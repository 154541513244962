import * as yup from "yup";
import { transformEmptyToNull } from "../../utils/yupTransformEmptyToNull";

export const defaultCountry = {
  code: "AU",
  label: "Australia",
  phone: "+61",
};

export const farmerOBP1Schema = yup
  .object()
  .shape({
    "UD-I1-FN": yup.string().nullable(),
    "UD-I1-LN": yup.string().nullable(),
    "UD-I2": yup.string().email("Email must be valid").nullable(),
    "UD-I3": yup.string().nullable(),
    "UD-I4": yup.string().nullable(),
  })
  .required();

export const farmerOBP1DefaultValues = {
  "UD-I1-FN": "",
  "UD-I1-LN": "",
  "UD-I2": "",
  "UD-I3": "",
  "UD-I4": "",
};

export const farmerOBP2Schema = yup
  .object()
  .shape({
    "FJ-G1": yup.array().nullable(),
    "FJ-G2": yup.array().nullable(),
    "FJ-V1": yup.string().nullable(),
    "FJ-V2": yup.array().nullable(),
    "FJ-D1": yup.string().nullable(),
    "FJ-D2": yup.string().nullable(),
    "FJ-E1": yup.string().nullable(),
    "FJ-C1": yup.array().nullable(),
    "FM-SP1": yup.string().nullable(),
    "2-FM-SP1-1-FJ-C1": yup.array().nullable(),
    "2-FM-SP1-2-FJ-C1": yup.array().nullable(),
    "2-FM-SP1-2-FM-SP1": yup.array().nullable(),
    "2-FM-SP1-3-FJ-C1": yup.array().nullable(),
    "2-FM-SP1-3-FM-SP1": yup.array().nullable(),
    "IK-1": yup.string().nullable(),
  })
  .required();

export const farmerOBP2DefaultValues = {
  "FJ-G1": [],
  "FJ-G2": [],
  "FJ-V1": "",
  "FJ-V2": [],
  "FJ-D1": "",
  "FJ-D2": "",
  "FJ-E1": "",
  "FJ-C1": [],
  "FM-SP1": "",
  "2-FM-SP1-1-FJ-C1": [],
  "2-FM-SP1-2-FJ-C1": [],
  "2-FM-SP1-2-FM-SP1": [],
  "2-FM-SP1-3-FJ-C1": [],
  "2-FM-SP1-3-FM-SP1": [],
  "IK-1": "",
};

export const farmerOBP3Schema = yup
  .object()
  .shape({
    "UD-I1": yup.string().nullable(),
    "UD-I2": yup.string().nullable(),
    "UD-I3": yup.string().nullable(),
    "UD-MA1": yup.array().nullable(),
    "UD-MA3": yup.array().nullable(),
    "UD-MA2": yup.string().nullable(),
    "FJ-E1": yup.array().nullable(),
  })
  .required();

export const farmerOBP3DefaultValues = {
  "UD-I1": "",
  "UD-I2": "",
  "UD-I3": "",
  "UD-MA1": [],
  "UD-MA3": [],
  "UD-MA2": "",
  "FJ-E1": [],
};

export const farmerOBSchema = yup
  .object()
  .shape({
    processOneData: farmerOBP1Schema,
    processTwoData: farmerOBP2Schema,
    processThreeData: farmerOBP3Schema,
  })
  .required();

export const userOBP1Schema = yup
  .object()
  .shape({
    gender: yup.string().nullable().transform(transformEmptyToNull),
    age: yup
      .number()
      .nullable()
      .transform(transformEmptyToNull)
      .min(1, "Value of this field must be at least 1")
      .max(100, "Value of this field must be below 100"),
    height: yup
      .number()
      .nullable()
      .transform(transformEmptyToNull)
      .min(1, "Value of this field must be at least 1")
      .max(250, "Value of this field must be below 250"),
    weight: yup
      .number()
      .nullable()
      .transform(transformEmptyToNull)
      .min(1, "Value of this field must be at least 1")
      .max(500, "Value of this field must be below 500"),
    healthStatus: yup.string().nullable().transform(transformEmptyToNull),
    diagnosis: yup
      .string()
      .nullable()
      .max(255, "This field must not exceed 255 characters"),
  })
  .required();

export const userOBP1DefaultValues = {
  gender: "",
  age: "",
  height: "",
  weight: "",
  healthStatus: "",
  diagnosis: "",
};

export const userOBP2Schema = yup
  .object()
  .shape({
    dietType: yup
      .string()
      .nullable()
      .transform(transformEmptyToNull)
      .max(255, "This field must not exceed 255 characters"),
    foodAllergies: yup.string().nullable().transform(transformEmptyToNull),
    allergiesSpecification: yup
      .string()
      .nullable()
      .max(255, "This field must not exceed 255 characters"),
    mealFrequency: yup
      .number()
      .nullable()
      .transform(transformEmptyToNull)
      .min(1, "Value of this field must be at least 1"),
    snackPreferences: yup.string().nullable().transform(transformEmptyToNull),
    preferedSnacks: yup
      .string()
      .nullable()
      .max(255, "This field must not exceed 255 characters"),
  })
  .required();

export const userOBP2DefaultValues = {
  dietType: "",
  foodAllergies: "",
  allergiesSpecification: "",
  mealFrequency: "",
  snackPreferences: "",
  preferedSnacks: "",
};

export const userOBP3Schema = yup
  .object()
  .shape({
    exerciseRegimen: yup.string().nullable().transform(transformEmptyToNull),
    typeOfExercise: yup.array().of(yup.string()),
    typeOfExerciseOther: yup
      .string()
      .max(255, "This field must not exceed 255 characters"),
    exerciseGoals: yup.array().of(yup.string()),
  })
  .required();

export const userOBP3DefaultValues = {
  exerciseRegimen: "",
  typeOfExercise: [],
  typeOfExerciseOther: "",
  exerciseGoals: [],
};

export const userOBP4Schema = yup
  .object()
  .shape({
    programIntensity: yup.string().nullable().transform(transformEmptyToNull),
    areasOfInterest: yup.array().of(yup.string()),
    areasOfInterestOther: yup
      .string()
      .max(255, "This field must not exceed 255 characters"),
    diagnosticsInterest: yup
      .string()
      .nullable()
      .transform(transformEmptyToNull),
    diagnosticsInterestTypes: yup.array().of(yup.string()),
  })
  .required();

export const userOBP4DefaultValues = {
  programIntensity: "",
  areasOfInterest: [],
  areasOfInterestOther: "",
  diagnosticsInterest: "",
  diagnosticsInterestTypes: [],
};

export const userOBP5Schema = yup
  .object()
  .shape({
    communityEngagement: yup
      .string()
      .nullable()
      .transform(transformEmptyToNull),
    challengesAndRewards: yup
      .string()
      .nullable()
      .transform(transformEmptyToNull),
    socialMedicineMovement: yup
      .string()
      .nullable()
      .transform(transformEmptyToNull),
    socialMedicineMovementTracking: yup
      .string()
      .nullable()
      .transform(transformEmptyToNull),
    contentPreferences: yup.array().of(yup.string()),
  })
  .required();

export const userOBP5DefaultValues = {
  communityEngagement: "",
  challengesAndRewards: "",
  socialMedicineMovement: "",
  socialMedicineMovementTracking: "",
  contentPreferences: [],
};

export const userOBP6Schema = yup
  .object()
  .shape({
    preferredCommunicationMethod: yup
      .string()
      .nullable()
      .transform(transformEmptyToNull),
    programStartDate: yup.string().nullable(),
    consent: yup.string(),
  })
  .required();

export const userOBP6DefaultValues = {
  preferredCommunicationMethod: "",
  programStartDate: null,
  consent: "",
};

export const userOBSchema = yup
  .object()
  .shape({
    processOneData: userOBP1Schema,
    processTwoData: userOBP2Schema,
    processThreeData: userOBP3Schema,
    processFourData: userOBP4Schema,
    processFiveData: userOBP5Schema,
    processSixData: userOBP6Schema,
  })
  .required();
