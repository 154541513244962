import { Add as AddIcon, Close as CloseIcon } from "@mui/icons-material";
import { Box, TextField, Typography } from "@mui/material";
import { useEffect } from "react";
import { Controller, useFieldArray } from "react-hook-form";

type Props = {
  control: any;
  name: string;
  label?: string;
  buttonLabel?: string;
};

const ReusableTagInputList = ({
  control,
  name,
  label,
  buttonLabel = "Click to add new item",
}: Props) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  useEffect(() => {
    if (fields.length === 0) {
      append("");
    }
  }, [append, fields]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {label && (
        <Typography fontSize={14} fontWeight={600} color="" mb={1}>
          {label}
        </Typography>
      )}
      <Box
        sx={{
          width: "100%",
          px: 2,
          py: 3.5,
          border: "1px solid #E5EAEF",
          borderRadius: 3,
        }}
      >
        {fields.map((item, index) => (
          <Box
            key={item.id}
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 0.9,
            }}
          >
            <Controller
              name={`${name}.${index}`}
              control={control}
              render={({ field, fieldState: { invalid, error } }) => (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    gap: 1,
                  }}
                >
                  <TextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    size="small"
                    placeholder={`Item`}
                    error={invalid}
                    helperText={error?.message}
                    sx={{
                      mr: 2,
                      "& .MuiInputBase-input": {
                        padding: "6px 10px",
                        fontSize: "14px",
                      },
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "6px",
                        padding: 0,
                      },
                      endAdornment: fields.length > 1 && (
                        <CloseIcon
                          sx={{
                            fontSize: 20,
                            mr: 1,
                            color: "#B2B5BB",
                            cursor: "pointer",
                          }}
                          onClick={() => remove(index)}
                        />
                      ),
                    }}
                  />
                </Box>
              )}
            />
          </Box>
        ))}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mt: 1,
            cursor: "pointer",
          }}
          onClick={() => append("")}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "600",
              mr: 1,
              color: "#2974B1",
              cursor: "pointer",
            }}
          >
            {buttonLabel}
          </Typography>
          <AddIcon sx={{ color: "#2974B1" }} />
        </Box>
      </Box>
    </Box>
  );
};

export default ReusableTagInputList;
