import { Box, Typography } from "@mui/material";
import ProgramOverviewWidgetHolder from "../widgets/ProgramOverviewWidgetHolder";
import Calendar from "../widgets/calendar/Calendar";
import {
  findEarliest,
  formatCustomDate,
  mergeConsecutiveDates,
} from "../../../../../utils/events";
import ReactQuill from "react-quill";
import "./index.css";
import { useState } from "react";

const Schedule = ({
  programEvents,
  programPlan,
}: {
  programEvents: any;
  programPlan: string;
}) => {
  const eventsData = mergeConsecutiveDates(programEvents);
  const [selected, setSelected] = useState<string>("");
  return (
    <ProgramOverviewWidgetHolder
      title="Exercise Regime"
      showSelect
      selectOptions={["Week 1", "Week 2", "Week 3"]}
      selectedOption={selected}
      onSelect={setSelected}
      selectPlaceholder="Select week"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          width: "100%",
          height: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 3,
            width: "100%",
            bgcolor: "#FFFFFF",
            height: "auto",
          }}
        >
          <Box
            sx={{
              height: "180px",
              width: "50%",
              flexGrow: 1,
              overflow: "hidden",
              position: "relative",
              borderRadius: "12px",
            }}
          >
            <img
              src="/assets/header-image.jpg"
              alt="placeholder"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",

                opacity: 0.3,
              }}
            />
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 3,
                pl: 4,
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <Typography
                  fontSize={16}
                  fontWeight={700}
                  color="#475367"
                  fontFamily="Comfortaa, sans-serif"
                >
                  Regime Start
                </Typography>
                <Typography
                  fontSize={24}
                  fontWeight={600}
                  color="#2974B1"
                  fontFamily="Comfortaa, sans-serif"
                >
                  {formatCustomDate(findEarliest(programEvents))}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              height: "180px",
              width: "50%",
              display: "flex",
              borderRadius: "12px",
              border: "1px solid #E4E7EC",
              justifyContent: "center",
              alignItems: "center",
              padding: "24px",
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <ReactQuill
                modules={{
                  toolbar: false,
                }}
                readOnly
                className="custom-quill-container"
                value={programPlan ?? ""}
              />
            </Box>
            <img
              src={"/assets/hearth-icon.svg"}
              alt="placeholder"
              width={"40px"}
              height={"40px"}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 3,
            width: "100%",
            p: 1.5,
            borderRadius: 3,
            border: "1px solid #E4E7EC",
            bgcolor: "#FFFFFF",
            height: "auto",
          }}
        >
          <Calendar eventsData={eventsData} />
        </Box>
      </Box>
    </ProgramOverviewWidgetHolder>
  );
};

export default Schedule;
