import { useWizard } from "react-use-wizard";
import { Box } from "@mui/material";
import {
  ReusableButton,
  ReusableChipChecklist,
  ReusableRadioButtons,
} from "../../reusable";
import { useEffect, useState } from "react";
import { SectionHeader } from "../../programs/widgets";
import UTurnLeftIcon from "@mui/icons-material/UTurnLeft";

type Props = {
  onSave: any;
  form: any;
};

const ProcessFive = ({ onSave, form }: Props) => {
  const [isBackLoading, setIsBackLoading] = useState<boolean>(false);
  const [isContinueLoading, setIsContinueLoading] = useState<boolean>(false);
  const { previousStep, nextStep, activeStep } = useWizard();

  const { control, handleSubmit } = form;

  const onSubmit = () => {
    if (isBackLoading || isContinueLoading) return;
    setIsContinueLoading(true);
    onSave().then(() => {
      setIsContinueLoading(false);
      nextStep();
    });
  };
  const handleOnBackClick = () => {
    if (isBackLoading || isContinueLoading) return;
    setIsBackLoading(true);
    onSave().then(() => {
      setIsBackLoading(false);
      previousStep();
    });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [activeStep]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        width: "100%",
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      <SectionHeader title="Engagement and Social Preferences" subtitle="" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "50%",
          gap: 2,
          pl: "40px",
        }}
      >
        <ReusableRadioButtons
          label="Community Engagement"
          sublabel="Are you interested in connecting with other participants for support and motivation?"
          name="processFiveData.communityEngagement"
          control={control}
          options={["Yes", "No"]}
          direction={"column"}
        />
        <ReusableRadioButtons
          label="Challenges and Rewards"
          sublabel="Would you like to participate in health challenges with the opportunity to earn rewards?"
          name="processFiveData.challengesAndRewards"
          control={control}
          options={["Yes", "No"]}
          direction={"column"}
        />
        <Box>
          <ReusableRadioButtons
            label="Social Medicine Movement"
            sublabel="Are you interested in contributing your data to a larger social medicine movement aimed at advancing public health?"
            name="processFiveData.socialMedicineMovement"
            control={control}
            options={["Yes", "No"]}
            direction={"column"}
          />
          <ReusableRadioButtons
            sublabel="If yes, are you interested in tracking your outcomes and sharing your progress for validation purposes?"
            name="processFiveData.socialMedicineMovementTracking"
            control={control}
            options={["Yes", "No"]}
            direction={"column"}
          />
        </Box>
        <ReusableChipChecklist
          name="processFiveData.contentPreferences"
          control={control}
          options={[
            "Longevity Tips",
            "Nutritional Guidance",
            "Fitness Programs",
            "Mental Health Support",
            "Research Updates on Health Topics",
          ]}
          label="Content Preferences"
          sublabel="What type of content are you most interested in receiving?"
        />
      </Box>
      <Box
        sx={{
          mt: 1,
          mb: 1,
          display: "flex",
          justifyContent: "center",
          width: "60%",
        }}
      >
        <ReusableButton
          onClickFunction={handleSubmit(handleOnBackClick)}
          buttonText="Go Back"
          color="#2974B1"
          backgroundColor="#E5EAEF"
          isLoading={isBackLoading}
          loaderColor="#1A1A1A"
          afterIcon={
            <UTurnLeftIcon
              sx={{
                rotate: "90deg",
                mt: 0.5,
              }}
            />
          }
        />
        <ReusableButton
          onClickFunction={handleSubmit(onSubmit)}
          buttonText="Save"
          color="#ffff"
          backgroundColor="#2974B1"
          isLoading={isContinueLoading}
        />
      </Box>
    </Box>
  );
};

export default ProcessFive;
