import { useQuery } from "@tanstack/react-query";
import { useAuthToken } from "../../auth/authToken";
import { coreServiceAxios } from "../../config/axios-instance";
import { useUsersMeQuery } from "../users/usersMeQuery";
import { TestingProtocol } from "../../types/testingProtocols";

export function useTestingProtocolsQuery() {
  const { hasToken } = useAuthToken();
  const { data: me } = useUsersMeQuery();
  const shouldEnableQuery = hasToken && me?.id !== undefined;

  return useQuery({
    queryKey: ["api/testingProtocols"],
    queryFn: () =>
      coreServiceAxios
        .get<TestingProtocol[]>(`api/health/program/testingProtocols`)
        .then((response: any) => response?.data),
    enabled: Boolean(shouldEnableQuery),
  });
}
