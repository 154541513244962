import { Box } from "@mui/material";
import { Control, UseFormWatch } from "react-hook-form";
import { SectionHeader } from "../widgets";
import ReusablePdfFileUploader from "../../reusable/Form/ReusablePdfFileUploader";

const MealPlan = ({
  control,
  watch,
  errors,
}: {
  control: Control<any>;
  watch: UseFormWatch<any>;
  errors?: any;
}) => {
  console.log("errors", errors?.mealPlan);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: 3,
        mt: 9,
      }}
    >
      <SectionHeader title="Meal Plan" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          px: "40px",
          gap: 2,
        }}
      >
        <ReusablePdfFileUploader control={control} name="mealPlan" />
        {errors?.mealPlan && (
          <p style={{ marginLeft: "16px", fontSize: "12px", color: "red" }}>
            Meal plan is required
          </p>
        )}
      </Box>
    </Box>
  );
};

export default MealPlan;
