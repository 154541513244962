import { useWizard } from "react-use-wizard";
import { Box } from "@mui/material";
import {
  ReusableButton,
  ReusableChipChecklist,
  ReusableRadioButtons,
  ReusableTextField,
} from "../../reusable";
import { useEffect, useState } from "react";
import { SectionHeader } from "../../programs/widgets";
import UTurnLeftIcon from "@mui/icons-material/UTurnLeft";

type Props = {
  onSave: any;
  form: any;
};

const typeOfExerciseOptions = [
  "Cardio (Running, Cycling)",
  "Strength Training",
  "Yoga/Pilates",
  "High-Intensity Interval Training (HIIT)",
];
const ProcessThree = ({ onSave, form }: Props) => {
  const [isBackLoading, setIsBackLoading] = useState<boolean>(false);
  const [isContinueLoading, setIsContinueLoading] = useState<boolean>(false);
  const { previousStep, nextStep, activeStep } = useWizard();

  const { control, watch, setValue, handleSubmit } = form;

  const exerciesTypesOther = watch("processThreeData.typeOfExerciseOther");
  const exerciesTypes = watch("processThreeData.typeOfExercise");

  useEffect(() => {
    if (exerciesTypesOther !== "" && exerciesTypesOther) {
      if (Array.isArray(exerciesTypes) && exerciesTypes.length === 0) {
        setValue("processThreeData.typeOfExercise", [exerciesTypesOther]);
      }
    } else {
      if (
        Array.isArray(exerciesTypes) &&
        exerciesTypes.length === 1 &&
        !typeOfExerciseOptions?.includes(exerciesTypes[0])
      ) {
        setValue("processThreeData.typeOfExercise", []);
      }
    }
  }, [exerciesTypesOther, exerciesTypes, setValue]);

  const onSubmit = () => {
    if (isBackLoading || isContinueLoading) return;
    setIsContinueLoading(true);
    onSave().then(() => {
      setIsContinueLoading(false);
      nextStep();
    });
  };
  const handleOnBackClick = () => {
    if (isBackLoading || isContinueLoading) return;
    setIsBackLoading(true);
    onSave().then(() => {
      setIsBackLoading(false);
      previousStep();
    });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [activeStep]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        width: "100%",
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      <SectionHeader title="Exercise Regimen" subtitle="" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "50%",
          gap: 2,
          pl: "40px",
        }}
      >
        <ReusableRadioButtons
          sublabel="How often do you exercise per week?"
          name="processThreeData.exerciseRegimen"
          control={control}
          options={["Rarely/Never", "1-2 times", "3-4 times", "5+ times"]}
          direction={"column"}
        />
        <Box>
          <ReusableChipChecklist
            name="processThreeData.typeOfExercise"
            control={control}
            options={typeOfExerciseOptions}
            label={"Type of Exercise"}
            sublabel="What type of physical activities do you engage in?"
          />
          <ReusableTextField
            inputType="text"
            control={control}
            name="processThreeData.typeOfExerciseOther"
            placeholder="Other..."
          />
        </Box>
        <ReusableChipChecklist
          name="processThreeData.exerciseGoals"
          control={control}
          options={[
            "Weight Loss",
            "Muscle Gain",
            "Flexibility/Mobility",
            "General Fitness",
          ]}
          label={"Exercise Goals"}
          sublabel="What are your main fitness goals?"
        />
      </Box>

      <Box
        sx={{
          mt: 1,
          mb: 1,
          display: "flex",
          justifyContent: "center",
          width: "60%",
        }}
      >
        <ReusableButton
          onClickFunction={handleSubmit(handleOnBackClick)}
          buttonText="Go Back"
          color="#2974B1"
          backgroundColor="#E5EAEF"
          isLoading={isBackLoading}
          loaderColor="#1A1A1A"
          afterIcon={
            <UTurnLeftIcon
              sx={{
                rotate: "90deg",
                mt: 0.5,
              }}
            />
          }
        />
        <ReusableButton
          onClickFunction={handleSubmit(onSubmit)}
          buttonText="Save"
          color="#ffff"
          backgroundColor="#2974B1"
          isLoading={isContinueLoading}
        />
      </Box>
    </Box>
  );
};

export default ProcessThree;
