import { Box } from "@mui/material";

import VerticalLines from "../widgets/VerticalLines";
import ProgramCardInfo from "./ProgramCardInfo";
import { ProgramType } from "../../../types/programs/ProgramsType";

type Props = {
  programObject: ProgramType;
  handleOnClick?: () => void;
};

export default function ProgramCard({
  programObject,
  handleOnClick = () => {},
}: Props) {
  return (
    <Box
      onClick={handleOnClick}
      sx={{
        width: "100%",
        height: "298px",
        borderRadius: "30px",
        backgroundColor: "#FFFFFF",
        boxShadow: "none",
        display: "flex",
        flexDirection: "row",
        paddingRight: 2,
        marginBottom: "16px",
        boxSizing: "border-box",
        border: "1px solid #A1C7E7",
        "&:hover": {
          cursor: "pointer",
        },
      }}
    >
      <Box
        sx={{
          width: "35%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <img
          alt="program"
          src={"/assets/science-page-obesity-section.jpg"}
          style={{
            height: "298px",
            width: "100%",
            objectFit: "fill",
            borderTopLeftRadius: "30px",
            borderBottomLeftRadius: "30px",
          }}
        />
      </Box>
      <VerticalLines />
      <ProgramCardInfo programObject={programObject} />
    </Box>
  );
}
