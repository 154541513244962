import { Box, Container, Typography } from "@mui/material";
import { useNavigate } from "react-router";

const Header = () => {
  const navigate = useNavigate();
  return (
    <Container
      component="main"
      maxWidth="sm"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 10,
      }}
    >
      <img
        alt="No data loaded"
        src={"/assets/fresh-earth-logo.svg"}
        onClick={() => navigate("/")}
        style={{ cursor: "pointer" }}
        width="215px"
        height="68px"
      />
      <Box sx={{ mt: 2 }}>
        <Typography
          variant="h4"
          component="span"
          style={{
            color: "black",
            fontWeight: "700",
            fontFamily: "Comfortaa",
            fontSize: "30px",
            lineHeight: "38px",
          }}
        >
          Register with
        </Typography>
        <Typography
          variant="h4"
          component="span"
          style={{
            marginLeft: 8,
            fontWeight: "700",
            fontFamily: "Comfortaa",
            fontSize: "30px",
            lineHeight: "38px",
            background: "linear-gradient(45deg, #a1c4fd,#4298f4)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Fresh Earth
        </Typography>
      </Box>
    </Container>
  );
};

export default Header;
