import { Box, MenuItem, Select, Typography } from "@mui/material";
import { ReusableButton } from "../../../../reusable";

const ProgramOverviewWidgetHolder = ({
  title,
  hideIcons = false,
  showButton,
  showSelect,
  selectOptions,
  onSelect,
  selectedOption,
  children,
  selectPlaceholder,
  buttonText,
  onButtonClick,
}: {
  title: string;
  hideIcons?: boolean;
  children: any;
  showSelect?: boolean;
  selectOptions?: string[];
  selectedOption?: string;
  onSelect?: (a: string) => void;
  selectPlaceholder?: string;
  showButton?: boolean;
  buttonText?: string;
  onButtonClick?: () => void;
}) => {
  return (
    <Box
      sx={{
        p: 1.5,
        borderRadius: 3,
        border: "1px solid #E4E7EC",
        bgcolor: "#FFFFFF",
        height: "auto",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          my: 0.75,
        }}
      >
        <Typography
          ml={3}
          mb={1}
          fontSize={24}
          fontWeight={600}
          color="#2974B1"
          fontFamily="Comfortaa, sans-serif"
        >
          {title}
        </Typography>

        {!hideIcons && (
          <Box
            mr={3}
            mb={1}
            sx={{
              display: "flex",
              gap: 3,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {showButton && (
              <ReusableButton
                buttonText={buttonText ?? "Click"}
                onClickFunction={onButtonClick}
                backgroundColor="#2974B1"
                width="auto"
              />
            )}
            {showSelect && onSelect && (
              <Select
                value={selectedOption || ""}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  if (selectedValue === "__clear__") {
                    onSelect("");
                  } else {
                    onSelect(selectedValue);
                  }
                }}
                sx={{
                  borderRadius: "8px",
                  height: "40px",
                  minWidth: "178px",
                  backgroundColor: "white",
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#2974B1",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#2974B1",
                  },
                }}
                displayEmpty
                renderValue={(selected) =>
                  selected ? (
                    <Typography textAlign="center">{selected}</Typography>
                  ) : (
                    <Typography color="grey" textAlign="center">
                      {selectPlaceholder ?? "Select"}
                    </Typography>
                  )
                }
              >
                <MenuItem value="__clear__">
                  <Typography fontSize={16} fontWeight={400} color="grey">
                    Select option
                  </Typography>
                </MenuItem>
                {selectOptions?.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            )}
            <img src="/assets/edit-icon.svg" alt="edit" />
            <img src="/assets/share-icon.svg" alt="share" />
          </Box>
        )}
      </Box>
      <Box sx={{ display: "flex", gap: 1.25 }}>{children}</Box>
    </Box>
  );
};

export default ProgramOverviewWidgetHolder;
