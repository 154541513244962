import { Box, Typography } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        mt: 2,
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          fontSize: "14px",
          fontFamily: "Open Sans",
          lineHeight: "24px",
          textAlign: "center",
          whiteSpace: "nowrap",
        }}
      >
        Check out how we use this information by reading our
      </Typography>
      <a
        href="https://www.techtarget.com/whatis/definition/privacy-policy"
        target="_blank"
        rel="noreferrer"
        style={{
          fontSize: "14px",
          fontFamily: "Open Sans",
          lineHeight: "24px",
          marginBottom: 40,
        }}
      >
        <u>Privacy Policy</u>
      </a>
    </Box>
  );
};

export default PrivacyPolicy;
