import { Box } from "@mui/material";
import ProgramOverviewWidgetHolder from "../widgets/ProgramOverviewWidgetHolder";
import ProgramOverviewBasicInfoCard from "../widgets/ProgramOveviewBaiscInfoCard";

const Suppliers = () => {
  return (
    <ProgramOverviewWidgetHolder title="Suppliers" hideIcons>
      <Box sx={{ display: "flex", gap: 3, width: "100%" }}>
        <ProgramOverviewBasicInfoCard
          title="Customized Health"
          info="Provides essential supplements, including nootropics, minerals, and vitamins."
          image="/assets/customized-health-logo.svg"
        />
        <ProgramOverviewBasicInfoCard
          title="NutriPath"
          info="Manages all diagnostic testing services, including biomarker tracking and verification."
          image="/assets/nutripath-logo.svg"
        />
      </Box>
    </ProgramOverviewWidgetHolder>
  );
};

export default Suppliers;
