import { useQuery } from "@tanstack/react-query";
import { useAuthToken } from "../../auth/authToken";
import { coreServiceAxios } from "../../config/axios-instance";
import { useUsersMeQuery } from "../users/usersMeQuery";
import { UserOnboardingValues } from "../../types/onboarding/onboarding";

export function useUserOnboardingQuery() {
  const { hasToken } = useAuthToken();
  const { data: me } = useUsersMeQuery();

  const shouldEnableQuery = hasToken && me && me.id;

  return useQuery({
    queryKey: ["api/users/onboarding"],
    queryFn: async () =>
      (await coreServiceAxios.get)<UserOnboardingValues>(
        `api/users/onboarding/${me?.id}`
      ).then((response) => response.data),
    enabled: !!shouldEnableQuery,
  });
}
