import { Box, Button, IconButton, Typography } from "@mui/material";
import {
  Control,
  useFieldArray,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { SectionHeader } from "../widgets";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { ReusableSelect, ReusableTextField } from "../../reusable";
import { Supplements } from "../../../types/supplements";
import { useMemo, useState } from "react";

const SupplementsWidget = ({
  control,
  watch,
  name,
  supplements,
  setValue,
}: {
  control: Control<any>;
  watch: UseFormWatch<any>;
  name: string;
  supplements: Supplements[];
  setValue: UseFormSetValue<any>;
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });
  const [index, setIndex] = useState(0);

  const supplementsArray = watch(name);

  const handleAddNewField = () => {
    append(0);
  };

  const components = useMemo(() => {
    return fields?.map((field, ind) => {
      return (
        <Box
          key={field.id + index}
          sx={{
            display: "flex",
            backgroundColor: "#F6FBFF",
            position: "relative",
            width: "100%",
            gap: 4,
            p: "18px 24px",
            borderRadius: "12px",
            borderLeft: "4px solid #2974B1",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: 3,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: 0.75,
              }}
            >
              <Typography
                fontSize={14}
                fontWeight={600}
                fontFamily="Open Sans"
                color="#383D45"
              >
                Supplements
              </Typography>
              <ReusableSelect
                control={control}
                borderRadius="10px"
                placeholder="Supplement Name"
                name={`${name}[${ind}].name`}
                options={supplements?.map((s) => s.name)}
                onChangeEffect={(isClear: boolean, selected: string) => {
                  setIndex((prevVal) => prevVal + 1);
                  if (isClear) {
                    setValue(`${name}[${ind}].id`, null);
                    setValue(`${name}[${ind}].cost`, 0);
                    setValue(`${name}[${ind}].benefits`, "");
                    setValue(`${name}[${ind}].dosage`, "");
                    setValue(`${name}[${ind}].link`, "");
                    setValue(`${name}[${ind}].linkText`, "");
                  } else {
                    setValue(
                      `${name}[${ind}].id`,
                      supplements?.find((s) => s.name === selected)?.id
                    );
                    setValue(
                      `${name}[${ind}].cost`,
                      supplements?.find((s) => s.name === selected)?.cost
                    );
                    setValue(
                      `${name}[${ind}].benefits`,
                      supplements?.find((s) => s.name === selected)?.benefits
                    );
                    setValue(
                      `${name}[${ind}].dosage`,
                      supplements?.find((s) => s.name === selected)?.dosage
                    );
                    setValue(
                      `${name}[${ind}].link`,
                      supplements?.find((s) => s.name === selected)?.link
                    );
                    setValue(
                      `${name}[${ind}].linkText`,
                      supplements?.find((s) => s.name === selected)?.linkText
                    );
                  }
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1.5,
                  width: "30%",
                }}
              >
                <Typography
                  fontSize={14}
                  fontWeight={600}
                  fontFamily="Open Sans"
                  color="#383D45"
                >
                  Cost
                </Typography>
                <Typography
                  fontSize={14}
                  fontWeight={400}
                  fontFamily="Open Sans"
                  color="#7D8189"
                >{`$ ${supplementsArray?.[ind]?.cost ?? 0}`}</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1.5,
                  width: "70%",
                }}
              >
                <Typography
                  fontSize={14}
                  fontWeight={600}
                  fontFamily="Open Sans"
                  color="#383D45"
                >
                  Benefits
                </Typography>
                <Typography
                  fontSize={14}
                  fontWeight={400}
                  fontFamily="Open Sans"
                  color="#7D8189"
                >
                  {supplementsArray?.[ind]?.benefits ?? ""}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: 0.75,
            }}
          >
            <Typography
              fontSize={14}
              fontWeight={600}
              fontFamily="Open Sans"
              color="#383D45"
            >
              Usage
            </Typography>
            <ReusableTextField
              control={control}
              borderRadius="10px"
              inputType="text"
              multiline
              rows={6}
              placeholder="Enter usage note..."
              name={`${name}[${ind}].usage`}
            />
          </Box>
          <IconButton
            disableTouchRipple
            sx={{ ml: 1, mb: 1, position: "absolute", top: 0, right: 3 }}
            onClick={() => remove(ind)}
          >
            <img
              src="/assets/area-delete.svg"
              alt="delete"
              width={25}
              height={25}
            />
          </IconButton>
        </Box>
      );
    });
  }, [
    control,
    name,
    remove,
    fields,
    setValue,
    supplements,
    supplementsArray,
    index,
    setIndex,
  ]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: 3,
        mt: 9,
      }}
    >
      <SectionHeader title="Supplements" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          px: "40px",
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 2,
          }}
        >
          {components}
        </Box>
        <Box>
          <Button
            disableRipple
            endIcon={<AddRoundedIcon />}
            sx={{
              mt: 1,
              px: 2,
              py: 1,
              bgcolor: "#E5EAEF",
              color: "#2974B1",
              textTransform: "none",
              borderRadius: "15px",
              "&:hover": {
                bgcolor: "#c1d1e0",
              },
            }}
            onClick={handleAddNewField}
          >
            <Typography fontSize={16} fontWeight={600} color="#2974B1">
              Add Supplement
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default SupplementsWidget;
