import { Box } from "@mui/material";

export default function Processes({
  processes,
  activeStep = -1,
}: {
  processes: { label: string; filled?: boolean }[];
  activeStep?: number;
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "whitesmoke",
        alignItems: "center",
        justifyContent: "center",
        p: 2,
        borderRadius: 5,
        width: "35%",
      }}
    >
      <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
        {processes.map((p, i) => {
          return (
            <Box
              key={p.label}
              sx={{
                color: p.filled ? "#fff" : "black",
                background: p.filled
                  ? "linear-gradient(to right,#F2B9FD,#3992DD)"
                  : "#A1C7E7",

                height: "35px",
                width: "35px",
                borderRadius: "12px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
                position: "relative",
                userSelect: "none",
              }}
            >
              {p.label}
              {p.filled && (
                <img
                  src="/assets/success-icon.svg"
                  alt="Success Icon"
                  style={{
                    position: "absolute",
                    top: "3px",
                    right: "3px",
                    width: "12px",
                    height: "12px",
                  }}
                />
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
