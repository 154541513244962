import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export function useMainPageQuery() {
  return useQuery({
    queryKey: ["/main-page"],
    queryFn: () =>
      axios
        .get<any>(
          `${process.env.REACT_APP_STRAPI_DEFAULT_URL}/api/pages/1?populate=deep,4`
        )
        .then((x) => {
          return x.data;
        }),
  });
}
