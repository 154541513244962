import { Box, Typography, LinearProgress } from "@mui/material";

export default function LinearProgressWidget({ value }: { value: number }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "whitesmoke",
        p: 2,
        justifyContent: "center",
        borderRadius: 4,
        width: "100%",
      }}
    >
      <Box
        sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}
      >
        <Typography
          color="black"
          fontWeight="400"
          fontFamily="Open Sans"
          fontSize="12px"
          sx={{ userSelect: "none" }}
        >
          Profile Completion
        </Typography>
        <Typography
          color="#3992DD"
          fontWeight="700"
          fontSize="16px"
          fontFamily="Open Sans"
          sx={{ userSelect: "none" }}
        >
          {value}%
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          backgroundColor: "whitesmoke",
          alignItems: "center",
          borderRadius: 5,
        }}
      >
        <LinearProgress
          variant="determinate"
          value={value}
          sx={{
            height: 20,
            borderRadius: 10,
            width: "100%",
            backgroundColor: "#DEE1E3",
            "& .MuiLinearProgress-barColorPrimary": {
              backgroundColor: "#3992DD",
            },
          }}
        />
      </Box>
    </Box>
  );
}
