import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import {
  ReusableButton,
  ReusableRadioButtons,
  ReusableTextField,
} from "../../reusable";
import { useWizard } from "react-use-wizard";
import { SectionHeader } from "../../programs/widgets";

type Props = {
  onSave: any;
  form: any;
};

const ProcessOne = ({ onSave, form }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInputDisabled, setIsInputDisabled] = useState<boolean>(false);
  const { nextStep, activeStep } = useWizard();

  const { control, watch, setValue, handleSubmit } = form;

  const healthStatus = watch("processOneData.healthStatus");

  useEffect(() => {
    if (healthStatus === "Yes") {
      setIsInputDisabled(false);
    }
    if (healthStatus === "No" || !healthStatus) {
      setIsInputDisabled(true);
      setValue("processOneData.diagnosis", "");
    }
  }, [healthStatus, setValue]);

  const onSubmit = async () => {
    if (isLoading) return;
    setIsLoading(true);
    await onSave(1).then(() => {
      setIsLoading(false);
      nextStep();
    });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [activeStep]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        width: "100%",
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      <SectionHeader title="Basic Demographic information" subtitle="" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "50%",
          pl: "40px",
        }}
      >
        <ReusableRadioButtons
          label="Gender"
          name="processOneData.gender"
          control={control}
          options={["Male", "Female", "Non-binary", "Prefer not to say"] ?? []}
          direction={"column"}
        />
        <ReusableTextField
          inputType="number"
          control={control}
          name="processOneData.age"
          label="Age"
        />
        <ReusableTextField
          inputType="number"
          control={control}
          name="processOneData.height"
          label="Height"
        />
        <ReusableTextField
          inputType="number"
          control={control}
          name="processOneData.weight"
          label="Weight"
        />
        <ReusableRadioButtons
          label="Health Status"
          sublabel="Do you have any diagnosed health conditions?"
          name="processOneData.healthStatus"
          control={control}
          options={["Yes", "No"]}
          direction={"column"}
        />
        <ReusableTextField
          inputType="text"
          control={control}
          disabled={isInputDisabled}
          name="processOneData.diagnosis"
          sublabel="If yes, please specify"
        />
      </Box>
      <Box
        sx={{
          mt: 1,
          mb: 1,
          display: "flex",
          justifyContent: "center",
          width: "60%",
        }}
      >
        <ReusableButton
          onClickFunction={handleSubmit(onSubmit)}
          buttonText="Save"
          color="#ffff"
          backgroundColor="#2974B1"
          isLoading={isLoading}
        />
      </Box>
    </Box>
  );
};

export default ProcessOne;
