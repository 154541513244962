import dayjs, { Dayjs } from "dayjs";
import isBetweenPlugin from "dayjs/plugin/isBetween";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import { Control, Controller } from "react-hook-form";
import { useState } from "react";
import { Box, Typography } from "@mui/material";
import ReusableButton from "./ReusableButton";
import {
  PickersCalendarHeader,
  PickersCalendarHeaderProps,
} from "@mui/x-date-pickers";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";

dayjs.extend(weekday);
dayjs.extend(weekOfYear);
dayjs.extend(isBetweenPlugin);

const getCurrentWeekRange = () => {
  // Get today's date
  const today = dayjs();

  // Calculate the start of the week (Sunday)
  const startOfWeek = today.weekday(1); // 0 represents Sunday

  // Calculate the end of the week (Saturday)
  const endOfWeek = today.weekday(7); // 6 represents Saturday

  return {
    startOfWeek: startOfWeek.format("MMM D"), // Format as 'Aug 19'
    endOfWeek: endOfWeek.format("D"), // Format as 'Aug 25'
  };
};

function Day(
  props: PickersDayProps<Dayjs> & {
    selectedDays?: Dayjs[] | null;
  }
) {
  const { day, selectedDays, ...other } = props;

  return (
    <PickersDay
      {...other}
      day={day}
      sx={{ px: 2.5 }}
      disableMargin
      selected={
        selectedDays?.find((d) => d?.toString() === day?.toString())
          ? true
          : false
      }
    />
  );
}
function Header(props: PickersCalendarHeaderProps<Dayjs>) {
  const { startOfWeek, endOfWeek } = getCurrentWeekRange();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <PickersCalendarHeader {...props} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-around",
          mt: 1,
        }}
      >
        <Box
          sx={{
            border: "1px solid #D0D5DD",
            borderRadius: "8px",
            width: "60%",
            p: "8px",
            height: "32px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography fontSize={14} fontWeight={400} color="#383D45">
            {startOfWeek} - {endOfWeek}
          </Typography>
        </Box>
        <Box
          sx={{
            border: "1px solid #D0D5DD",
            borderRadius: "180px",
            p: "6px 10px",
            width: "20%",
            height: "32px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography fontSize={14} fontWeight={600} color="#383D45">
            Today
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default function ReusableMultiDaysPicker({
  control,
  name,
  defaultValue,
  onCancel,
}: {
  control: Control<any>;
  name: string;
  defaultValue: Dayjs[];
  onCancel: () => void;
}) {
  const [tempValue, setTempValue] = useState<Dayjs[]>(defaultValue ?? []);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              onChange={(newValue) => {
                const hasElement = tempValue?.find(
                  (d: any) => d?.toString() === newValue?.toString()
                )
                  ? true
                  : false;

                if (!hasElement) {
                  setTempValue([...tempValue, newValue]);
                } else {
                  setTempValue(
                    tempValue.filter(
                      (item: any) => item?.toString() !== newValue?.toString()
                    )
                  );
                }
              }}
              view="day"
              views={["day"]}
              disablePast
              slots={{ day: Day, calendarHeader: Header }}
              slotProps={{
                day: (_) =>
                  ({
                    selectedDays: tempValue,
                  } as any),
              }}
            />
          </LocalizationProvider>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              justifyContent: "center",
              alignItems: "center",
              p: "10px",
              borderTop: "1px solid #D0D5DD",
              mt: "10px",
            }}
          >
            <ReusableButton
              buttonText={"Cancel"}
              color="#383D45"
              border="1px solid #D0D5DD"
              backgroundColor="#FFFFFF"
              width="50%"
              onClickFunction={() => onCancel()}
            />

            <ReusableButton
              buttonText={"Apply"}
              width="50%"
              onClickFunction={() => {
                onChange(tempValue);
                onCancel();
              }}
              backgroundColor="#2974B1"
            />
          </Box>
        </>
      )}
    />
  );
}
