import { Box } from "@mui/material";
import ProgramOverviewWidgetHolder from "../widgets/ProgramOverviewWidgetHolder";

import { useState } from "react";
import ProgramOverviewMealCard from "../widgets/ProgramOverviewMealCard";

const MealPlan = ({
  selectMeal,
  openModal,
  programFile,
}: {
  selectMeal: (a: any) => void;
  openModal: () => void;
  programFile: File;
}) => {
  const [selected, setSelected] = useState<string>("");
  return (
    <ProgramOverviewWidgetHolder
      title="Meal Plan"
      showSelect
      selectOptions={[
        "Vegetarian",
        "Vegan",
        "Paleo",
        "Keto",
        "Standard/Omnivore",
      ]}
      selectedOption={selected}
      onSelect={setSelected}
      selectPlaceholder="Select diet type"
      showButton
      buttonText="Download Meal Plan"
      onButtonClick={() => {
        if (programFile) {
          const fileUrl = URL.createObjectURL(programFile);
          const link = document.createElement("a");
          link.href = fileUrl;
          link.setAttribute("download", programFile.name);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 6,
          width: "100%",
          p: "20px 35px",
        }}
      >
        <ProgramOverviewMealCard
          image="/assets/meal1.jpg"
          title="Whole Grain Oatmeal with Berries and Nuts"
          mealTime="Breakfast"
          dietType="Vegetarian"
          info="Oatmeal, berries, and nuts are rich in antioxidants, fiber, and healthy fats, supporting heart health and reducing inflammation."
          onClick={() => {
            openModal();
            selectMeal({
              title: "Whole Grain Oatmeal with Berries and Nuts",
              info: "Oatmeal, berries, and nuts are rich in antioxidants, fiber, and healthy fats, supporting heart health and reducing inflammation.",
              image: "/assets/meal1.jpg",
              dietType: "Vegetarian Meal",
              mealTime: "Breakfast",
              reference: [
                {
                  title: "Oatmeal reference:",
                  text: "Gélinas, P., & McKinnon, C. M. (2006). Whole grain products and health. Canadian Journal of Dietetic Practice and Research",
                  linkTo: "https://mui.com/",
                },
                {
                  title: "Berries reference:",
                  text: "Joseph, J. A., et al. (2009). Reversing the deleterious effects of aging on neuronal communication and behavior: beneficial properties of fruit polyphenolic compounds. The Journal of Nutritionh",
                  linkTo: "https://mui.com/",
                },
              ],
            });
          }}
        />
        <ProgramOverviewMealCard
          image="/assets/meal2.jpg"
          title="Quinoa Salad with Mixed Greens, Chickpeas, and Olive Oil"
          mealTime="Lunch"
          dietType="Vegetarian"
          info="A high-protein, fiber-rich meal with healthy fats from olive oil, promoting cardiovascular health."
          onClick={() => {
            openModal();
            selectMeal({
              title: "Quinoa Salad with Mixed Greens, Chickpeas, and Olive Oil",
              info: "A high-protein, fiber-rich meal with healthy fats from olive oil, promoting cardiovascular health.",
              image: "/assets/meal2.jpg",
              dietType: "Vegetarian",
              mealTime: "Lunch",
              reference: [
                {
                  title: "Quinoa:",
                  text: "Gélinas, P., & McKinnon, C. M. (2006). Whole grain products and health. Canadian Journal of Dietetic Practice and Research",
                  linkTo: "https://mui.com/",
                },
                {
                  title: "Mixed Greens:",
                  text: "Joseph, J. A., et al. (2009). Reversing the deleterious effects of aging on neuronal communication and behavior: beneficial properties of fruit polyphenolic compounds. The Journal of Nutritionh",
                  linkTo: "https://mui.com/",
                },
              ],
            });
          }}
        />
        <ProgramOverviewMealCard
          image="/assets/meal3.jpg"
          title="Grilled Vegetables with Tofu and Lentil Soup"
          mealTime="Dinner"
          dietType="Vegetarian"
          info="A plant-based, protein-packed dinner with essential amino acids, vitamins, and minerals that support overall health."
          onClick={() => {
            openModal();
            selectMeal({
              title: "Grilled Vegetables with Tofu and Lentil Soup",
              info: "A plant-based, protein-packed dinner with essential amino acids, vitamins, and minerals that support overall health.",
              image: "/assets/meal3.jpg",
              dietType: "Vegetarian",
              mealTime: "Dinner",
              reference: [
                {
                  title: "Tofu reference:",
                  text: "Gélinas, P., & McKinnon, C. M. (2006). Whole grain products and health. Canadian Journal of Dietetic Practice and Research",
                  linkTo: "https://mui.com/",
                },
                {
                  title: "Lentil reference:",
                  text: "Joseph, J. A., et al. (2009). Reversing the deleterious effects of aging on neuronal communication and behavior: beneficial properties of fruit polyphenolic compounds. The Journal of Nutritionh",
                  linkTo: "https://mui.com/",
                },
              ],
            });
          }}
        />
      </Box>
    </ProgramOverviewWidgetHolder>
  );
};

export default MealPlan;
