import { Box, Typography } from "@mui/material";
import { ReusableSelect, ReusableTextField } from "../reusable";

import { useEffect, useState } from "react";
import { useProgramQuery } from "../../api/programs/programQuery";
import { useSupplementsInfiniteQuery } from "../../api/supplements/supplementsInfiniteQuery";
import { useDebounce } from "../../hooks/useDebounce";
import { useInfiniteScroll } from "../../hooks/useInfiniteScroll";
import { useBackdrop } from "../reusable/LoadingSpinners/infinityBackdrop";
import SupplementMarketplaceCard from "./SupplementMarketplaceCard";
import SupplementModal from "./SupplementModal";

type Props = {
  control: any;
  watch: any;
};

const SupplementsMarketplace = ({ control, watch }: Props) => {
  const programs = watch("programs");
  const type = watch("type");
  const search = watch("searchText");
  const sort = watch("sortBy");
  const maxPrice = watch("maxPrice");
  const supplier = watch("supplier");

  const [openModal, setOpenModal] = useState({
    isOpen: false,
    supplement: null,
  });
  const debouncedSearchInput = useDebounce(search);
  const { toggleBackdrop } = useBackdrop();

  const {
    data: supplements,
    fetchNextPage,
    isLoading,
    isFetched,
  } = useSupplementsInfiniteQuery({
    programs: programs,
    pageSize: 6,
    type,
    search: debouncedSearchInput,
    sort,
    maxPrice,
    supplier,
  });
  const { data: allPrograms } = useProgramQuery({ removePagination: true });

  const triggerRef = useInfiniteScroll({
    fetchNextPage,
    isLoading,
  });

  useEffect(() => {
    toggleBackdrop(isLoading);
  }, [isLoading, toggleBackdrop]);

  return (
    <Box
      sx={{
        width: "75%",
        display: "flex",
        flexDirection: "column",
        p: "25px 16px",
      }}
    >
      {openModal.isOpen && (
        <SupplementModal
          open={openModal.isOpen}
          handleClose={() => setOpenModal({ isOpen: false, supplement: null })}
          supplement={openModal.supplement}
          allPrograms={allPrograms?.pages[0].data}
        />
      )}
      <Typography
        fontSize="30px"
        fontFamily="Comfortaa"
        fontWeight={600}
        color="#2974B1"
      >
        Supplements marketplace
      </Typography>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          gap: 1,
          mt: 2.5,
        }}
      >
        <Box
          sx={{
            width: "83%",
          }}
        >
          <ReusableTextField
            control={control}
            name="searchText"
            inputType="text"
            placeholder="Search your Programs"
            borderRadius="8px"
            fullWidth
            startAdornment={<img src="/assets/search-icon.svg" alt="search" />}
          />
        </Box>
        <Box
          sx={{
            width: "17%",
            pt: 0.5,
          }}
        >
          <ReusableSelect
            control={control}
            borderRadius="10px"
            placeholder="Sort by"
            name="sortBy"
            options={[
              "Name (A-Z)",
              "Name (Z-A)",
              "Price (Low to High)",
              "Price (High to Low)",
            ]}
          />
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "grid",
          gridTemplateColumns: "33% 33% 33%",
          gap: 2,
          mt: 2,
        }}
      >
        {supplements?.pages.map((obj) =>
          obj.data.map((obj, index) => (
            <SupplementMarketplaceCard
              key={index}
              supplement={obj}
              openModal={setOpenModal}
            />
          ))
        )}
        {isFetched && supplements?.pages[0]?.pagination.total === 0 && (
          <p>No supplements found</p>
        )}
      </Box>
      <Box ref={triggerRef}></Box>
    </Box>
  );
};

export default SupplementsMarketplace;
