import React from "react";
import { Controller } from "react-hook-form";
import { FormControl, Typography, Select, MenuItem } from "@mui/material";

interface Props {
  name: string;
  control: any;
  options: string[];
  label?: string;
  placeholder?: string;
  borderRadius?: string;
  onChangeEffect?: (a: boolean, b: string) => void;
}

const ReusableSelect: React.FC<Props> = ({
  name,
  control,
  options,
  label,
  placeholder,
  borderRadius = "8px",
  onChangeEffect,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl
          fullWidth
          error={!!error}
          sx={{ marginBottom: "8px" }}
          variant="outlined"
        >
          {label && (
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "20px",
                textAlign: "left",
                marginBottom: "4px",
              }}
            >
              {label}
            </Typography>
          )}
          <Select
            value={value || ""}
            onChange={(e) => {
              const selectedValue = e.target.value;
              if (selectedValue === "__clear__") {
                onChange("");
                if (onChangeEffect) onChangeEffect(true, "");
              } else {
                if (onChangeEffect) onChangeEffect(false, selectedValue);
                onChange(selectedValue);
              }
            }}
            size="small"
            displayEmpty
            MenuProps={{
              sx: { height: "300px" },
            }}
            sx={{
              maxHeight: "50px",
              mt: 0.5,
              borderRadius: borderRadius,
              backgroundColor: "#FFFFFF",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: error ? "#d32f2f" : "#D0D5DD", // Apply red border on error
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#2974B1",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#2974B1",
              },
            }}
            renderValue={(selected) =>
              selected ? (
                selected
              ) : (
                <Typography color="grey">{placeholder ?? ""}</Typography>
              )
            }
          >
            <MenuItem value="__clear__">
              <Typography fontSize={16} fontWeight={400} color="grey">
                Select option
              </Typography>
            </MenuItem>
            {options.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
          {error && (
            <Typography
              variant="caption"
              color="error"
              sx={{ marginLeft: "12px", marginTop: "4px" }}
            >
              {error.message}
            </Typography>
          )}
        </FormControl>
      )}
    />
  );
};

export default ReusableSelect;
