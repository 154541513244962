/* eslint-disable react-hooks/exhaustive-deps */
import { useParams } from "react-router-dom";
import { Box, createTheme, ThemeProvider } from "@mui/material";
import { InfinityLoader } from "../../components/reusable";
import EditProgramPage from "../../components/programs/editProgram/EditProgramPage";
import { useProgramByIdQuery } from "../../api/programs/programByIdQuery";

export const editProgramCustomTheme = createTheme({
  palette: {
    primary: {
      main: "#1E6F30",
    },
    secondary: {
      main: "#FFAD01",
    },
  },
  typography: {
    fontFamily: [
      "Open Sans",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

const EditProgram = () => {
  let { id } = useParams();
  const { data, isLoading } = useProgramByIdQuery({ id });

  return (
    <ThemeProvider theme={editProgramCustomTheme}>
      {isLoading && (
        <Box
          sx={{
            width: "100%",
            height: "80vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <InfinityLoader />
        </Box>
      )}
      {!isLoading && data !== undefined && data !== null && id && (
        <EditProgramPage data={data} id={id} />
      )}
    </ThemeProvider>
  );
};

export default EditProgram;
