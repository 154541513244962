import { useEffect, useRef } from "react";

type Props = {
  fetchNextPage: () => void;
  isLoading: boolean;
};

export const useInfiniteScroll = ({ fetchNextPage, isLoading }: Props) => {
  const observerRef = useRef<IntersectionObserver | null>(null);
  const triggerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (observerRef.current) observerRef.current.disconnect();

    observerRef.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isLoading) {
          fetchNextPage();
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 1.0,
      }
    );

    const observeLastItem = () => {
      if (triggerRef.current) {
        observerRef.current?.observe(triggerRef.current);

        setTimeout(() => {
          const rect = triggerRef.current?.getBoundingClientRect();
          if (rect && rect.bottom <= window.innerHeight) {
            // If the last item is initially visible, trigger fetchNextPage
            fetchNextPage();
          }
        }, 100); // Delay to allow layout to settle
      }
    };

    observeLastItem();

    return () => {
      if (observerRef.current) observerRef.current.disconnect();
    };
  }, [fetchNextPage, isLoading]);

  return triggerRef;
};
