import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import LockIcon from "@mui/icons-material/Lock";
import LockResetIcon from "@mui/icons-material/LockReset";
import PasswordIcon from "@mui/icons-material/Password";
import { Alert, Button, IconButton, Snackbar } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef, useState } from "react";
import { useBlockUserMutation } from "../../api/users/blockUserMutation";
import { useResetPaswordMutation } from "../../api/users/resetPasswordMutation";
import { useUsersMeQuery } from "../../api/users/usersMeQuery";
import { User } from "../../types/user/user";
import ContactDetailsForm from "../profile/account-details/ContactDetailsForm";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

enum Action {
  resetPassword,
  blockUser,
  unblockUser,
}

const Actions = (props: User) => {
  const resetPasswordMutation = useResetPaswordMutation();
  const blockUserMutation = useBlockUserMutation();
  const { data: me } = useUsersMeQuery();
  const resetPassword = (email: string) => {
    resetPasswordMutation.mutate(email);
  };
  const blockUnblockUser = (email: string, blocked: boolean) => {
    blockUserMutation.mutate({ email: email, blocked: blocked });
  };
  const [open, setOpen] = useState(false);
  const [editDialogOpen, seteditDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogText, setDialogText] = useState("");
  const [action, setAction] = useState(Action.resetPassword);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarContent, setSnackBarContent] = useState("");

  const handleClickOpenResetPassword = () => {
    setDialogTitle("Are you sure you want to send reset password email?");
    setDialogText("This action will send reset password email to end user");
    setAction(Action.resetPassword);
    setOpen(true);
  };

  const handleClickOpenBlockUser = () => {
    setDialogTitle("Are you sure you want to block this user ?");
    setDialogText("This action will block end user");
    setAction(Action.blockUser);
    setOpen(true);
  };

  const handleClickOpenUnblockUser = () => {
    setDialogTitle("Are you sure you want to unblock this user ?");
    setDialogText("This action will unblock end user");

    setAction(Action.unblockUser);
    setOpen(true);
  };

  const setEditDialog = () => {
    seteditDialogOpen(true);
  };

  const handleOk = (user: User) => {
    switch (action) {
      case Action.resetPassword:
        resetPassword(user.email);
        setOpen(false);
        setSnackBarContent("You have successfully sent reset password email!");
        setSnackBarOpen(true);
        break;
      case Action.blockUser:
        blockUnblockUser(user.email, true);
        setOpen(false);
        setSnackBarContent("You have successfully blocked user!");
        setSnackBarOpen(true);
        break;
      case Action.unblockUser:
        blockUnblockUser(user.email, false);
        setOpen(false);
        setSnackBarContent("You have successfully unblocked user!");
        setSnackBarOpen(true);
        break;
      default:
        break;
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSnackBarOpen(false);
  };
  const handleEditClose = async () => {
    seteditDialogOpen(false);
    setSnackBarContent("You have successfully edited user!");
    setSnackBarOpen(true);
  };
  const handleFormClose = () => {
    seteditDialogOpen(false);
  };
  return (
    <>
      <Snackbar
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackBarOpen}
        autoHideDuration={2000}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackBarContent}
        </Alert>
      </Snackbar>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={editDialogOpen}
        onClose={handleFormClose}
        PaperProps={{
          component: "form",
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2>Edit User</h2>
          <IconButton onClick={handleFormClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            To edit user, please fill out the form below.
          </DialogContentText>
          <ContactDetailsForm
            onCloseModal={handleEditClose}
            user={props}
            isForUserManagement={true}
          />
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" onClick={handleSubmit(onSubmit)}>
            Invite
          </Button>
        </DialogActions> */}
      </Dialog>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {dialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>Cancel</Button>
          <Button onClick={() => handleOk(props)}>Ok</Button>
        </DialogActions>
      </Dialog>
      <IconButton
        disabled={me?.email === props.email}
        onClick={() => handleClickOpenResetPassword()}
      >
        <PasswordIcon />
      </IconButton>
      {props.blocked ? (
        <IconButton
          disabled={me?.email === props.email}
          onClick={() => handleClickOpenUnblockUser()}
        >
          <LockResetIcon />
        </IconButton>
      ) : (
        <IconButton
          disabled={me?.email === props.email}
          onClick={() => handleClickOpenBlockUser()}
        >
          <LockIcon />
        </IconButton>
      )}
      <IconButton onClick={() => setEditDialog()}>
        <EditIcon />
      </IconButton>
    </>
  );
};
export default Actions;
