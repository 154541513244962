import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import {
  ProgramData,
  programDefaultValues,
  programSchema,
} from "../../../schemas/programs/programs";
import ProgramDetails from "./ProgramDetails";
import MealPlan from "./MealPlan";
import Supplements from "./Supplements";
import TestingProtocols from "./TestingProtocols";
import WorkoutRegime from "./WorkoutRegime";
import { ReusableButton } from "../../reusable";
import { useState } from "react";
import { useNavigate } from "react-router";
import { useCreateProgramMutation } from "../../../api/programs/createProgramMutation";
import { useSupplementsQuery } from "../../../api/supplements/supplementsQuery";
import { useTestingProtocolsQuery } from "../../../api/testingProtocols/testingProtocolsQuery";

const CreateProgramPage = () => {
  const form = useForm({
    defaultValues: programDefaultValues,
    resolver: yupResolver(programSchema),
  });
  const { data: supplements } = useSupplementsQuery();
  const { data: testingProtocols } = useTestingProtocolsQuery();

  const { watch, control, handleSubmit, setValue, formState } = form;
  const { errors } = formState;

  const { mutate } = useCreateProgramMutation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = async (value: ProgramData) => {
    if (isLoading) return;
    setIsLoading(true);

    try {
      mutate(value);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        p: 5,
        gap: 4,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "87px",
            height: "87px",
            color: "#fff",
            background: "linear-gradient(to right, #F2B9FD, #3992DD)",
            borderRadius: "14px",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src="/assets/calendar-plus-02.svg"
            alt="Success Icon"
            style={{
              width: "34px",
              height: "34px",
            }}
          />
        </Box>
        <Typography
          sx={{
            fontSize: "30px",
            fontWeight: "600",
            fontFamily: "Comfortaa",
            lineHeight: "40px",
            color: "#2974B1",
          }}
        >
          Create new program
        </Typography>
      </Box>
      <Box
        sx={{ display: "flex", width: "100%", px: 15, flexDirection: "column" }}
      >
        <ProgramDetails control={control} watch={watch} />
        <MealPlan control={control} watch={watch} errors={errors} />
        <Supplements
          control={control}
          watch={watch}
          name="supplements"
          supplements={supplements?.data ?? []}
          setValue={setValue}
        />
        <TestingProtocols
          control={control}
          watch={watch}
          testingProtocols={testingProtocols}
          name="testingProtocols"
          setValue={setValue}
        />
        <WorkoutRegime control={control} watch={watch} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            mt: 5,
          }}
        >
          <ReusableButton
            buttonText={"Cancel"}
            color="#2974B1"
            backgroundColor="#E5EAEF"
            width="15%"
            onClickFunction={() => {
              window.scrollTo(0, 0);
              navigate("/");
            }}
          />
          <ReusableButton
            buttonText={"Create Program"}
            width="15%"
            onClickFunction={handleSubmit(onSubmit)}
            isLoading={isLoading}
            backgroundColor="#2974B1"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CreateProgramPage;
