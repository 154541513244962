import { Box } from "@mui/material";
import ProgramOverviewWidgetHolder from "../widgets/ProgramOverviewWidgetHolder";
import ProgramOverviewInfoCard from "../widgets/ProgramOverviewInfoCard";
import { ProgramData } from "../../../../../schemas/programs/programs";
import { useMemo } from "react";

type Props = {
  program: ProgramData;
};

const LongevityProgramCost = ({ program }: Props) => {
  const totalSupplementsCost = useMemo(
    () =>
      program.supplements?.reduce((accumulator, currentValue) => {
        if (currentValue?.cost) return accumulator + currentValue?.cost;
        return accumulator;
      }, 0),
    [program.supplements]
  );

  return (
    <ProgramOverviewWidgetHolder title="Longevity Program Cost">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 3,
            width: "100%",
          }}
        >
          <ProgramOverviewInfoCard
            title="Total Program Cost (Comprehensive)"
            valueOne={{
              title: "Total Cost for 1 Subject",
              value: 4588,
            }}
            valueTwo={{
              title: "Total Cost for 100 Subject",
              value: 458800,
            }}
            total={{
              title: "Per Unit Cost",
              value: 4588,
            }}
            imageStyle={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
              transform: "scale(5.5) translate(0px, -28px)",
              opacity: 0.3,
            }}
          />
          <ProgramOverviewInfoCard
            title="Total Program Cost (Low-Cost)"
            valueOne={{
              title: "Total Cost for 1 Subject",
              value: 2592,
            }}
            valueTwo={{
              title: "Total Cost for 100 Subject",
              value: 259200,
            }}
            total={{
              title: "Per Unit Cost",
              value: 2592,
            }}
            imageStyle={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
              transform: "scale(5.5) translate(0px, -28px)",
              opacity: 0.3,
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 3,
            width: "100%",
          }}
        >
          <ProgramOverviewInfoCard
            title="Diagnostics (Comprehensive)"
            valueOne={{
              title: "Total Cost for 1 Subject",
              value: 2592,
            }}
            valueTwo={{
              title: "Total Cost for 100 Subject",
              value: 259200,
            }}
            total={{
              title: "Per Unit Cost",
              value: 2592,
            }}
            image="/assets/science-page-diabetes-section.jpg"
            imageStyle={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              transform: "scale(1) translate(0px, 0px)",
              opacity: 0.3,
            }}
          />
          <ProgramOverviewInfoCard
            title="Diagnostics (Low-Cost)"
            valueOne={{
              title: "Total Cost for 1 Subject",
              value: 500,
            }}
            valueTwo={{
              title: "Total Cost for 100 Subject",
              value: 50000,
            }}
            total={{
              title: "Per Unit Cost",
              value: 500,
            }}
            image="/assets/science-page-diabetes-section.jpg"
            imageStyle={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              transform: "scale(1) translate(0px, 0px)",
              opacity: 0.3,
              borderRadius: 3,
            }}
          />
        </Box>
        <ProgramOverviewInfoCard
          title="Supplements"
          valueOne={{
            title: "Total Cost for 1 Subject",
            value: totalSupplementsCost ?? 0,
          }}
          valueTwo={{
            title: "Total Cost for 100 Subject",
            value: totalSupplementsCost ? totalSupplementsCost * 100 : 0,
          }}
          total={{
            title: "Per Unit Cost",
            value: totalSupplementsCost ?? 0,
          }}
          image="/assets/protein-powder.jpg"
          imageStyle={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
            transform: "scale(9) translate(0px, -19px)",
            opacity: 0.3,
            borderRadius: 3,
          }}
        />
      </Box>
    </ProgramOverviewWidgetHolder>
  );
};

export default LongevityProgramCost;
