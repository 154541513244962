import { Box, Typography } from "@mui/material";

type Props = {
  title: string;
  info: string;
  image: string;
};

const ProgramOverviewBasicInfoCard = ({ title, info, image }: Props) => {
  return (
    <Box
      sx={{
        borderRadius: 3,
        border: "1px solid #E4E7EC",
        bgcolor: "white",
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        p: "24px 35px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Typography
          fontSize={24}
          fontWeight={600}
          color="#000000"
          fontFamily="Comfortaa, sans-serif"
        >
          {title}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "end",
        }}
      >
        <Box
          sx={{
            width: "80%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            borderRight: "2px solid #2974B1",
            gap: 0.5,
            paddingRight: "40px",
          }}
        >
          <Typography
            fontSize={16}
            fontWeight={600}
            color="#000000"
            fontFamily="Comfortaa, sans-serif"
          >
            {info}
          </Typography>
        </Box>
        <Box
          sx={{
            height: "100%",
            width: "20%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            ml: 2,
          }}
        >
          <img src={image} alt="placeholder" width={"50px"} height={"50px"} />
        </Box>
      </Box>
    </Box>
  );
};

export default ProgramOverviewBasicInfoCard;
