import { Box, Typography } from "@mui/material";
import ProgramOverviewInfoCard from "../widgets/ProgramOverviewInfoCard";

const SummaryAndExperts = ({ programSummary }: { programSummary: string }) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: 3,
        width: "100%",
      }}
    >
      <ProgramOverviewInfoCard
        title="Program Summary"
        showBadge
        imageStyle={{
          width: "100%",
          height: "100%",
          objectFit: "contain",
          transform: "scale(5.5) translate(0px, -28px)",
          opacity: 0.3,
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 3,
            p: "36px 46px",
          }}
        >
          <Typography
            fontFamily="Open Sans"
            fontWeight={400}
            fontSize="16px"
            color="#545F71"
          >
            {programSummary}
          </Typography>
          <img src={"/assets/star-icon.svg"} alt="placeholder" />
        </Box>
      </ProgramOverviewInfoCard>
      <ProgramOverviewInfoCard
        title="Program Experts"
        imageStyle={{
          width: "100%",
          height: "100%",
          objectFit: "contain",
          transform: "scale(5.5) translate(0px, -28px)",
          opacity: 0.3,
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            p: "36px",
          }}
        >
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            <img
              src={"/assets/doctor-icon.svg"}
              alt="placeholder"
              width={"40px"}
              height={"40px"}
            />
            <Typography
              fontFamily="Open Sans"
              fontWeight={400}
              fontSize="16px"
              color="#545F71"
            >
              Medical Doctor
            </Typography>
          </Box>
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            <img
              src={"/assets/dietician-icon.svg"}
              alt="placeholder"
              width={"40px"}
              height={"40px"}
            />
            <Typography
              fontFamily="Open Sans"
              fontWeight={400}
              fontSize="16px"
              color="#545F71"
            >
              Dietician
            </Typography>
          </Box>
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            <img
              src={"/assets/chef-icon.svg"}
              alt="placeholder"
              width={"40px"}
              height={"40px"}
            />
            <Typography
              fontFamily="Open Sans"
              fontWeight={400}
              fontSize="16px"
              color="#545F71"
            >
              Chef
            </Typography>
          </Box>
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            <img
              src={"/assets/influencer-icon.svg"}
              alt="placeholder"
              width={"40px"}
              height={"40px"}
            />
            <Typography
              fontFamily="Open Sans"
              fontWeight={400}
              fontSize="16px"
              color="#545F71"
            >
              Influencer
            </Typography>
          </Box>
        </Box>
      </ProgramOverviewInfoCard>
    </Box>
  );
};

export default SummaryAndExperts;
