import { Box, Link, Typography } from "@mui/material";
import { Supplements } from "../../../../../types/supplements";

const SupplementCard = ({ supplement }: { supplement: Supplements }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: 1,
        border: "1px solid #E4E7EC",
        borderRadius: "12px",
        padding: "24px 12px",
      }}
    >
      <Typography
        fontWeight={800}
        fontSize="16px"
        lineHeight="22.4px"
        color="#000000"
      >
        {supplement.name}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          gap: 1,
          borderBottom: "1px solid #E4E7EC",
          paddingBottom: "10px",
        }}
      >
        <Box
          sx={{
            py: "2px",
            px: "12px",
            borderRadius: "12px",
            border: "1px solid #2974B1",
            backgroundColor: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 1,
          }}
        >
          <img
            src="/assets/schedule-icon.svg"
            alt="schedule icon"
            width={13}
            height={13}
          />
          <Typography fontSize={14} fontWeight={500} color="#2974B1">
            {supplement.dosage}
          </Typography>
        </Box>
        <Typography fontSize={14} fontWeight={600} color="#2974B1">
          {`$ AUD ${supplement.cost}/month`}
        </Typography>
      </Box>

      <Typography fontSize={16} fontWeight={400} color="#4F5359">
        {supplement.benefits}
      </Typography>
      <Link
        href={supplement.link}
        sx={{
          color: "#2974B1",
          fontWeight: 400,
          fontSize: "12px",
          fontStyle: "italic",
        }}
        target="_blank"
      >
        {supplement.linkText}
      </Link>
    </Box>
  );
};

export default SupplementCard;
