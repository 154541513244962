import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import {
  ReusableButton,
  ReusableRadioButtons,
  ReusableTextField,
} from "../../reusable";
import { useWizard } from "react-use-wizard";
import { SectionHeader } from "../../programs/widgets";
import UTurnLeftIcon from "@mui/icons-material/UTurnLeft";

type Props = {
  onSave: any;
  form: any;
};

const dietTypes = ["Vegetarian", "Vegan", "Paleo", "Keto", "Standard/Omnivore"];

const ProcessTwo = ({ onSave, form }: Props) => {
  const [isBackLoading, setIsBackLoading] = useState<boolean>(false);
  const [isContinueLoading, setIsContinueLoading] = useState<boolean>(false);
  const { previousStep, nextStep, activeStep } = useWizard();
  const [isFAInputDisabled, setIsFAInputDisabled] = useState<boolean>(false);
  const [isSPInputDisabled, setIsSPInputDisabled] = useState<boolean>(false);

  const { control, watch, setValue, handleSubmit } = form;

  const foodAllergies = watch("processTwoData.foodAllergies");
  const dietType = watch("processTwoData.dietType");
  const snack = watch("processTwoData.snackPreferences");

  useEffect(() => {
    if (foodAllergies === "Yes") {
      setIsFAInputDisabled(false);
    }
    if (foodAllergies === "No" || !foodAllergies) {
      setIsFAInputDisabled(true);
      setValue("processTwoData.allergiesSpecification", "");
    }
  }, [foodAllergies, setValue]);

  useEffect(() => {
    if (snack === "Yes") {
      setIsSPInputDisabled(false);
    }
    if (snack === "No" || !snack) {
      setIsSPInputDisabled(true);
      setValue("processTwoData.preferedSnacks", "");
    }
  }, [snack, setValue]);

  const onSubmit = () => {
    if (isBackLoading || isContinueLoading) return;
    setIsContinueLoading(true);
    onSave().then(() => {
      setIsContinueLoading(false);
      nextStep();
    });
  };

  const handleOnBackClick = () => {
    if (isBackLoading || isContinueLoading) return;
    setIsBackLoading(true);
    onSave().then(() => {
      setIsBackLoading(false);
      previousStep();
    });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [activeStep]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        width: "100%",
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      <SectionHeader title="Dietary Preferences" subtitle="" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "50%",
          gap: 2,
          pl: "40px",
        }}
      >
        <Box>
          <ReusableRadioButtons
            label="Diet type"
            name="processTwoData.dietType"
            control={control}
            options={dietTypes}
            direction={"column"}
          />
          <ReusableTextField
            inputType="text"
            control={control}
            hideValue={dietTypes?.includes(dietType)}
            name="processTwoData.dietType"
            placeholder="Other..."
          />
        </Box>
        <Box>
          <ReusableRadioButtons
            label="Food Sensitivities/Allergies"
            sublabel="Do you have any food allergies?"
            name="processTwoData.foodAllergies"
            control={control}
            options={["Yes", "No"]}
            direction={"column"}
          />
          <ReusableTextField
            inputType="text"
            control={control}
            disabled={isFAInputDisabled}
            name="processTwoData.allergiesSpecification"
            sublabel="If yes, please specify"
          />
        </Box>
        <ReusableTextField
          inputType="number"
          control={control}
          label="Preferred Meal Frequency"
          name="processTwoData.mealFrequency"
          sublabel="How many meals do you prefer per day?"
        />
        <Box>
          <ReusableRadioButtons
            label="Snack Preferences"
            sublabel="Do you snack between meals?"
            name="processTwoData.snackPreferences"
            control={control}
            options={["Yes", "No"]}
            direction={"column"}
          />
          <ReusableTextField
            inputType="text"
            control={control}
            disabled={isSPInputDisabled}
            name="processTwoData.preferedSnacks"
            sublabel="Preferred snacks"
          />
        </Box>
      </Box>
      <Box
        sx={{
          mt: 1,
          mb: 1,
          display: "flex",
          justifyContent: "center",
          width: "60%",
        }}
      >
        <ReusableButton
          onClickFunction={handleSubmit(handleOnBackClick)}
          buttonText="Go Back"
          color="#2974B1"
          backgroundColor="#E5EAEF"
          isLoading={isBackLoading}
          loaderColor="#1A1A1A"
          afterIcon={
            <UTurnLeftIcon
              sx={{
                rotate: "90deg",
                mt: 0.5,
              }}
            />
          }
        />
        <ReusableButton
          onClickFunction={handleSubmit(onSubmit)}
          buttonText="Save"
          color="#ffff"
          backgroundColor="#2974B1"
          isLoading={isContinueLoading}
        />
      </Box>
    </Box>
  );
};

export default ProcessTwo;
