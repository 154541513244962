import { Box, ThemeProvider } from "@mui/material";
import { useForm } from "react-hook-form";
import { Wizard } from "react-use-wizard";
import {
  userHeaderObjects,
  userOnboardingCustomTheme,
} from "../../../constants/onboarding/userOnboarding";
import {
  userProcessOneQuestions,
  userProcessThreeQuestions,
  userProcessTwoQuestions,
  userProcessFiveQuestions,
  userProcessFourQuestions,
  userProcessSixQuestions,
} from "../../../constants/onboarding/userQuestions";
import ProcessOne from "./ProcessOne";
import ProcessThree from "./ProcessThree";
import ProcessTwo from "./ProcessTwo";
import {
  getEstimatedTime,
  getProcessProgress,
  getTotalProgress,
} from "../../../utils/onboarding";
//import BackToProfiles from "../widgets/BackToProfiles";
import Header from "../widgets/Header";
import ProfileCard from "../widgets/ProfileCard";
import ProcessFour from "./ProcessFour";
import ProcessFive from "./ProcessFive";
import ProcessSix from "./ProcessSix";
import { isEqual } from "lodash";
import { useSnackbar } from "../../snackbar/Snackbar";
import { useUserDemographicsMutation } from "../../../api/onboarding/userDemographicsMutation";
import { yupResolver } from "@hookform/resolvers/yup";
import { userOBSchema } from "../../../schemas/user/onboarding";
import { useUserDietaryPreferencesMutation } from "../../../api/onboarding/userDietaryPreferencesMutation";
import { useUserExerciseProgramMutation } from "../../../api/onboarding/userExerciseProgramMutation";
import { useUserHealthProgramLevelMutation } from "../../../api/onboarding/userHealthProgramLevelMutation";
import { useUserEngagementSocialPreferencesMutation } from "../../../api/onboarding/userEngagementSocialPreferencesMutation";
import { useUserFinalDetailsMutation } from "../../../api/onboarding/userFinalDetailsMutation";

type Props = {
  defaultValues: {
    processOneData: any;
    processTwoData: any;
    processThreeData: any;
    processFourData: any;
    processFiveData: any;
    processSixData: any;
  };
};

const OnboardingMainPage = ({ defaultValues }: Props) => {
  const form = useForm({
    defaultValues,
    resolver: yupResolver(userOBSchema),
  });
  const { showSnackbar } = useSnackbar();
  const { mutateAsync: demographic } = useUserDemographicsMutation();
  const { mutateAsync: dietaryPreferences } =
    useUserDietaryPreferencesMutation();
  const { mutateAsync: exerciseProgram } = useUserExerciseProgramMutation();
  const { mutateAsync: healthProgramLevel } =
    useUserHealthProgramLevelMutation();
  const { mutateAsync: engagementSocialPreferences } =
    useUserEngagementSocialPreferencesMutation();
  const { mutateAsync: finalDetails } = useUserFinalDetailsMutation();

  const processOneProgress = getProcessProgress(
    form.watch(),
    "processOneData",
    userProcessOneQuestions
  );
  const processTwoProgress = getProcessProgress(
    form.watch(),
    "processTwoData",
    userProcessTwoQuestions
  );
  const processThreeProgress = getProcessProgress(
    form.watch(),
    "processThreeData",
    userProcessThreeQuestions
  );
  const processFourProgress = getProcessProgress(
    form.watch(),
    "processFourData",
    userProcessFourQuestions
  );
  const processFiveProgress = getProcessProgress(
    form.watch(),
    "processFiveData",
    userProcessFiveQuestions
  );
  const processSixProgress = getProcessProgress(
    form.watch(),
    "processSixData",
    userProcessSixQuestions
  );

  const processProgresses = [
    processOneProgress,
    processTwoProgress,
    processThreeProgress,
    processFourProgress,
    processFiveProgress,
    processSixProgress,
  ];

  const totalProgress = getTotalProgress(processProgresses);
  const estimatedTime = getEstimatedTime(processProgresses);

  const handleSaveProccessOne = form.handleSubmit(async (questionTree) => {
    if (isEqual(defaultValues.processOneData, questionTree.processOneData)) {
      return;
    }
    try {
      await demographic(questionTree.processOneData);
      showSnackbar("success", "Your progress is successfully saved.", 2000);
    } catch (error) {
      showSnackbar(
        "error",
        "Something went wrong while saving your progess.",
        3000
      );
    }
  });

  const handleSaveProccessTwo = form.handleSubmit(async (questionTree) => {
    if (isEqual(defaultValues.processTwoData, questionTree.processTwoData)) {
      return;
    }
    try {
      await dietaryPreferences(questionTree.processTwoData);
      showSnackbar("success", "Your progress is successfully saved.", 2000);
    } catch (error) {
      showSnackbar(
        "error",
        "Something went wrong while saving your progess.",
        3000
      );
    }
  });

  const handleSaveProccessThree = form.handleSubmit(async (questionTree) => {
    if (
      isEqual(defaultValues.processThreeData, questionTree.processThreeData)
    ) {
      return;
    }
    try {
      await exerciseProgram(questionTree.processThreeData);
      showSnackbar("success", "Your progress is successfully saved.", 2000);
    } catch (error) {
      showSnackbar(
        "error",
        "Something went wrong while saving your progess.",
        3000
      );
    }
  });

  const handleSaveProccessFour = form.handleSubmit(async (questionTree) => {
    if (isEqual(defaultValues.processFourData, questionTree.processFourData)) {
      return;
    }
    try {
      await healthProgramLevel(questionTree.processFourData);
      showSnackbar("success", "Your progress is successfully saved.", 2000);
    } catch (error) {
      showSnackbar(
        "error",
        "Something went wrong while saving your progess.",
        3000
      );
    }
  });

  const handleSaveProccessFive = form.handleSubmit(async (questionTree) => {
    if (isEqual(defaultValues.processFiveData, questionTree.processFiveData)) {
      return;
    }
    try {
      await engagementSocialPreferences(questionTree.processFiveData);
      showSnackbar("success", "Your progress is successfully saved.", 2000);
    } catch (error) {
      showSnackbar(
        "error",
        "Something went wrong while saving your progess.",
        3000
      );
    }
  });

  const handleSaveProccessSix = form.handleSubmit(async (questionTree) => {
    if (isEqual(defaultValues.processSixData, questionTree.processSixData)) {
      return;
    }
    try {
      await finalDetails(questionTree.processSixData);
      showSnackbar("success", "Your progress is successfully saved.", 2000);
    } catch (error) {
      showSnackbar(
        "error",
        "Something went wrong while saving your progess.",
        3000
      );
    }
  });

  const wizardHeader = (
    <>
      <ProfileCard
        title="User Onboarding"
        imgUrl="/assets/profile-image.jpg"
        estimatedTime={estimatedTime}
        totalProgress={totalProgress}
        processes={[
          {
            label: "1",
            filled: processOneProgress.done === processOneProgress.total,
          },
          {
            label: "2",
            filled: processTwoProgress.done === processTwoProgress.total,
          },
          {
            label: "3",
            filled: processThreeProgress.done === processThreeProgress.total,
          },
          {
            label: "4",
            filled: processFourProgress.done === processFourProgress.total,
          },
          {
            label: "5",
            filled: processFiveProgress.done === processFiveProgress.total,
          },
          {
            label: "6",
            filled: processSixProgress.done === processSixProgress.total,
          },
        ]}
      />
      <Header
        headerObjects={userHeaderObjects.map((el, index) => ({
          ...el,
          filled:
            processProgresses[index].total === processProgresses[index].done,
        }))}
      />
    </>
  );

  return (
    <ThemeProvider theme={userOnboardingCustomTheme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-end",
          maxWidth: "1300px",
          marginX: "auto",
          marginTop: "10px",
        }}
      >
        <Wizard
          header={wizardHeader}
          wrapper={<Box sx={{ width: "75%" }}></Box>}
        >
          <ProcessOne onSave={handleSaveProccessOne} form={form} />
          <ProcessTwo onSave={handleSaveProccessTwo} form={form} />
          <ProcessThree onSave={handleSaveProccessThree} form={form} />
          <ProcessFour onSave={handleSaveProccessFour} form={form} />
          <ProcessFive onSave={handleSaveProccessFive} form={form} />
          <ProcessSix onSave={handleSaveProccessSix} form={form} />
        </Wizard>
      </Box>
    </ThemeProvider>
  );
};

export default OnboardingMainPage;
