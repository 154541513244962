import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuthToken } from "../../auth/authToken";
import { useSnackbar } from "../../components/snackbar/Snackbar";
import { MarketplaceSupplementData } from "../../schemas/marketplace/marketplace";

export function useSupplementToProgramMutation() {
  const { tokenHeaders } = useAuthToken();
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: async ({
      programId,
      data,
    }: {
      programId: number | null;
      data: MarketplaceSupplementData;
    }) => {
      if (!programId) return;
      const response = await fetch(
        `${process.env.REACT_APP_CORE_SERVICE_URL}/api/health/program/${programId}/supplements`,
        {
          body: JSON.stringify({
            supplementId: data.supplementId,
            usage: data.usage,
          }),
          method: "POST",
          headers: {
            ...tokenHeaders,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      return response.json();
    },
    onSuccess: (variable) => {
      showSnackbar(
        "success",
        "You have added the supplement to the program successfully.",
        3000
      );
      queryClient.invalidateQueries({
        queryKey: [`/healthProgram/${variable.programId}`],
      });
    },
    onError: (error) => {
      showSnackbar(
        "error",
        "Something went wrong. Please try again later.",
        3000
      );
    },
  });
}
