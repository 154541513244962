import { Box, Typography } from "@mui/material";
import { workoutLibFilterOptions } from "../../../constants/program-library/workout-library";
import FilterChecklist from "../../marketplace/FilterChecklist";
import SliderFilter from "../widgets/SliderFilter";

type Props = {
  control: any;
};

const WorkoutLibraryFilters = ({ control }: Props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "20%",
        px: "16px",
        gap: 2,
      }}
    >
      <Typography
        fontFamily="Open Sans"
        fontSize="16px"
        fontWeight={600}
        sx={{ paddingLeft: "15px" }}
      >
        Filter by
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <FilterChecklist
          name="type"
          control={control}
          options={workoutLibFilterOptions.type}
          label="Type"
        />
        <SliderFilter
          name="duration"
          control={control}
          maxValue={60}
          label="Duration"
          minLabel="0 min"
          maxLabel="60 min"
          placeholderText="Set the duration range"
        />
        <FilterChecklist
          name="difficulty"
          control={control}
          options={workoutLibFilterOptions.difficulty}
          label="Difficulty"
        />
      </Box>
    </Box>
  );
};

export default WorkoutLibraryFilters;
