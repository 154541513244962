import { useMemo } from "react";
import { Navigate, Route, Routes } from "react-router";
import { useMainPageQuery } from "./api/pages/mainPage";
import { useUsersMeQuery } from "./api/users/usersMeQuery";
import { useAuthToken } from "./auth/authToken";
import {
  AccountDetailsPage,
  EditProgram,
  ErrorPage,
  LogInPage,
  MainPage,
  ProgramPage,
  Programs,
  RegisterPage,
  ResetPassword,
  SciencePage,
  UserManagement,
  UserOnboarding,
} from "./pages";
import MarketplacePage from "./pages/marketplace/MarketplacePage";
import ProgramLibraryPage from "./pages/program-library/ProgramLibraryPage";
import ProgramOverview from "./pages/programs/ProgramOverview";
import SuccessScreen from "./pages/user/SuccessRegisterPage";
import { isAdmin } from "./utils/adminCheck";

const roleInRoles = (roles: string[], role: string | undefined) => {
  if (!role) return false;
  return roles.includes(role);
};

const mappedRoutes = [{ name: "Science", value: <SciencePage /> }];

const AppRoutes = () => {
  const { data: mainPageData } = useMainPageQuery();
  const { data: userData } = useUsersMeQuery();
  const { hasToken } = useAuthToken();
  const role = userData?.role.type;
  const dataNavigation = mainPageData?.navigation;

  const userManagementRoute = useMemo(() => {
    return isAdmin(role as string) ? (
      <Route path="/profile/users" element={<UserManagement />} />
    ) : null;
  }, [role]);

  const routes = useMemo(() => {
    return dataNavigation?.nav_item.map(
      (item: { id: number; name: string; role: [] }) => {
        const listOfRoles = item.role?.map((a: any) => a.type);
        if (!roleInRoles(listOfRoles, role)) {
          return null;
        }
        return (
          <Route
            key={item.id}
            path={`/${item.name.toLowerCase()}`}
            element={
              mappedRoutes.find((x) => x.name === item.name)?.value ?? <></>
            }
          />
        );
      }
    );
  }, [dataNavigation, role]);

  if (!hasToken)
    return (
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/login" element={<LogInPage />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/success-request" element={<SuccessScreen />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    );

  return (
    <Routes>
      <Route path="/" element={<MainPage />} />
      {userManagementRoute}
      {routes}
      <Route path="/profile/account-details" element={<AccountDetailsPage />} />
      <Route path="/program/create" element={<ProgramPage />} />
      <Route path="/programs" element={<Programs />} />
      <Route path="/program/:id" element={<ProgramOverview />} />
      <Route path="/program/edit/:id" element={<EditProgram />} />
      <Route path="/marketplace" element={<MarketplacePage />} />
      <Route path="/program-library" element={<ProgramLibraryPage />} />
      {userData && (
        <>
          <Route path="/profile/manage-profiles" element={<UserOnboarding />} />
        </>
      )}
      <Route path="*" element={<Navigate replace to="/" />} />
      <Route
        path="/server-error"
        element={<ErrorPage variant="server-error" />}
      />
      <Route path="/not-found" element={<ErrorPage variant="not-found" />} />
      <Route
        path="/something-wrong"
        element={<ErrorPage variant="something-wrong" />}
      />
    </Routes>
  );
};

export default AppRoutes;
