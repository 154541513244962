import React from "react";
import { Typography } from "@mui/material";

const HeaderText: React.FC<{ text: string }> = ({ text }) => {
  return (
    <Typography
      sx={{
        fontFamily: "Comfortaa, sans-serif",
        fontSize: "48px",
        fontWeight: 700,
        lineHeight: "57.6px",
        letterSpacing: "-0.01em",
        color: "rgba(255, 255, 255, 1)",
        textAlign: "center",
        display: "inline-block",
      }}
    >
      {text}
    </Typography>
  );
};
export default HeaderText;
