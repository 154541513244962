import { useQuery } from "@tanstack/react-query";
import { useAuthToken } from "../../auth/authToken";
import { useResponseParser } from "../jsonResponseParser";
import { TenantRoles } from "../../types/user/tenantRoles";

export function useAllTenantsRolesQuery() {
  const { tokenHeaders, hasToken } = useAuthToken();
  const { parseJsonResponse } = useResponseParser();

  return useQuery({
    queryKey: [tokenHeaders, "/tenantsRoles/all"],
    queryFn: () =>
      fetch(`${process.env.REACT_APP_MC_URL}/api/tenants/roles`, {
        headers: tokenHeaders,
      }).then(parseJsonResponse<TenantRoles>),
    enabled: hasToken,
  });
}
