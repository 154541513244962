import { Box, Button, IconButton, Typography } from "@mui/material";
import {
  Control,
  useFieldArray,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { SectionHeader } from "../widgets";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { ReusableSelect, ReusableTextField } from "../../reusable";
import { TestingProtocol } from "../../../types/testingProtocols";
import { useMemo, useState } from "react";

const TestingProtocols = ({
  control,
  watch,
  name,
  testingProtocols,
  setValue,
}: {
  control: Control<any>;
  watch: UseFormWatch<any>;
  name: string;
  testingProtocols: TestingProtocol[];
  setValue: UseFormSetValue<any>;
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  const [index, setIndex] = useState(0);

  const testingProtocolsArray = watch(name);

  const handleAddNewField = () => {
    append(0);
  };

  const components = useMemo(() => {
    return fields?.map((field, ind) => (
      <Box
        key={field.id + index}
        sx={{
          display: "flex",
          backgroundColor: "#F6FBFF",
          position: "relative",
          width: "100%",
          gap: 4,
          p: "18px 24px",
          borderRadius: "12px",
          borderLeft: "4px solid #2974B1",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "45%",
            gap: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: 0.75,
            }}
          >
            <Typography
              fontSize={14}
              fontWeight={600}
              fontFamily="Open Sans"
              color="#383D45"
            >
              Testing Protocols
            </Typography>
            <ReusableSelect
              control={control}
              borderRadius="10px"
              placeholder="Test Name"
              name={`${name}[${ind}].name`}
              options={testingProtocols?.map((t) => t.name)}
              onChangeEffect={(isClear: boolean, selected: string) => {
                setIndex((prevVal) => prevVal + 1);
                if (isClear) {
                  setValue(`${name}[${ind}].id`, null);
                  setValue(`${name}[${ind}].cost`, 0);
                } else {
                  setValue(
                    `${name}[${ind}].id`,
                    testingProtocols.find((t) => t.name === selected)?.id
                  );
                  setValue(
                    `${name}[${ind}].cost`,
                    testingProtocols?.find((t) => t.name === selected)?.cost
                  );
                }
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              gap: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "70%",
              }}
            >
              <img
                src="/assets/nutripath.svg"
                alt="Nutripath"
                style={{
                  height: "50px",
                  marginTop: "10px",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1.25,
                width: "30%",
                mt: 0.75,
              }}
            >
              <Typography
                fontSize={14}
                fontWeight={600}
                fontFamily="Open Sans"
                color="#383D45"
              >
                Cost
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={400}
                fontFamily="Open Sans"
                color="#7D8189"
              >
                {`$ ${testingProtocolsArray?.[ind]?.cost ?? 0}`}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: 0.75,
          }}
        >
          <Typography
            fontSize={14}
            fontWeight={600}
            fontFamily="Open Sans"
            color="#383D45"
          >
            Note
          </Typography>
          <ReusableTextField
            control={control}
            borderRadius="10px"
            inputType="text"
            multiline
            rows={5}
            placeholder="Enter note..."
            name={`${name}[${ind}].note`}
          />
        </Box>
        <IconButton
          disableTouchRipple
          sx={{ ml: 1, mb: 1, position: "absolute", top: 0, right: 3 }}
          onClick={() => remove(ind)}
        >
          <img
            src="/assets/area-delete.svg"
            alt="delete"
            width={25}
            height={25}
          />
        </IconButton>
      </Box>
    ));
  }, [
    control,
    name,
    remove,
    fields,
    setValue,
    index,
    setIndex,
    testingProtocols,
    testingProtocolsArray,
  ]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: 3,
        mt: 9,
      }}
    >
      <SectionHeader title="Testing Protocols" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          px: "40px",
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 2,
          }}
        >
          {components}
        </Box>
        <Box>
          <Button
            disableRipple
            endIcon={<AddRoundedIcon />}
            sx={{
              mt: 1,
              px: 2,
              py: 1,
              bgcolor: "#E5EAEF",
              color: "#2974B1",
              textTransform: "none",
              borderRadius: "15px",
              "&:hover": {
                bgcolor: "#c1d1e0",
              },
            }}
            onClick={handleAddNewField}
          >
            <Typography fontSize={16} fontWeight={600} color="#2974B1">
              Add Testing Protocol
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default TestingProtocols;
