import { Button } from "@mui/material";
import { MouseEvent } from "react";
import { darkenColor } from "../../../utils/styling";
import InfinityLoader from "../LoadingSpinners/InfinityLoader";

type Props = {
  onClickFunction?: (event: MouseEvent<HTMLButtonElement>) => void;
  type?: "submit" | "button" | "reset" | undefined;
  variant?: "text" | "outlined" | "contained" | undefined;
  buttonText: string;
  borderRadius?: string;
  fontWeight?: number;
  color?: string;
  backgroundColor?: string;
  disabled?: boolean;
  width?: string;
  isLoading?: boolean;
  loaderColor?: string;
  beforeIcon?: any;
  afterIcon?: any;
  border?: string;
  height?: string;
  infinityLoaderData?: {
    color?: string;
    size?: string;
    speed?: string;
    stroke?: string;
    strokeLength?: string;
    bgOpacity?: string;
  };
};

const ReusableButton = ({
  onClickFunction,
  type = "submit",
  variant = "contained",
  buttonText,
  borderRadius = "30px",
  height = "46px",
  fontWeight = 700,
  color = "#FFFFFF",
  backgroundColor = "#2974B1",
  disabled = false,
  width = "30%",
  beforeIcon = null,
  afterIcon = null,
  isLoading = false,
  border = "none",
  infinityLoaderData = {
    color: "#ffff",
    size: "60",
    speed: "1.3",
    stroke: "4",
    strokeLength: "0.15",
    bgOpacity: "0",
  },
}: Props) => {
  const buttonContent = (
    <>
      {beforeIcon}
      {buttonText}
      {afterIcon}
    </>
  );

  return (
    <Button
      disableRipple
      disabled={disabled}
      type={type}
      onClick={onClickFunction}
      variant={variant}
      sx={{
        width: width,
        height: height,
        m: 1,
        border: border,
        textTransform: "none",
        borderRadius: borderRadius,
        fontWeight: fontWeight,
        backgroundColor: backgroundColor,
        color: color,
        transition: "background-color 0.3s",
        lineHeight: "15px",
        gap: 1,
        boxShadow: "none",
        "&:hover": {
          backgroundColor: darkenColor(backgroundColor, -20),
          boxShadow: "none",
        },
      }}
    >
      {isLoading ? (
        <InfinityLoader
          color={infinityLoaderData.color}
          size={infinityLoaderData.size}
          stroke={infinityLoaderData.stroke}
          bgOpacity={infinityLoaderData.bgOpacity}
        />
      ) : (
        buttonContent
      )}
    </Button>
  );
};

export default ReusableButton;
