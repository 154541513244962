import { Box, Typography } from "@mui/material";

type Props = {
  title: string;
  info: string;
  image: string;
  dietType: string;
  mealTime: string;
  onClick: () => void;
};

const ProgramOverviewMealCard = ({
  title,
  info,
  image,
  dietType,
  mealTime,
  onClick,
}: Props) => {
  return (
    <Box
      sx={{
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        gap: 1.5,
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "160px",
          borderRadius: "10px",
          overflow: "hidden",
        }}
      >
        <img
          src={image}
          alt="meal"
          style={{
            width: "100%",
            height: "160px",
            objectFit: "cover",
          }}
        />
      </Box>
      <Typography
        fontSize="16px"
        fontWeight={700}
        color="#000000"
        lineHeight="22.4px"
      >
        {title}
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          width: "100%",
        }}
      >
        <Box
          sx={{
            py: "2px",
            px: "12px",
            borderRadius: "12px",
            border: "1px solid #2974B1",
            backgroundColor: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography fontSize={14} fontWeight={500} color="#2974B1">
            {dietType}
          </Typography>
        </Box>
        <Box
          sx={{
            py: "2px",
            px: "12px",
            borderRadius: "12px",
            backgroundColor: "#2974B1",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography fontSize={14} fontWeight={500} color="white">
            {mealTime}
          </Typography>
        </Box>
      </Box>
      <Typography
        fontSize="16px"
        fontWeight={400}
        color="#000000"
        lineHeight="22.4px"
      >
        {info}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          width: "100%",
          mt: "-10px",
        }}
      >
        <img
          onClick={onClick}
          src="/assets/arrow-down.svg"
          alt="arrow-down"
          style={{
            width: "14px",
            height: "14px",
            cursor: "pointer",
          }}
        />
      </Box>
    </Box>
  );
};

export default ProgramOverviewMealCard;
