import { Box, Chip, Typography } from "@mui/material";
import { ReusableButton } from "../../reusable";

type Props = {
  workout: Workout;
  handleOpenAddToProgramModal: () => void;
};

const WorkoutLibraryCard = ({
  workout,
  handleOpenAddToProgramModal,
}: Props) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: 300,
        border: "1px solid #E4E7EC",
        borderRadius: "12px",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: 149,
          flexShrink: 0,
          overflow: "hidden",
          position: "relative",
        }}
      >
        <img
          src="/assets/workout-placeholder.png"
          alt="placeholder"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: 20,
            left: 20,
            px: 1,
            py: 0.5,
            fontFamily: "Comfortaa",
            fontSize: "16px",
            fontWeight: "600",
            color: "white",
            zIndex: 2,
            bgcolor: "#2974B1",
            borderRadius: 2,
          }}
        >
          {workout.type}
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1, px: 2 }}>
        <Typography
          fontWeight={900}
          fontSize="16px"
          color="#000000"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            py: 1,
          }}
        >
          {workout.name}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            gap: 1,
          }}
        >
          <Chip
            label={workout.difficulty}
            variant="outlined"
            sx={{
              color: "#2974B1",
              borderColor: "#2974B1",
              backgroundColor: "#ffffff",
              fontSize: "14px",
              fontWeight: 500,
              borderRadius: "16px",
              paddingX: "12px",
              paddingY: "2px",
            }}
          />
          <Typography
            fontSize={14}
            fontWeight={400}
            color="#2974B1"
            fontFamily="Poppins"
          >
            {`${workout.duration}min`}
          </Typography>
          <Typography
            fontSize={14}
            fontWeight={400}
            color="#7D8189"
            fontFamily="Poppins"
          >
            {`${111} Participants`}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            pb: 2,
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography fontSize={14} fontWeight={600} color="#7D8189">
            Adam Kelly
          </Typography>
          <ReusableButton
            buttonText={"Add to Program"}
            width="50%"
            height="32px"
            onClickFunction={handleOpenAddToProgramModal}
            backgroundColor="#2974B1"
            beforeIcon={<img src="/assets/plus-icon.svg" alt="icon" />}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default WorkoutLibraryCard;
