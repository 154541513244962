import { Box, Typography } from "@mui/material";
import ReadMoreButton from "./ReadMoreButton";
import React from "react";

const ContentSection: React.FC<{
  title: string;
  bodyText: string;
  expandedText?: string | React.ReactNode;
  isExpanded: boolean;
  onToggleExpand: () => void;
  buttonText: string;
  imagePosition: "left" | "right";
  imageSrc: string;
  imageAlt: string;
  backgroundColor: string;
  overlayImageSrc?: string;
  buttonColor?: string;
  hoverColor?: string;
  hoverTextColor?: string;
}> = ({
  title,
  bodyText,
  expandedText,
  isExpanded,
  onToggleExpand,
  buttonText,
  imagePosition,
  imageSrc,
  imageAlt,
  backgroundColor,
  overlayImageSrc,
  buttonColor,
  hoverColor,
  hoverTextColor,
}) => (
  <Box
    sx={{
      display: "inline-grid",
      gridTemplateColumns:
        imagePosition === "left" ? "1fr 1.1fr" : "1.1fr 0.9fr",
      backgroundColor: backgroundColor,
      padding: "48px 0px",
      alignItems: "center",
      position: "relative",
      width: "100vw",
      transition: "height 0.3s ease",
      height: isExpanded ? "auto" : "407px",
    }}
  >
    {/* Image Section */}
    {imagePosition === "left" && (
      <Box
        sx={{
          position: "relative",
          width: "100%",
          maxWidth: "468px",
          height: "auto",
          borderRadius: "24px 0px 0px 0px",
          display: "flex",
          alignItems: "center",
          margin: "auto",
        }}
      >
        <img
          alt={imageAlt}
          src={imageSrc}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "24px 0px 0px 0px",
          }}
        />
      </Box>
    )}

    {/* Text Section */}
    <Box
      sx={{
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        maxWidth: "600px",
        margin: "auto",
      }}
    >
      <Typography
        sx={{
          fontFamily: "Comfortaa",
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "33.6px",
          letterSpacing: "-0.01em",
          textAlign: "left",
          color: "rgba(57, 146, 221, 1)",
          marginBottom: "16px",
        }}
      >
        {title}
      </Typography>

      <Typography
        sx={{
          fontFamily: "Comfortaa",
          fontSize: "16px",
          fontWeight: 400,
          lineHeight: "22.4px",
          textAlign: "left",
          color: "rgba(61, 64, 67, 1)",
          marginBottom: "16px",
        }}
      >
        {bodyText}
      </Typography>

      {isExpanded && (
        <Typography
          sx={{
            fontFamily: "Comfortaa",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "22.4px",
            textAlign: "left",
            color: "rgba(61, 64, 67, 1)",
            marginBottom: "16px",
          }}
        >
          {expandedText}
        </Typography>
      )}

      <ReadMoreButton
        onClick={onToggleExpand}
        isExpanded={isExpanded}
        buttonText={buttonText}
        buttonColor={buttonColor} // Pass buttonColor prop
        hoverColor={hoverColor}
        hoverTextColor={hoverTextColor}
      />
    </Box>

    {/* Image Section */}
    {imagePosition === "right" && (
      <Box
        sx={{
          position: "relative",
          width: "100%",
          maxWidth: "468px",
          height: "auto",
          borderRadius: "24px 0px 0px 0px",
          display: "flex",
          alignItems: "center",
          margin: "auto",
        }}
      >
        <img
          alt={imageAlt}
          src={imageSrc}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "24px",
          }}
        />
        {overlayImageSrc && (
          <img
            alt="overlay"
            src={overlayImageSrc}
            style={{
              position: "absolute",
              top: "calc(50% + 20px)",
              left: "calc(50% + 20px)",
              width: "268px",
              height: "280px",
              objectFit: "cover",
              borderRadius: "24px",
              zIndex: 10,
            }}
          />
        )}
      </Box>
    )}
  </Box>
);

export default ContentSection;
