import { Box, Typography } from "@mui/material";
import { TestingProtocol } from "../../../../../types/testingProtocols";
import { ReusableButton } from "../../../../reusable";

const TestingProtocolCard = ({
  testingProtocol,
}: {
  testingProtocol: TestingProtocol;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: 1,
        border: "1px solid #E4E7EC",
        borderRadius: "12px",
        padding: "24px 12px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          gap: 1,
          paddingBottom: "25px",
          borderBottom: "1px solid #E4E7EC",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: 1,
            justifyContent: "center",
          }}
        >
          <Typography
            fontWeight={800}
            fontSize="16px"
            lineHeight="22.4px"
            color="#475367"
            fontFamily="Comfortaa, sans-serif"
          >
            {testingProtocol.name}
          </Typography>
          <Typography
            fontWeight={400}
            fontSize="12px"
            lineHeight="14.4px"
            color="#2974B1"
            fontFamily="Open Sans"
          >
            A series of full body tests to unlock valuable insights into your
            well-being.
          </Typography>
        </Box>
        <img
          src={"/assets/nutripath-logo.svg"}
          alt="placeholder"
          width={"35px"}
          height={"35px"}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          gap: 1,
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: 2,
            mt: 1,
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              gap: 2,
            }}
          >
            <Box
              sx={{
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                backgroundColor: "#2974B1",
                display: "inline-block",
              }}
            />
            <Typography
              fontWeight={600}
              fontSize="12px"
              lineHeight="14.4px"
              color="#615959"
              fontFamily="Comfortaa, sans-serif"
            >
              20 tests done
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              gap: 2,
            }}
          >
            <Box
              sx={{
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                backgroundColor: "#2974B1",
                display: "inline-block",
              }}
            />
            <Typography
              fontWeight={600}
              fontSize="12px"
              lineHeight="14.4px"
              color="#615959"
              fontFamily="Comfortaa, sans-serif"
            >
              Report in 3 days
            </Typography>
          </Box>
        </Box>
        <ReusableButton
          buttonText={"View Results"}
          width="35%"
          onClickFunction={() => {}}
          backgroundColor="#2974B1"
        />
      </Box>
    </Box>
  );
};

export default TestingProtocolCard;
