import * as yup from "yup";

export const marketplaceFiltersSchema = yup
  .object()
  .shape({
    programs: yup.array().of(yup.string()),
    maxPrice: yup.number(),
    supplier: yup.array().of(yup.string()),
    type: yup.array().of(yup.string()),
    sortBy: yup.string(),
    searchText: yup.string(),
  })
  .required();

export const marketplaceFiltersDefaultValues = {
  programs: [],
  maxPrice: 0,
  supplier: [],
  type: [],
  sortBy: "",
  searchText: "",
};

export const marketplaceModalSchema = yup
  .object()
  .shape({
    programName: yup.string().required("This field is required"),
    supplementId: yup.number().required("This field is required"),
    usage: yup
      .string()
      .required("This field is required")
      .max(255, "Usage note must not exceed 255 characters"),
  })
  .required();

export type MarketplaceSupplementData = yup.InferType<
  typeof marketplaceModalSchema
>;
