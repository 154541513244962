import { Close as CloseIcon } from "@mui/icons-material";
import { Box, FormControl, IconButton, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { Controller } from "react-hook-form";

type Props = {
  form: any;
  name: string;
  width?: string;
  height?: string;
};

//NOT FINISHED!

const ReusableVideoUpload = ({
  form,
  name,
  width = "100%",
  height = "100%",
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { control } = form;
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleDeleteFile = () => {
    setSelectedFile(null);
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange } }) => (
        <FormControl
          fullWidth
          sx={{
            position: "relative",
            width,
            height,
            textAlign: "center",
          }}
        >
          <Box
            sx={{
              borderRadius: "12px",
              border: "2px dashed #688EA1",
              overflow: "hidden",
              backgroundColor: "#F2F5F6",
              width: "100%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              cursor: "pointer",
              py: 2,
            }}
            onDragOver={(e) => {
              e.preventDefault();
            }}
            onDragEnter={(e) => {
              e.preventDefault();
            }}
            onClick={handleButtonClick}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "18px",
                  marginBottom: "6px",
                }}
              >
                Video
              </Typography>
              <img
                src="/assets/upload-video-icon.svg"
                alt="Upload"
                style={{
                  width: 40,
                  height: 40,
                  cursor: "pointer",
                }}
              />
              <Box sx={{ display: "flex", gap: 1, paddingX: 12 }}>
                <button
                  type="button"
                  style={{
                    cursor: "pointer",
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                >
                  <Typography
                    color="#2974B1"
                    fontSize={16}
                    sx={{ whiteSpace: "nowrap", fontWeight: 600 }}
                  >
                    Click to upload
                  </Typography>
                </button>
                <Typography
                  fontSize={16}
                  fontWeight={400}
                  color="#383D45"
                  sx={{ whiteSpace: "nowrap" }}
                >
                  or drag and drop
                </Typography>
              </Box>
              <Typography fontSize={12} fontWeight={400} color="text.secondary">
                MP4, MOV, WMV or WebM (max. 1920x1080px)
              </Typography>
              <input
                ref={inputRef}
                type="file"
                accept="video/*"
                style={{ display: "none" }}
                onChange={(e) => {
                  handleFileChange(e);
                  onChange(e.target.files?.[0]);
                }}
              />
            </Box>
          </Box>
          {selectedFile && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                padding: "8px 16px",
                marginTop: "16px",
                borderRadius: "8px",
                backgroundColor: "#ffffff",
                boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src="/assets/upload-video-icon.svg"
                  alt="Video Icon"
                  style={{
                    width: 40,
                    height: 40,
                    marginRight: 16,
                  }}
                />
                <Box
                  sx={{
                    textAlign: "start",
                  }}
                >
                  <Typography fontSize={14} fontWeight={600}>
                    {selectedFile.name}
                  </Typography>
                  <Typography fontSize={12} color="text.secondary">
                    {(selectedFile.size / 1024).toFixed(2)} KB
                  </Typography>
                </Box>
              </Box>
              <IconButton onClick={handleDeleteFile}>
                <CloseIcon />
              </IconButton>
            </Box>
          )}
        </FormControl>
      )}
    />
  );
};

export default ReusableVideoUpload;
