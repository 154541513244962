import { QuestionType } from "../../types/onboarding/onboarding";

export const userProcessOneQuestions: QuestionType[] = [
  {
    id: "processOneData.gender",
    serialNum: "1",
    questionType: "radioButton",
    condition: null,
  },
  {
    id: "processOneData.age",
    serialNum: "2",
    questionType: "textInput",
    condition: null,
  },
  {
    id: "processOneData.height",
    serialNum: "3",
    questionType: "textInput",
    condition: null,
  },
  {
    id: "processOneData.weight",
    serialNum: "4",
    questionType: "textInput",
    condition: null,
  },
  {
    id: "processOneData.healthStatus",
    serialNum: "5",
    questionType: "radioButton",
    condition: null,
    children: [
      {
        id: "processOneData.diagnosis",
        serialNum: "5a",
        questionType: "textInput",
        condition: {
          parentQuestion: "processOneData.healthStatus",
          rule: "Yes",
        },
      },
    ],
  },
];

export const userProcessTwoQuestions: QuestionType[] = [
  {
    id: "processTwoData.dietType",
    serialNum: "1",
    questionType: "radioButton",
    condition: null,
  },
  {
    id: "processTwoData.foodAllergies",
    serialNum: "2",
    questionType: "radioButton",
    condition: null,
    children: [
      {
        id: "processTwoData.allergiesSpecification",
        serialNum: "2a",
        questionType: "textInput",
        condition: {
          parentQuestion: "processTwoData.foodAllergies",
          rule: "Yes",
        },
      },
    ],
  },
  {
    id: "processTwoData.mealFrequency",
    serialNum: "3",
    questionType: "textInput",
    condition: null,
  },
  {
    id: "processTwoData.snackPreferences",
    serialNum: "4",
    questionType: "radioButton",
    condition: null,
    children: [
      {
        id: "processTwoData.preferedSnacks",
        serialNum: "4a",
        questionType: "textInput",
        condition: {
          parentQuestion: "processTwoData.snackPreferences",
          rule: "Yes",
        },
      },
    ],
  },
];
export const userProcessThreeQuestions: QuestionType[] = [
  {
    id: "processThreeData.exerciseRegimen",
    serialNum: "1",
    questionType: "chipChecklist",
    condition: null,
  },
  {
    id: "processThreeData.typeOfExercise",
    serialNum: "2",
    questionType: "chipChecklist",
    condition: null,
    children: [
      {
        id: "processThreeData.typeOfExerciseOther",
        serialNum: "2a",
        questionType: "textInput",
        condition: {
          parentQuestion: "processThreeData.typeOfExercise",
          rule: "empty",
        },
      },
    ],
  },
  {
    id: "processThreeData.exerciseGoals",
    serialNum: "3",
    questionType: "chipChecklist",
    condition: null,
  },
];
export const userProcessFourQuestions: QuestionType[] = [
  {
    id: "processFourData.programIntensity",
    serialNum: "1",
    questionType: "radioButton",
    condition: null,
  },
  {
    id: "processFourData.areasOfInterest",
    serialNum: "2",
    questionType: "chipChecklist",
    condition: null,
    children: [
      {
        id: "processFourData.areasOfInterestOther",
        serialNum: "2a",
        questionType: "textInput",
        condition: {
          parentQuestion: "processFourData.areasOfInterest",
          rule: "empty",
        },
      },
    ],
  },
  {
    id: "processFourData.diagnosticsInterest",
    serialNum: "3",
    questionType: "chipChecklist",
    condition: null,
    children: [
      {
        id: "processFourData.diagnosticsInterestTypes",
        serialNum: "3a",
        questionType: "textInput",
        condition: {
          parentQuestion: "processFourData.diagnosticsInterest",
          rule: "Yes",
        },
      },
    ],
  },
];
export const userProcessFiveQuestions: QuestionType[] = [
  {
    id: "processFiveData.communityEngagement",
    serialNum: "1",
    questionType: "radioButton",
    condition: null,
  },
  {
    id: "processFiveData.challengesAndRewards",
    serialNum: "2",
    questionType: "radioButton",
    condition: null,
  },
  {
    id: "processFiveData.socialMedicineMovement",
    serialNum: "3",
    questionType: "radioButton",
    condition: null,
    children: [
      {
        id: "processFiveData.socialMedicineMovementTracking",
        serialNum: "3a",
        questionType: "radioButton",
        condition: {
          parentQuestion: "processFiveData.socialMedicineMovement",
          rule: "Yes",
        },
      },
    ],
  },
  {
    id: "processFiveData.contentPreferences",
    serialNum: "4",
    questionType: "chipChecklist",
    condition: null,
  },
];
export const userProcessSixQuestions: QuestionType[] = [
  {
    id: "processSixData.preferredCommunicationMethod",
    serialNum: "1",
    questionType: "chipChecklist",
    condition: null,
  },
  {
    id: "processSixData.programStartDate",
    serialNum: "2",
    questionType: "dateInput",
    condition: null,
  },
  {
    id: "processSixData.consent",
    serialNum: "2",
    questionType: "textInput",
    condition: null,
  },
];
