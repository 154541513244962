import { useQuery } from "@tanstack/react-query";
import { useAuthToken } from "../../auth/authToken";
import { useResponseParser } from "../jsonResponseParser";
import { User } from "../../types/user/user";

export function useUsersMeQuery() {
  const { tokenHeaders, hasToken, strapiUrl } = useAuthToken();
  const { parseJsonResponse } = useResponseParser();

  return useQuery({
    queryKey: [tokenHeaders, "/me"],
    queryFn: () =>
      fetch(`${strapiUrl}/api/users/me?populate=deep,2`, {
        headers: tokenHeaders,
      }).then(parseJsonResponse<User>),
    enabled: hasToken,
  });
}
