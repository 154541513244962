import { createTheme } from "@mui/material/styles";

export const userOnboardingCustomTheme = createTheme({
  palette: {
    primary: {
      main: "#1E6F30",
    },
    secondary: {
      main: "#FFAD01",
    },
  },
  typography: {
    fontFamily: [
      "Open Sans",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

export const userHeaderObjects = [
  {
    id: 1,
    stepNumber: "1",
    title: "Process 1",
    subtitle: "Demographics",
    filled: false,
  },
  {
    id: 2,
    stepNumber: "2",
    title: "Process 2",
    subtitle: "Dietary Preferences",
    filled: false,
  },
  {
    id: 3,
    stepNumber: "3",
    title: "Process 3",
    subtitle: "Exercise Regimen",
    filled: false,
  },
  {
    id: 4,
    stepNumber: "4",
    title: "Process 4",
    subtitle: "Health Program Level",
    filled: false,
  },
  {
    id: 5,
    stepNumber: "5",
    title: "Process 5",
    subtitle: "Engagement and Social Preferences",
    filled: false,
  },
  {
    id: 6,
    stepNumber: "6",
    title: "Process 6",
    subtitle: "Final Details",
    filled: false,
  },
];
