import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuthToken } from "../../auth/authToken";

interface BlockUserData {
  email: string;
  blocked: boolean;
}

export function useBlockUserMutation() {
  const { tokenHeaders } = useAuthToken();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: BlockUserData) => {
      return fetch(`${process.env.REACT_APP_MC_URL}/api/users/block`, {
        body: JSON.stringify(data),
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [tokenHeaders, "/users/all"] });
    },
  });
}
