import { Box, Typography } from "@mui/material";
import EstimatedTimeWidget from "../widgets/EstimatedTime";
import LinearProgressWidget from "../widgets/LinearProgress";
import Processes from "../widgets/Process";
import { useWizard } from "react-use-wizard";

type Props = {
  title: string;
  imgUrl: string;
  iconUrl?: string;
  totalProgress: number;
  processes: { label: string; filled: boolean }[];
  estimatedTime: string;
};

const ProfileCard = ({
  title,
  imgUrl,
  iconUrl,
  totalProgress,
  processes,
  estimatedTime,
}: Props) => {
  const { activeStep } = useWizard();

  return (
    <Box
      sx={{
        mb: 5,
        borderRadius: "30px",
        backgroundColor: "#E6E6E6",
        display: "flex",
        maxHeight: "140px",
        width: "100%",
      }}
    >
      <Box
        sx={{
          width: "20%",
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${imgUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          borderRadius: "30px",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "left",
        }}
      >
        {iconUrl && (
          <img
            src={iconUrl}
            alt={"Farmer icon"}
            style={{ maxHeight: "70px", userSelect: "none" }}
          />
        )}
      </Box>
      <Box
        sx={{
          marginLeft: 2,
          marginY: 2,
          marginX: 2,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box sx={{ width: "100%", height: "50%" }}>
          <Typography
            sx={{
              ml: 1,
              mb: 1,
              mt: 1.5,
              fontWeight: "600",
              fontSize: "24px",
              fontFamily: "Open Sans",
            }}
          >
            {title}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 2,
            width: "100%",
            height: "50%",
          }}
        >
          <Processes activeStep={activeStep} processes={processes} />
          <LinearProgressWidget value={totalProgress} />
          <EstimatedTimeWidget time={estimatedTime} />
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileCard;
