import { Box } from "@mui/material";
import {
  ReusableImageUpload,
  ReusableSelect,
  ReusableTextField,
} from "../../reusable";
import { mealLibraryFilterOptions } from "../../../constants/program-library/meal-library";
import ReusableTextEditor from "../../reusable/Form/ReusableTextEditor";

type Props = {
  form: any;
};

const MealForm = ({ form }: Props) => {
  const { control } = form;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          width: "100%",
          height: "100%",
        }}
      >
        <Box sx={{ width: "50%", height: 150 }}>
          <ReusableImageUpload form={form} name="image" label="Image" />
        </Box>
        <Box sx={{ width: "50%" }}>
          <ReusableTextField
            control={control}
            label="Meal Name"
            name="name"
            inputType="text"
            placeholder="Meal Name"
            borderRadius="8px"
            fullWidth
          />
          <ReusableSelect
            control={control}
            label="Meal Type"
            name="type"
            placeholder="Meal Type"
            borderRadius="8px"
            options={mealLibraryFilterOptions.mealType}
          />
        </Box>
      </Box>
      <ReusableSelect
        control={control}
        label="Diet Plan"
        name="dietPlan"
        placeholder="Choose Diet plan"
        borderRadius="8px"
        options={mealLibraryFilterOptions.dietType}
      />
      <ReusableTextField
        control={control}
        label="Description"
        name="description"
        inputType="text"
        placeholder="Enter Meal description"
        borderRadius="8px"
        multiline
        rows={3}
      />
      <Box sx={{ display: "flex", gap: 2 }}>
        <ReusableTextField
          control={control}
          label="Preparation time"
          name="preparationTime"
          inputType="number"
          placeholder="Enter preparation time"
          borderRadius="8px"
        />
        <ReusableTextField
          control={control}
          label="Calories"
          name="calories"
          inputType="number"
          placeholder="Enter number of calories"
          borderRadius="8px"
        />
      </Box>
      <ReusableTextEditor
        control={control}
        name="recipe"
        label="Recipe"
        placeholder="Enter Ingredients and Preparation instructions..."
      />
      <Box mt={6}>
        <ReusableTextEditor
          control={control}
          name="references"
          label="References"
          placeholder="Enter Peer-Reviewed References"
        />
      </Box>
    </Box>
  );
};

export default MealForm;
