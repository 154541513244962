import { useQuery } from "@tanstack/react-query";
import { useAuthToken } from "../../auth/authToken";
import { useUsersMeQuery } from "../users/usersMeQuery";
import { coreServiceAxios } from "../../config/axios-instance";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

interface UseOneProgramQueryProps {
  id: string | undefined;
}

export function useProgramByIdQuery(props: UseOneProgramQueryProps) {
  const { hasToken } = useAuthToken();
  const { data: me } = useUsersMeQuery();
  const shouldEnableQuery =
    hasToken && me?.id !== undefined && props.id !== undefined;

  return useQuery({
    queryKey: [`/healthProgram/${props.id}`],
    queryFn: () =>
      coreServiceAxios
        .get(`api/health/program/${props.id}`)
        .then((response: any) => {
          const binaryString = atob(response.data.program.mealPlan);
          const len = binaryString.length;
          const bytes = new Uint8Array(len);
          for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
          }

          // Create a Blob and then a File from the Uint8Array
          const blob = new Blob([bytes], { type: "application/pdf" });
          const newFile = new File([blob], "mealPlan.pdf", {
            type: "application/pdf",
          });
          return {
            programName: response.data.program.name,
            difficulty: response.data.program.difficulty,
            category: response.data.program.category,
            objectives: response.data.program.objectives,
            summary: response.data.program.summary,
            mealPlan: newFile,
            supplements: response.data.program.supplements,
            testingProtocols: response.data.program.testingProtocols,
            workoutRegime: {
              days: response.data.program.workoutDays?.map((day: any) =>
                dayjs(day.date)
              ),
              workoutPlan: response.data.program.workoutPlan,
            },
          };
        }),
    enabled: Boolean(shouldEnableQuery),
  });
}
