export const workoutLibFilterOptions = {
  type: [
    "Full Body Workout",
    "Fitness",
    "HIIT",
    "Strength Training",
    "Aerobic",
    "Weight Loss",
  ],
  difficulty: ["Beginner", "Intermediate", "Advance"],
};
