import { Modal, Box, Typography, Link } from "@mui/material";
import { Supplements } from "../../types/supplements";
import { useForm } from "react-hook-form";
import {
  marketplaceModalSchema,
  MarketplaceSupplementData,
} from "../../schemas/marketplace/marketplace";
import { yupResolver } from "@hookform/resolvers/yup";
import { ReusableButton, ReusableSelect, ReusableTextField } from "../reusable";
import { ProgramsType } from "../../types/programs/ProgramsType";
import { useMemo, useState } from "react";
import { useSupplementToProgramMutation } from "../../api/supplements/supplementToProgramMutation";

type Props = {
  open: boolean;
  handleClose: any;
  supplement: Supplements | null;
  allPrograms: ProgramsType | undefined;
};
const SupplementModal: React.FC<Props> = ({
  open,
  handleClose,
  supplement,
  allPrograms,
}) => {
  const { mutate: supplementToProgram } = useSupplementToProgramMutation();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      supplementId: supplement?.id ?? 0,
      programName: "",
      usage: "",
    },
    resolver: yupResolver(marketplaceModalSchema),
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const programsOptions = useMemo(() => {
    return allPrograms?.map((p) => p.name);
  }, [allPrograms]);

  const onSubmit = async (value: MarketplaceSupplementData) => {
    if (isLoading) return;
    setIsLoading(true);

    const selectedProgram = allPrograms?.find(
      (p) => p.name === value.programName
    );
    try {
      supplementToProgram({
        programId: selectedProgram?.id ?? null,
        data: value,
      });
      setIsLoading(false);
      handleClose();
    } catch (error) {
      setIsLoading(false);
    }
  };
  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "8px",
      }}
    >
      <Box
        sx={{
          overflowY: "auto",
          backgroundColor: "background.paper",
          boxShadow: 5,
          outline: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "10px",
          padding: " 30px 39px",
          width: "600px",
          height: "auto",
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1.5,
            width: "100%",

            backgroundColor: "white",
          }}
        >
          <Box
            sx={{
              borderRadius: "10px",
              display: "flex",
              gap: 1.5,
              width: "100%",
            }}
          >
            <Box
              sx={{
                borderRadius: "10px",
                overflow: "hidden",
                height: "175px",
              }}
            >
              <img
                src={supplement?.image ?? "/assets/protein-powder.jpg"}
                alt="meal"
                style={{
                  width: "450px",
                  height: "175px",
                  objectFit: "cover",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                gap: 3,
                width: "100%",
                height: "175px",
              }}
            >
              <Typography
                fontSize="24px"
                fontWeight={700}
                color="#000000"
                lineHeight="33.6px"
              >
                {supplement?.name}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    py: "2px",
                    px: "12px",
                    borderRadius: "12px",
                    border: "1px solid #2974B1",
                    backgroundColor: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <img
                    src="/assets/schedule-icon.svg"
                    alt="schedule icon"
                    width={13}
                    height={13}
                  />
                  <Typography fontSize={14} fontWeight={500} color="#2974B1">
                    {supplement?.dosage}
                  </Typography>
                </Box>
                <Typography fontSize={14} fontWeight={600} color="#000000">
                  {`$ AUD ${supplement?.cost}/month`}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Typography
            fontSize="16px"
            fontWeight={600}
            color="#000000"
            lineHeight="22.4px"
            mt={1}
          >
            {supplement?.benefits}
          </Typography>

          <Typography fontSize={14} fontWeight={600} color="#606367">
            Reference
          </Typography>
          <Link
            href={supplement?.link}
            sx={{
              color: "#2974B1",
              fontWeight: 400,
              fontSize: "16px",
              fontStyle: "italic",
              borderBottom: "1px solid #E4E7EC",
              paddingBottom: "10px",
            }}
            target="_blank"
          >
            {supplement?.linkText}
          </Link>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: 1,
            }}
          >
            <Box>
              <Typography fontSize={14} fontWeight={600} color="#383D45">
                Select the program from the list
              </Typography>
              <ReusableSelect
                control={control}
                borderRadius="10px"
                placeholder="Select Program"
                name="programName"
                options={programsOptions ?? []}
              />
            </Box>
            <Box>
              <Typography fontSize={14} fontWeight={600} color="#383D45">
                Usage
              </Typography>
              <ReusableTextField
                control={control}
                borderRadius="10px"
                inputType="text"
                multiline
                rows={6}
                placeholder="Enter usage note..."
                name="usage"
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <ReusableButton
              buttonText={"Cancel"}
              color="#4F5359"
              backgroundColor="#FFFFFF"
              width="50%"
              onClickFunction={handleClose}
              border="1px solid #D0D5DD"
            />
            <ReusableButton
              buttonText={"Add to Program"}
              width="50%"
              onClickFunction={handleSubmit(onSubmit)}
              backgroundColor="#2974B1"
              isLoading={isLoading}
              beforeIcon={<img src="/assets/plus-icon.svg" alt="icon" />}
            />
          </Box>
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: 27,
            right: 22,
            cursor: "pointer",
          }}
          onClick={handleClose}
        >
          <img
            src="/assets/close-icon.svg"
            alt="close"
            style={{
              width: "12px",
              height: "12px",
            }}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default SupplementModal;
