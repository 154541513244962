import { useState } from "react";
import { Controller, Control } from "react-hook-form";
import {
  TextField,
  InputAdornment,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import PasswordRules from "./PasswordRules";

type Props = {
  control: Control<any>;
  id: string;
  name: string;
  inputType: string;
  label: string;
  placeholder?: string;
  autoComplete?: string;
  borderRadius?: string;
  isRequired?: boolean;
  fullWidth?: boolean;
  autofocus?: boolean;
};

const PasswordTextField = ({
  control,
  id,
  name,
  inputType,
  label,
  placeholder = "",
  autoComplete,
  borderRadius = "8px",
  isRequired = true,
  fullWidth = true,
  autofocus = false,
}: Props) => {
  const [isPassword, setIsPassword] = useState(inputType === "password");
  const handleClickIsPassword = () => setIsPassword(!isPassword);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: isRequired,
      }}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { invalid, error, isTouched },
      }) => (
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
          <Typography
            style={{ fontSize: "14px", fontWeight: "500", textAlign: "left" }}
          >
            {label}
          </Typography>
          <TextField
            hiddenLabel
            sx={{
              mt: 0.5,
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "#2974B1",
                  borderWidth: "2px",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderWidth: "1px",
                },
                backgroundColor: "#fff",
              },
            }}
            size="small"
            ref={ref}
            value={value}
            onChange={onChange}
            onBlur={() => {
              onBlur();
            }}
            margin="normal"
            id={id}
            type={inputType === "password" && !isPassword ? "text" : inputType}
            name={name}
            placeholder={placeholder}
            required={isRequired}
            fullWidth={fullWidth}
            autoComplete={autoComplete}
            autoFocus={autofocus}
            error={invalid}
            helperText={<PasswordRules value={value} invalid={invalid} />}
            InputProps={{
              style: {
                borderRadius: borderRadius,
              },
              endAdornment: inputType === "password" && (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickIsPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {isPassword ? (
                      <VisibilityOff style={{ color: "#cfcfcf" }} />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      )}
    />
  );
};

export default PasswordTextField;
