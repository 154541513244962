import React from "react";

const VerticalLines: React.FC = () => {
  return (
    <div style={{ display: "flex" }}>
      <div style={{ borderRight: `4px solid #FFAD01`, marginRight: "3px" }} />
      <div style={{ borderRight: `4px solid #2974B1`, marginRight: "3px" }} />
      <div style={{ borderRight: `4px solid #785134` }} />
    </div>
  );
};

export default VerticalLines;
