import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuthToken } from "../../auth/authToken";
import { useUsersMeQuery } from "../users/usersMeQuery";

export function useUserExerciseProgramMutation() {
  const { tokenHeaders } = useAuthToken();
  const queryClient = useQueryClient();
  const { data: me } = useUsersMeQuery();

  return useMutation({
    mutationFn: (data: any) => {
      return fetch(
        `${process.env.REACT_APP_CORE_SERVICE_URL}/api/users/onboarding/exerciseProgram/${me?.id}`,
        {
          body: JSON.stringify(data),
          method: "POST",
          headers: {
            ...tokenHeaders,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["api/users/onboarding"] });
    },
  });
}
