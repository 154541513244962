import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useWizard } from "react-use-wizard";

type Props = {
  headerObjects: {
    id: number;
    stepNumber: string;
    title: string;
    subtitle: string;
    filled: boolean;
  }[];
};

const Header = ({ headerObjects }: Props) => {
  const [headerData, setHeaderData] = useState(headerObjects[0]);
  const { activeStep, goToStep } = useWizard();

  useEffect(() => {
    setHeaderData(headerObjects[activeStep]);
  }, [activeStep, headerObjects]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        position: "relative",
        textAlign: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "10%",
          color: headerObjects[activeStep].filled ? "#fff" : "#1A1A1A",
          background: headerObjects[activeStep].filled
            ? "linear-gradient(to right,#F2B9FD,#3992DD)"
            : "#A1C7E7",
          borderRadius: "14px",
          textAlign: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <Typography sx={{ fontSize: "45px", fontWeight: "bold" }}>
          {headerData.stepNumber}
        </Typography>
        {headerObjects[activeStep].filled && (
          <img
            src="/assets/success-icon.svg"
            alt="Success Icon"
            style={{
              position: "absolute",
              top: "3px",
              right: "3px",
              width: "24px",
              height: "24px",
            }}
          />
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "90%",
          textAlign: "left",
          marginLeft: "20px",
        }}
      >
        <Typography
          sx={{ fontSize: "16px", fontWeight: "700", color: "#785135" }}
        >
          {headerData.title}
        </Typography>
        <Typography
          sx={{ fontSize: "30px", fontWeight: "600", fontFamily: "Comfortaa" }}
        >
          {headerData.subtitle}
        </Typography>
      </Box>
      <Box
        sx={{
          position: "absolute",
          width: "30%",
          display: "flex",
          flexDirection: "column",
          gap: 1,
          top: 100,
          left: 0,
          textAlign: "left",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: activeStep === 0 ? "600" : "400",
            color: activeStep === 0 ? "#2974B1" : "#475367",
            cursor: "pointer",
          }}
          onClick={() => goToStep(0)}
        >
          Demographics
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: activeStep === 1 ? "600" : "400",
            color: activeStep === 1 ? "#2974B1" : "#475367",
            cursor: "pointer",
          }}
          onClick={() => goToStep(1)}
        >
          Dietary Preferences
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: activeStep === 2 ? "600" : "400",
            color: activeStep === 2 ? "#2974B1" : "#475367",
            cursor: "pointer",
          }}
          onClick={() => goToStep(2)}
        >
          Exercise Regimen
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: activeStep === 3 ? "600" : "400",
            color: activeStep === 3 ? "#2974B1" : "#475367",
            cursor: "pointer",
          }}
          onClick={() => goToStep(3)}
        >
          Health Program Level
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: activeStep === 4 ? "600" : "400",
            color: activeStep === 4 ? "#2974B1" : "#475367",
            cursor: "pointer",
          }}
          onClick={() => goToStep(4)}
        >
          Engagement and Social Preferences
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: activeStep === 5 ? "600" : "400",
            color: activeStep === 5 ? "#2974B1" : "#475367",
            cursor: "pointer",
          }}
          onClick={() => goToStep(5)}
        >
          Final Details
        </Typography>
      </Box>
    </Box>
  );
};

export default Header;
