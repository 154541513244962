import { Box } from "@mui/material";
import ProgramOverviewWidgetHolder from "../widgets/ProgramOverviewWidgetHolder";
import { Supplements as SupplementsType } from "../../../../../types/supplements";
import SupplementCard from "../widgets/SupplementCard";

const Supplements = ({ supplements }: { supplements: SupplementsType[] }) => {
  return (
    <ProgramOverviewWidgetHolder title="Supplements">
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "31.8% 31.8% 31.8%",
          gap: 3,
          width: "100%",
        }}
      >
        {supplements?.map((s) => (
          <SupplementCard supplement={s} />
        ))}
      </Box>
    </ProgramOverviewWidgetHolder>
  );
};

export default Supplements;
