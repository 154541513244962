import { Box } from "@mui/material";
import { Control, UseFormWatch } from "react-hook-form";
import { SectionHeader } from "../widgets";
import { ReusableSelect, ReusableTextField } from "../../reusable";
import ReusableTextEditor from "../../reusable/Form/ReusableTextEditor";

const ProgramDetails = ({
  control,
  watch,
}: {
  control: Control<any>;
  watch: UseFormWatch<any>;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: 3,
      }}
    >
      <SectionHeader title="Program details" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          px: "40px",
          gap: 2,
        }}
      >
        <ReusableTextField
          control={control}
          name="programName"
          inputType="text"
          label="Program Name"
          placeholder="Enter Program Name"
        />
        <Box sx={{ display: "flex", gap: 2 }}>
          <ReusableSelect
            control={control}
            name="difficulty"
            options={["Beginner", "Intermediate", "Advanced"]}
            label="Difficulty"
            placeholder="Select level"
          />
          <ReusableSelect
            control={control}
            name="category"
            options={[
              "Longevity",
              "Diabetes",
              "Obesity",
              "Menopause",
              "Cognitive Health",
            ]}
            label="Category"
            placeholder="Select category"
          />
        </Box>
        <Box>
          <ReusableTextEditor
            control={control}
            name="objectives"
            label="Program Objectives"
            placeholder="Enter Program Objectives..."
          />
        </Box>
        <Box sx={{ mt: 6 }}>
          <ReusableTextEditor
            control={control}
            name="summary"
            label="Program Summary"
            placeholder="Enter Program Summary..."
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ProgramDetails;
