import { Box } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { CSSObject, styled, Theme } from "@mui/material/styles";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  marginTop: "50px",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginTop: "50px",
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 10px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 10px)`,
  },
});

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "left",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

export const Content = styled(Box)(
  ({ theme, open }: { theme: Theme; open: boolean }) => ({
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up("sm")]: {},
  })
);

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export interface SidebarOption {
  section: string;
  label: string;
  icon: string;
  redirect: string;
  role: string[];
}

export const sidebarOptions: SidebarOption[] = [
  {
    section: "/profile",
    label: "Account Details",
    redirect: "/profile/account-details",
    icon: "/assets/user-circle-icon.svg",
    role: ["*"],
  },
  {
    section: "/profile",
    label: "Manage Profile",
    redirect: "/profile/manage-profiles",
    icon: "/assets/users-icon.svg",
    role: ["Farmer"],
  },
  {
    section: "/profile",
    label: "User Management",
    redirect: "/profile/users",
    icon: "/assets/users-edit-icon.svg",
    role: ["Admin"],
  },
  {
    section: "/profile",
    label: "General Settings",
    redirect: "/profile/general-settings",
    icon: "/assets/users-settings-account.svg",
    role: ["Admin"],
  },
];
