import Dropzone from "react-dropzone";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { Control, Controller } from "react-hook-form";
import { darkenColor } from "../../../utils/styling";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useState, useEffect } from "react";

interface Props {
  control: Control<any>;
  name: string;
}

function ReusablePdfFileUploader({ control, name }: Props) {
  const [fileUrl, setFileUrl] = useState<string | null>(null);

  useEffect(() => {
    // Revoke the previous URL to avoid memory leaks
    return () => {
      if (fileUrl) {
        URL.revokeObjectURL(fileUrl);
      }
    };
  }, [fileUrl]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => {
        if (value) {
          const file = value instanceof File ? value : value[0];
          if (file && !fileUrl) {
            const newUrl = URL.createObjectURL(file);
            setFileUrl(newUrl);
          }

          return (
            <Box
              sx={{
                border: "2px dashed #2974B1",
                borderRadius: "12px",
                height: "190px",
                padding: "20px",
                textAlign: "center",
                backgroundColor: "#F6FBFF",
                cursor: "pointer",
                transition: "background-color 0.2s ease",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
              }}
            >
              <Tooltip title={`Remove ${file.name}`}>
                <Button
                  sx={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                    color: "white",
                    backgroundColor: "#C41E3A",
                    textTransform: "none",
                    fontWeight: 600,
                    px: 1.25,
                    py: 0.5,
                    width: "auto",
                    minWidth: "0px",
                    borderRadius: "12px",
                    "&:hover": {
                      backgroundColor: darkenColor("#C41E3A", -20),
                    },
                  }}
                  onClick={() => {
                    onChange(null);
                    setFileUrl(null);
                  }}
                >
                  <DeleteForeverIcon sx={{ width: "20px", height: "20px" }} />
                </Button>
              </Tooltip>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "16px",
                  lineHeight: "20px",
                  color: "#464A51",
                }}
              >
                File Uploaded
              </Typography>
              <img
                src="/assets/pdf-upload.svg"
                alt="Upload"
                style={{ width: "40px", height: "40px", marginTop: "10px" }}
              />
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: "#464A51",
                  mt: 1,
                }}
              >
                Your meal plan{" "}
                <span style={{ color: "#2974B1", fontWeight: 600 }}>
                  {file.name}
                </span>{" "}
                is uploaded
              </Typography>

              {/* Download Button */}
              <Button
                sx={{
                  mt: 2,
                  backgroundColor: "#2974B1",
                  color: "white",
                  textTransform: "none",
                  fontWeight: 600,
                  "&:hover": {
                    backgroundColor: darkenColor("#2974B1", -20),
                  },
                }}
                href={fileUrl || ""}
                download={file.name}
              >
                Download PDF
              </Button>
            </Box>
          );
        }
        return (
          <Dropzone
            onDrop={(acceptedFiles) => {
              const selectedFile = acceptedFiles[0];
              onChange(selectedFile);
            }}
            maxFiles={1}
            accept={{
              "application/pdf": [".pdf"],
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <Box
                {...getRootProps()}
                sx={{
                  border: "2px dashed #2974B1",
                  borderRadius: "12px",
                  height: "190px",
                  padding: "20px",
                  textAlign: "center",
                  backgroundColor: "#F6FBFF",
                  cursor: "pointer",
                  transition: "background-color 0.2s ease",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <input {...getInputProps()} />
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: "16px",
                    lineHeight: "20px",
                    color: "#464A51",
                  }}
                >
                  File Upload
                </Typography>
                <img
                  src="/assets/pdf-upload.svg"
                  alt="Upload"
                  style={{ width: "40px", height: "40px", marginTop: "10px" }}
                />
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "20px",
                    color: "#464A51",
                    mt: 1,
                  }}
                >
                  <span style={{ color: "#2974B1", fontWeight: 600 }}>
                    Click to upload
                  </span>{" "}
                  or drag and drop
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "20px",
                    color: "#464A51",
                    mt: 0.75,
                  }}
                >
                  your meal plan .pdf file here
                </Typography>
              </Box>
            )}
          </Dropzone>
        );
      }}
    />
  );
}

export default ReusablePdfFileUploader;
