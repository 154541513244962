import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import BackToPrograms from "../widgets/BackToPrograms";
import ProgramCard from "./ProgramCard";
import { ProgramData } from "../../../schemas/programs/programs";

type Props = {
  program: ProgramData;
};

const ProgramOverviewHeader = ({ program }: Props) => {
  const navigate = useNavigate();

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <BackToPrograms link="/programs" title="Back to Programs" />
        <Typography
          component="div"
          fontSize={18}
          fontWeight={700}
          color="#6A4D39"
          fontFamily="Comfortaa, sans-serif"
        >
          <Typography
            component="span"
            sx={{
              fontSize: 18,
              fontWeight: 700,
              color: "#6A4D39",
              fontFamily: "Comfortaa, sans-serif",
              cursor: "pointer",
            }}
            onClick={() => navigate("/projects")}
          >
            Projects{" "}
          </Typography>
          / Program
        </Typography>
      </Box>
      <Box sx={{ width: "100%", mt: 3 }}>
        <ProgramCard programObject={program} />
      </Box>
    </Box>
  );
};

export default ProgramOverviewHeader;
