import { Box } from "@mui/material";
import SummaryAndExperts from "./SummaryAndExperts";
import Suppliers from "./Suppliers";
import MealPlan from "./MealPlan";
import { useState } from "react";
import MealModal, { MealType } from "./MealModal";
import Schedule from "./Schedule";
import { ProgramData } from "../../../../../schemas/programs/programs";
import Supplements from "./Supplements";
import TestingProtocols from "./TestingProtocols";

const MainOverview = ({ program }: { program: ProgramData }) => {
  const [meal, setMeal] = useState<MealType | null>(null);
  const [openModal, setOpenModal] = useState(false);

  return (
    <Box
      sx={{
        padding: 3,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        gap: 4,
      }}
    >
      <SummaryAndExperts
        programSummary={program?.summary?.replace(/<\/?[^>]+(>|$)/g, "") ?? ""}
      />
      <Suppliers />
      <MealPlan
        selectMeal={(meal) => setMeal(meal)}
        openModal={() => setOpenModal(true)}
        programFile={program.mealPlan}
      />
      <MealModal
        open={openModal}
        meal={meal}
        handleClose={() => setOpenModal(false)}
      />
      <Schedule
        programEvents={program.workoutRegime.days}
        programPlan={program.workoutRegime.workoutPlan ?? ""}
      />
      <Supplements
        supplements={
          program.supplements?.map((s) => {
            return {
              id: s.id ?? 0,
              name: s.name ?? "",
              cost: s.cost ?? 0,
              benefits: s.benefits ?? "",
              dosage: s.dosage ?? "",
              link: s.link ?? "",
              linkText: s.linkText ?? "",
              image: s.image ?? "",
            };
          }) ?? []
        }
      />
      <TestingProtocols
        testingProtocols={
          program.testingProtocols?.map((t) => {
            return {
              id: t.id ?? 0,
              name: t.name ?? "",
              cost: t.cost ?? 0,
            };
          }) ?? []
        }
      />
    </Box>
  );
};

export default MainOverview;
