import { useInfiniteQuery } from "@tanstack/react-query";
import { useAuthToken } from "../../auth/authToken";
import { useUsersMeQuery } from "../users/usersMeQuery";
import { ProgramsType } from "../../types/programs/ProgramsType";
import { useResponseParser } from "../jsonResponseParser";

interface UseProgramsQueryProps {
  name?: string;
  removePagination?: boolean;
}

export interface ProgramsData {
  data: ProgramsType;
  pagination: {
    pageCount: number;
    total: number;
  };
}

export function useProgramQuery(props: UseProgramsQueryProps) {
  const { tokenHeaders, hasToken } = useAuthToken();
  const { data: me } = useUsersMeQuery();
  const { parseJsonResponse } = useResponseParser();

  const filters = [props?.name ? `name=${props?.name}` : null]
    .filter(Boolean)
    .join("&");

  return useInfiniteQuery({
    queryKey: ["api/healthProgram", props?.name],
    queryFn: ({ pageParam }) => {
      const queryParameters = `page=${pageParam}&pageSize=3`;

      const endpoint = `${process.env.REACT_APP_CORE_SERVICE_URL}${
        props.removePagination
          ? "/api/health/program"
          : `/api/health/program?${queryParameters}${
              filters ? `&${filters}` : ""
            }`
      }`;

      return fetch(endpoint, {
        headers: tokenHeaders,
      }).then(parseJsonResponse<ProgramsData>);
    },
    enabled: !!hasToken && me?.id !== undefined,
    initialPageParam: 1,
    getNextPageParam: (lastPage, __, lastPageParam) =>
      lastPageParam < lastPage.pagination.pageCount ? lastPageParam + 1 : null,
  });
}
