import axios from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuthToken } from "../../auth/authToken";
import { ContactData } from "../../types/account-details";
import { useSnackbar } from "../../components/snackbar/Snackbar";

export const useEditUserMutation = () => {
  const { tokenHeaders } = useAuthToken();
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: async ({ id, data }: { id: number; data: ContactData }) => {
      await axios.put(
        `${process.env.REACT_APP_MC_URL}/api/users/edit/${id}`,
        data
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [tokenHeaders, "/me"] });
      showSnackbar(
        "success",
        "Your account details are successfully saved.",
        3000
      );
    },
  });
};
