import * as yup from "yup";

export const registrationSchema = yup
  .object()
  .shape({
    firstName: yup
      .string()
      .required("First name is required")
      .matches(/^[a-zA-Z]+$/, "First name can only contain letters"),
    lastName: yup
      .string()
      .required("Last name is required")
      .matches(/^[a-zA-Z]+$/, "Last name can only contain letters"),
    country: yup.object().required("Country is required"),
    zipCode: yup
      .string()
      .matches(/^[0-9\s]+$/, "Invalid zip code value.")
      .required("Zip code is required"),
    email: yup
      .string()
      .required("Email is required")
      .email("Email must be valid"),
    username: yup.string().required("Username is required"),
    phoneNumber: yup
      .string()
      .required("Phone number is required")
      .matches(/^\d+$/, "Invalid phone number"),
    password: yup
      .string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*]{8,}$/,
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and be at least 8 characters long"
      ),
    confirmPassword: yup
      .string()
      .required("Password is required")
      .oneOf([yup.ref("password")], "Your passwords must match"),
    interestSource: yup.string().required("Interest source is required"),
  })
  .required();

export const generalAccountSchema = yup
  .object()
  .shape({
    firstName: yup
      .string()
      .required("First name is required")
      .matches(/^[a-zA-Z]+$/, "First name can only contain letters"),
    lastName: yup
      .string()
      .required("Last name is required")
      .matches(/^[a-zA-Z]+$/, "Last name can only contain letters"),
    email: yup
      .string()
      .required("Email is required")
      .email("Email must be valid"),
    username: yup.string().required("Username is required"),
    phoneNumber: yup
      .string()
      .required("Phone number is required")
      .matches(/^\d+$/, "Invalid phone number"),
    password: yup
      .string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*]{8,}$/,
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and be at least 8 characters long"
      ),
    confirmPassword: yup
      .string()
      .required("Password is required")
      .oneOf([yup.ref("password")], "Your passwords must match"),
  })
  .required();

export const farmerInputFieldsSchema = yup
  .object()
  .shape({
    company: yup.number().required("Company is required"),
    country: yup.string().required("Country is required"),
    propertyName: yup.string().required("Property name is required"),
    mainEnterprise: yup.string().required("Main enterprise is required"),
    zipCode: yup
      .string()
      .matches(/^[0-9\s]+$/, "Invalid zip code value.")
      .required("Zip code is required"),
    areaCode: yup
      .string()
      .matches(/^[0-9\s]+$/, "Invalid area code value.")
      .required("Area code is required"),
    farmSize: yup
      .string()
      .matches(/^[0-9\s]+$/, "Invalid farm size value")
      .required("Farm size is required"),
    interestSource: yup.string().required("Interest source is required"),
  })
  .required();

export const defaultCountry = {
  code: "AU",
  label: "Australia",
  phone: "+61",
};

export const RegisterDataDefaultValues = {
  firstName: "",
  lastName: "",
  country: {
    id: "AU",
    label: "Australia",
  },
  zipCode: "",
  email: "",
  username: "",
  phoneNumber: "",
  password: "",
  confirmPassword: "",
  interestSource: "",
};

export const GeneralAccountDefaultValues = {
  firstName: "",
  lastName: "",
  email: "",
  username: "",
  phoneNumber: "",
  password: "",
  confirmPassword: "",
};

export const SelectionDataDefaultValues = {
  id: 0,
  iconUrl: "",
  backgroundUrl: "",
  title: "",
  text: "",
};

export const FarmerProfileDefaultValues = {
  company: 0,
  country: "Australia",
  propertyName: "",
  mainEnterprise: "",
  zipCode: "",
  areaCode: "",
  farmSize: "",
  interestSource: "",
};
