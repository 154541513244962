import { useMutation } from "@tanstack/react-query";

interface InviteUserData {
  email: string;
  industry: string;
  industryType: string;
  industrySubType: string;
  tenant: string;
}

export function useInviteUserMutation() {
  return useMutation({
    mutationFn: (data: InviteUserData) => {
      return fetch(
        `${process.env.REACT_APP_MC_URL}/api/users/sendinvitationemail`,
        {
          body: JSON.stringify(data),
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
    },
  });
}
