import { useWizard } from "react-use-wizard";
import { Box } from "@mui/material";
import {
  ReusableButton,
  ReusableChipChecklist,
  ReusableRadioButtons,
  ReusableTextField,
} from "../../reusable";
import { useEffect, useState } from "react";
import { SectionHeader } from "../../programs/widgets";
import UTurnLeftIcon from "@mui/icons-material/UTurnLeft";

type Props = {
  onSave: any;
  form: any;
};

const areasOfInterestOptions = [
  "Longevity",
  "Weight Management",
  "Cognitive Function",
  "Cardiovascular Health",
  "Immune Support",
  "Digestive Health",
];
const ProcessFour = ({ onSave, form }: Props) => {
  const [isBackLoading, setIsBackLoading] = useState<boolean>(false);
  const [isContinueLoading, setIsContinueLoading] = useState<boolean>(false);
  const { previousStep, nextStep, activeStep } = useWizard();

  const { control, watch, setValue, handleSubmit } = form;

  const areasOfInterestOther = watch("processFourData.areasOfInterestOther");
  const areasOfInterest = watch("processFourData.areasOfInterest");

  useEffect(() => {
    if (areasOfInterestOther !== "" && areasOfInterestOther) {
      if (Array.isArray(areasOfInterest) && areasOfInterest.length === 0) {
        setValue("processFourData.areasOfInterest", [areasOfInterestOther]);
      }
    } else {
      if (
        Array.isArray(areasOfInterest) &&
        areasOfInterest.length === 1 &&
        !areasOfInterestOptions?.includes(areasOfInterest[0])
      ) {
        setValue("processFourData.areasOfInterest", []);
      }
    }
  }, [areasOfInterestOther, areasOfInterest, setValue]);

  const onSubmit = () => {
    if (isBackLoading || isContinueLoading) return;
    setIsContinueLoading(true);
    onSave().then(() => {
      setIsContinueLoading(false);
      nextStep();
    });
  };
  const handleOnBackClick = () => {
    if (isBackLoading || isContinueLoading) return;
    setIsBackLoading(true);
    onSave().then(() => {
      setIsBackLoading(false);
      previousStep();
    });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [activeStep]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        width: "100%",
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      <SectionHeader title="Health Program Level" subtitle="" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "50%",
          gap: 2,
          pl: "40px",
        }}
      >
        <ReusableRadioButtons
          label="Desired Program Intensity"
          sublabel="What level of health program are you interested in?"
          name="processFourData.programIntensity"
          control={control}
          options={[
            "Basic (General health and wellness)",
            "Intermediate (Focused on specific goals)",
            "Advanced (Intense and targeted with specific diagnostics)",
          ]}
          direction={"column"}
        />
        <Box>
          <ReusableChipChecklist
            name="processFourData.areasOfInterest"
            control={control}
            options={[
              "Longevity",
              "Weight Management",
              "Cognitive Function",
              "Cardiovascular Health",
              "Immune Support",
              "Digestive Health",
            ]}
            label={"Areas of Interest"}
            sublabel="Which areas of health are you most interested in improving?"
          />
          <ReusableTextField
            inputType="text"
            control={control}
            name="processFourData.areasOfInterestOther"
            placeholder="Other..."
          />
        </Box>
        <Box>
          <ReusableRadioButtons
            label="Diagnostics Interest"
            sublabel="Are you interested in undergoing diagnostic tests to personalize your program further?"
            name="processFourData.diagnosticsInterest"
            control={control}
            options={["Yes", "No"]}
            direction={"column"}
          />
          <ReusableChipChecklist
            name="processFourData.diagnosticsInterestTypes"
            control={control}
            options={[
              "Blood Biomarkers",
              "Genetic Testing",
              "Hormonal Analysis",
              "Nutrient Deficiency Testing",
              "Epigenetic Clocks",
            ]}
            sublabel="If yes, what type of diagnostics would you be interested in?"
          />
        </Box>
      </Box>

      <Box
        sx={{
          mt: 1,
          mb: 1,
          display: "flex",
          justifyContent: "center",
          width: "60%",
        }}
      >
        <ReusableButton
          onClickFunction={handleSubmit(handleOnBackClick)}
          buttonText="Go Back"
          color="#2974B1"
          backgroundColor="#E5EAEF"
          isLoading={isBackLoading}
          loaderColor="#1A1A1A"
          afterIcon={
            <UTurnLeftIcon
              sx={{
                rotate: "90deg",
                mt: 0.5,
              }}
            />
          }
        />
        <ReusableButton
          onClickFunction={handleSubmit(onSubmit)}
          buttonText="Save"
          color="#ffff"
          backgroundColor="#2974B1"
          isLoading={isContinueLoading}
        />
      </Box>
    </Box>
  );
};

export default ProcessFour;
