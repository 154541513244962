import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import { Control, Controller } from "react-hook-form";
import { styled } from "@mui/system";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type Props = {
  control: Control<any>;
  name: string;
  label?: string;
  sublabel?: string;
  options: string[];
};

const FilterChecklist = ({
  control,
  name,
  label,
  sublabel,
  options,
}: Props) => {
  const handleChange = (
    value: string[],
    option: string,
    checked: boolean,
    onChange: (value: any) => void
  ) => {
    const newValue = checked
      ? value?.find((v) => !options?.includes(v))
        ? [option]
        : [...value, option]
      : value.filter((val: string) => val !== option);
    onChange(newValue);
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value = [] }, fieldState: { error } }) => (
        <Box>
          <Accordion
            sx={{
              borderRadius: "0px",
              boxShadow: "none",
              border: "none",
            }}
            disableGutters
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{
                    color: "#000000",
                  }}
                />
              }
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{
                fontSize: "13px",
                fontWeight: "600",
                color: "#000000",
                height: "30px",
                minHeight: "0px",
              }}
            >
              {label}
            </AccordionSummary>
            <AccordionDetails>
              <StyledFormGroup>
                {options?.map((option, index) => (
                  <StyledFormControlLabel
                    key={index}
                    label={option}
                    checked={value?.includes(option)}
                    control={
                      <StyledCheckbox
                        disableRipple
                        checked={value?.includes(option)}
                        onChange={(e) =>
                          handleChange(
                            value,
                            option,
                            e.target.checked,
                            onChange
                          )
                        }
                      />
                    }
                  />
                ))}
              </StyledFormGroup>
              {error && (
                <StyledTypographyErrorCaption>
                  {error.message}
                </StyledTypographyErrorCaption>
              )}
            </AccordionDetails>
          </Accordion>
        </Box>
      )}
    />
  );
};

const pallete = {
  blue: "#3992DD",
  border: { default: "#EAECF0", checked: "#FFAD01", uncheked: "#D7D9DC" },
  text: "#000000",
  btnBgColor: {
    default: "#FFFFFF",
    checked: "#FEF7EB",
  },
};

const StyledFormGroup = styled(FormGroup)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 5,
  padding: "0px 10px",
}));

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  width: "100%",
  paddingRight: 10,
  backgroundColor: pallete.btnBgColor.default,
  color: pallete.text,

  "& .MuiFormControlLabel-label": {
    color: pallete.text,
    fontSize: "14px",
    marginLeft: "8px",
    userSelect: "none",
    WebkitUserSelect: "none",
    MozUserSelect: "none",
    msUserSelect: "none",
  },
}));

const StyledCheckbox = styled(Checkbox)(() => ({
  color: pallete.border.uncheked,
  borderRadius: "3px",
  width: "16px",
  height: "16px",
  "&.Mui-checked": {
    color: pallete.blue,
  },
}));

const StyledTypographyErrorCaption = styled(Typography)(() => ({
  fontSize: "12px",
  color: "red",
  marginTop: "4px",
}));

export default FilterChecklist;
