import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import { Control, Controller } from "react-hook-form";
import { styled } from "@mui/system";

type Props = {
  control: Control<any>;
  name: string;
  label?: string;
  sublabel?: string;
  options: string[];
};

const ReusableChipChecklist = ({
  control,
  name,
  label,
  sublabel,
  options,
}: Props) => {
  const handleChange = (
    value: string[],
    option: string,
    checked: boolean,
    onChange: (value: any) => void
  ) => {
    const newValue = checked
      ? value?.find((v) => !options?.includes(v))
        ? [option]
        : [...value, option]
      : value.filter((val: string) => val !== option);
    onChange(newValue);
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value = [] }, fieldState: { error } }) => (
        <Box>
          {label && <StyledTypographyLabel>{label}</StyledTypographyLabel>}
          {sublabel && (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                  lineHeight: "20px",
                  textAlign: "left",
                  marginBottom: "4px",
                  color: "#4F5359",
                }}
              >
                {sublabel}
              </Typography>
              <Typography
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  lineHeight: "20px",
                  textAlign: "left",
                  marginBottom: "4px",
                  color: "#4F5359",
                }}
              >
                (Select all that apply)
              </Typography>
            </Box>
          )}
          <StyledFormGroup>
            {options.map((option, index) => (
              <StyledFormControlLabel
                key={index}
                label={option}
                checked={value?.includes(option)}
                control={
                  <StyledCheckbox
                    disableRipple
                    checked={value?.includes(option)}
                    onChange={(e) =>
                      handleChange(value, option, e.target.checked, onChange)
                    }
                  />
                }
              />
            ))}
          </StyledFormGroup>
          {error && (
            <StyledTypographyErrorCaption>
              {error.message}
            </StyledTypographyErrorCaption>
          )}
        </Box>
      )}
    />
  );
};

const pallete = {
  blue: "#3992DD",
  border: { default: "#EAECF0", checked: "#FFAD01", uncheked: "#D7D9DC" },
  text: "#344054",
  btnBgColor: {
    default: "#FFFFFF",
    checked: "#FEF7EB",
  },
};

const StyledTypographyLabel = styled(Typography)(() => ({
  fontSize: "16px",
  fontWeight: "600",
  color: pallete.text,
  marginBottom: "8px",
}));

const StyledFormGroup = styled(FormGroup)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 2,
}));

const StyledFormControlLabel = styled(FormControlLabel, {
  shouldForwardProp: (prop) => prop !== "checked",
})<{ checked: boolean }>(({ checked }) => ({
  width: "100%",

  paddingRight: 10,
  margin: 2,

  backgroundColor: checked
    ? pallete.btnBgColor.checked
    : pallete.btnBgColor.default,

  border: checked
    ? `2px solid ${pallete.border.checked}`
    : `1px solid ${pallete.border.default}`,
  borderRadius: 12,

  color: checked ? pallete.blue : pallete.text,

  "& .MuiFormControlLabel-label": {
    userSelect: "none",
    WebkitUserSelect: "none",
    MozUserSelect: "none",
    msUserSelect: "none",
  },
}));

const StyledCheckbox = styled(Checkbox)(() => ({
  color: pallete.border.uncheked,
  borderRadius: "3px",
  "&.Mui-checked": {
    color: pallete.blue,
  },
}));

const StyledTypographyErrorCaption = styled(Typography)(() => ({
  fontSize: "12px",
  color: "red",
  marginTop: "4px",
}));

export default ReusableChipChecklist;
