import { useWizard } from "react-use-wizard";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Popover,
  Typography,
} from "@mui/material";
import { ReusableButton, ReusableRadioButtons } from "../../reusable";
import { useEffect, useState } from "react";
import { SectionHeader } from "../../programs/widgets";
import { Controller } from "react-hook-form";
import { format } from "date-fns";
import { StaticDatePicker } from "@mui/x-date-pickers";
import UTurnLeftIcon from "@mui/icons-material/UTurnLeft";

type Props = {
  onSave: any;
  form: any;
};

const ProcessSix = ({ onSave, form }: Props) => {
  const [isBackLoading, setIsBackLoading] = useState<boolean>(false);
  const [isContinueLoading, setIsContinueLoading] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { previousStep, activeStep } = useWizard();

  const { control, handleSubmit } = form;

  const onSubmit = () => {
    if (isBackLoading || isContinueLoading) return;
    setIsContinueLoading(true);
    onSave().then(() => {
      setIsContinueLoading(false);
    });
  };
  const handleOnBackClick = () => {
    if (isBackLoading || isContinueLoading) return;
    setIsBackLoading(true);
    onSave().then(() => {
      setIsBackLoading(false);
      previousStep();
    });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [activeStep]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        width: "100%",
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      <SectionHeader title="Final Details" subtitle="" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "50%",
          gap: 2,
          pl: "40px",
        }}
      >
        <ReusableRadioButtons
          label="Preferred Communication Method"
          sublabel="How would you like to receive updates and communication?"
          name="processSixData.preferredCommunicationMethod"
          control={control}
          options={["Email", "SMS"]}
          direction={"column"}
        />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              textAlign: "left",
              marginBottom: "8px",
            }}
          >
            Program Start Date
          </Typography>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "600",
              lineHeight: "20px",
              textAlign: "left",
              marginBottom: "8px",
              color: "#4F5359",
            }}
          >
            When would you like to start your personalized health program?
          </Typography>
          <Box
            onClick={(event) => setAnchorEl(event.currentTarget)}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
              border: "1px solid lightgrey",
              borderRadius: "25px",
              px: 8,
              py: 1,
              cursor: "pointer",
              width: "fit-content",
            }}
          >
            <img src="/assets/calendar-Icon.svg" alt="calendar" />
            <Controller
              name="processSixData.programStartDate"
              control={control}
              render={({ field: { value } }) => (
                <Typography fontSize={16} fontWeight={600}>
                  {value
                    ? format(new Date(value), "MMM dd, yyyy")
                    : "Select Date"}
                </Typography>
              )}
            />
          </Box>
        </Box>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Controller
            name="processSixData.programStartDate"
            control={control}
            render={({ field: { onChange, value } }) => (
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                openTo="day"
                value={value ? new Date(value) : new Date()}
                defaultValue={new Date()}
                onChange={(date) => {
                  onChange(date?.toString());
                  setAnchorEl(null);
                }}
                views={["year", "month", "day"]}
                sx={{
                  "& .MuiPickersDay-root": {
                    "&.Mui-selected": {
                      backgroundColor: "#217A25",
                      "&:hover": {
                        backgroundColor: "#195B1B",
                      },
                    },
                  },
                }}
              />
            )}
          />
        </Popover>
        <Box>
          <Controller
            control={control}
            name="processSixData.consent"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    textAlign: "left",
                    marginBottom: "8px",
                  }}
                >
                  Consent
                </Typography>
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "20px",
                    textAlign: "left",
                    marginBottom: "8px",
                    color: "#4F5359",
                  }}
                >
                  I agree to the terms and conditions, and I consent to the use
                  of my data as outlined.
                </Typography>
                <FormControlLabel
                  label="I agree."
                  checked={value === "Yes" ? true : false}
                  sx={{
                    color: value === "Yes" ? "#3992DD" : "#344054",
                  }}
                  control={
                    <Checkbox
                      disableRipple
                      checked={value === "Yes" ? true : false}
                      sx={{
                        color: "#D7D9DC",
                        borderRadius: "3px",
                        "&.Mui-checked": {
                          color: "#3992DD",
                        },
                      }}
                      onChange={(e) => {
                        if (e.target.checked) {
                          onChange("Yes");
                        } else {
                          onChange("");
                        }
                      }}
                    />
                  }
                />
                {error && (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: "red",
                      marginTop: "4px",
                    }}
                  >
                    {error.message}
                  </Typography>
                )}
              </Box>
            )}
          />
        </Box>
      </Box>
      <Box
        sx={{
          mt: 1,
          mb: 1,
          display: "flex",
          justifyContent: "center",
          width: "60%",
        }}
      >
        <ReusableButton
          onClickFunction={handleSubmit(handleOnBackClick)}
          buttonText="Go Back"
          color="#2974B1"
          backgroundColor="#E5EAEF"
          isLoading={isBackLoading}
          loaderColor="#1A1A1A"
          afterIcon={
            <UTurnLeftIcon
              sx={{
                rotate: "90deg",
                mt: 0.5,
              }}
            />
          }
        />
        <ReusableButton
          onClickFunction={handleSubmit(onSubmit)}
          buttonText="Save"
          color="#ffff"
          backgroundColor="#2974B1"
          isLoading={isContinueLoading}
        />
      </Box>
    </Box>
  );
};

export default ProcessSix;
