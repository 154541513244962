export enum ProgramOverviewTabs {
  Overview = 0,
  FinancialOverview,
}

export const tabLabels = {
  [ProgramOverviewTabs.Overview]: "Overview",
  [ProgramOverviewTabs.FinancialOverview]: "Financial Overview",
};

export const accuData = [
  { year: 2025, upfront: 150, instalment: 0 },
  { year: 2026, upfront: 0, instalment: 50 },
  { year: 2027, upfront: 100, instalment: 0 },
  { year: 2028, upfront: 0, instalment: 100 },
  { year: 2029, upfront: 100, instalment: 0 },
  { year: 2030, upfront: 100, instalment: 0 },
  { year: 2031, upfront: 150, instalment: 0 },
  { year: 2032, upfront: 200, instalment: 0 },
  { year: 2033, upfront: 0, instalment: 0 },
  { year: 2034, upfront: 0, instalment: 0 },
  { year: 2035, upfront: 0, instalment: 0 },
  { year: 2036, upfront: 0, instalment: 0 },
  { year: 2037, upfront: 50, instalment: 0 },
  { year: 2038, upfront: 0, instalment: 50 },
  { year: 2039, upfront: 0, instalment: 0 },
  { year: 2040, upfront: 0, instalment: 0 },
  { year: 2041, upfront: 250, instalment: 0 },
  { year: 2042, upfront: 0, instalment: 0 },
  { year: 2043, upfront: 150, instalment: 0 },
  { year: 2044, upfront: 0, instalment: 0 },
  { year: 2045, upfront: 0, instalment: 50 },
  { year: 2046, upfront: 0, instalment: 0 },
  { year: 2047, upfront: 0, instalment: 0 },
  { year: 2048, upfront: 100, instalment: 0 },
  { year: 2049, upfront: 0, instalment: 50 },
];

export const firstPieChartData = [
  { name: "Gross Profit", value: 60, fill: "#F3AC38" },
  { name: "Cost of Sales", value: 25, fill: "#6A4D39" },
  { name: "Cost of carbon application", value: 5, fill: "#82624C" },
];

export const secondPieChartData = [
  { name: "Farmer (Operator)", value: 80, fill: "#217A25" },
  { name: "Tech (Operating Entity)", value: 15, fill: "#6A4D39" },
  { name: "Aggregator", value: 5, fill: "#F3AC38" },
];
