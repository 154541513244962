import { infinity } from "ldrs";
infinity.register();

type Props = {
  color?: string;
  size?: string;
  speed?: string;
  stroke?: string;
  strokeLength?: string;
  bgOpacity?: string;
};

const InfinityLoader = ({
  color = "#FF9B00",
  size = "150",
  speed = "1.3",
  stroke = "8",
  strokeLength = "0.15",
  bgOpacity = "0.1",
}: Props) => {
  return (
    <l-infinity
      size={size}
      stroke={stroke}
      stroke-length={strokeLength}
      bg-opacity={bgOpacity}
      speed={speed}
      color={color}
    ></l-infinity>
  );
};

export default InfinityLoader;
