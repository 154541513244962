import * as yup from "yup";

export const searchInputSchema = yup
  .object()
  .shape({
    name: yup.string().max(50, "Search input cannot exceed 50 characters"),
  })
  .required();

export const programDefaultValues = {
  programName: "",
  difficulty: "",
  category: "",
  objectives: "",
  summary: "",
  mealPlan: null,
  supplements: [],
  testingProtocols: [],
  workoutRegime: {
    days: [],
    workoutPlan: "",
  },
};
export const programSchema = yup.object().shape({
  programName: yup.string().max(50, "This field must not exceed 50 characters"),
  difficulty: yup.string(),
  category: yup.string(),
  objectives: yup
    .string()
    .max(255, "This field must not exceed 255 characters"),
  summary: yup.string().max(255, "This field must not exceed 255 characters"),
  mealPlan: yup.mixed(), // FILE .pdf
  supplements: yup.array().of(
    yup.object().shape({
      id: yup.number(),
      name: yup.string(),
      benefits: yup.string(),
      cost: yup.number(),
      usage: yup.string().max(255, "This field must not exceed 255 characters"),
      dosage: yup.string(),
      link: yup.string(),
      linkText: yup.string(),
      image: yup.string(),
    })
  ),
  testingProtocols: yup.array().of(
    yup.object().shape({
      id: yup.number(),
      name: yup.string().max(50, "This field must not exceed 50 characters"),
      cost: yup.number(),
      note: yup.string().max(255, "This field must not exceed 255 characters"),
    })
  ),
  workoutRegime: yup.object().shape({
    days: yup.array().of(yup.object()),
    workoutPlan: yup
      .string()
      .max(255, "This field must not exceed 255 characters"),
  }),
});

export type ProgramData = yup.InferType<typeof programSchema>;
