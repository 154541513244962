import { Box } from "@mui/material";
import { useParams } from "react-router";
import { ProgramOverviewHeader } from "../../components/programs";
import ProgramOverviewBody from "../../components/programs/programOverview/ProgramOverviewBody";
import { useEffect } from "react";
import { useBackdrop } from "../../components/reusable/LoadingSpinners/infinityBackdrop";
import { useProgramByIdQuery } from "../../api/programs/programByIdQuery";

const ProgramOverview = () => {
  let { id } = useParams();
  const { data: program, isLoading } = useProgramByIdQuery({ id });
  const { toggleBackdrop } = useBackdrop();

  useEffect(() => {
    if (isLoading) {
      toggleBackdrop(true);
      return;
    }

    toggleBackdrop(false);
  }, [isLoading, toggleBackdrop]);

  if (!program) return <></>;
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Box
        sx={{ display: "flex", flexDirection: "column", width: "80%", p: 3 }}
      >
        <ProgramOverviewHeader program={program} />
        <ProgramOverviewBody program={program} />
      </Box>
    </Box>
  );
};

export default ProgramOverview;
