import { Box } from "@mui/material";

import ProgramOverviewWidgetHolder from "../widgets/ProgramOverviewWidgetHolder";
import ProgramOverviewPieChart from "../widgets/ProgramOverviewPieChart";
import { ProgramData } from "../../../../../schemas/programs/programs";
import { generateChartData } from "../../../../../utils/program";

type Props = {
  program: ProgramData;
};

const TotalExpenses = ({ program }: Props) => {
  const supplementsPieChartData = generateChartData(program?.supplements);

  const testingProtocolsPieChartData = generateChartData(
    program?.testingProtocols
  );

  return (
    <ProgramOverviewWidgetHolder title="Total Expenses per month">
      <Box sx={{ display: "flex", gap: 3, width: "100%" }}>
        <ProgramOverviewPieChart
          title="Total Supplement Cost"
          data={supplementsPieChartData}
          legendTitle="Total Cost"
        />
        <ProgramOverviewPieChart
          title="Total Testing Protocols Cost"
          data={testingProtocolsPieChartData}
          legendTitle="Total Cost"
        />
      </Box>
    </ProgramOverviewWidgetHolder>
  );
};

export default TotalExpenses;
