import { Button } from "@mui/material";

const ViewDataButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        width: "145px",
        height: "42px",
        padding: "10px 16px",
        borderRadius: "20px",
        backgroundColor: "rgba(255, 255, 255, 1)",
        color: "rgba(41, 116, 177, 1)",
        fontFamily: "Font/--secondary-font-family",
        fontSize: "16px",
        fontWeight: 600,
        textAlign: "center",
        lineHeight: "22.4px",
        textTransform: "none",
        "&:hover": {
          opacity: 1,
          color: "rgba(20, 87, 144, 1)",
          backgroundColor: "rgba(199, 215, 225, 1)",
        },
      }}
    >
      View Data
    </Button>
  );
};

export default ViewDataButton;
