import * as React from "react";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import Box from "@mui/material/Box";
import { Control, Controller } from "react-hook-form";

const PrettoSlider = styled(Slider)({
  color: "#3992DD",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 17,
    width: 17,
    backgroundColor: "#fff",
    border: "2px solid #2974B1",
    boxShadow: "0 0 0 4px #F4AF00",
    outline: "2px solid #68442A",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&::before": {
      display: "none",
    },
  },
  " .MuiSlider-rail": {
    backgroundColor: "#E4E7EC",
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#3992DD",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&::before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

type Props = {
  control: Control<any>;
  name: string;
};

const PriceSlider = ({ control, name }: Props) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value = [] }, fieldState: { error } }) => (
        <Box sx={{ width: "100%", paddingX: "15px", mt: 1, mb: 1 }}>
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "#000000",
            }}
          >
            Price
          </Typography>
          <PrettoSlider
            valueLabelDisplay="auto"
            defaultValue={20}
            max={1000}
            onChangeCommitted={(e: any) => {
              onChange(Number(e.target.outerText));
            }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: -1,
            }}
          >
            <Typography
              sx={{
                fontSize: "10px",
                fontWeight: "400",
                color: "#ABADC6",
              }}
            >
              min
            </Typography>
            <Typography
              sx={{
                fontSize: "10px",
                fontWeight: "400",
                color: "#ABADC6",
              }}
            >
              max
            </Typography>
          </Box>
        </Box>
      )}
    />
  );
};

export default PriceSlider;
