import OnboardingMainPage from "../../components/profile/userOnboarding/OnboardingMainPage";
import {
  userOBP1DefaultValues,
  userOBP2DefaultValues,
  userOBP3DefaultValues,
  userOBP4DefaultValues,
  userOBP5DefaultValues,
  userOBP6DefaultValues,
} from "../../schemas/user/onboarding";
import { useUserOnboardingQuery } from "../../api/onboarding/userOnboardingQuery";

const UserOnboarding = () => {
  const { data: user } = useUserOnboardingQuery();

  const defaultValues = {
    processOneData: user?.demographics ?? userOBP1DefaultValues,
    processTwoData: user?.dietaryPreferences ?? userOBP2DefaultValues,
    processThreeData: user?.exerciseProgram ?? userOBP3DefaultValues,
    processFourData: user?.healthProgramLevel ?? userOBP4DefaultValues,
    processFiveData: user?.engagementSocialPreferences ?? userOBP5DefaultValues,
    processSixData: user?.finalDetails ?? userOBP6DefaultValues,
  };

  return <>{user && <OnboardingMainPage defaultValues={defaultValues} />}</>;
};

export default UserOnboarding;
