import { Box, Popover, Typography } from "@mui/material";
import { Control, Controller, UseFormWatch } from "react-hook-form";
import { SectionHeader } from "../widgets";
import { useState } from "react";
import ReusableTextEditor from "../../reusable/Form/ReusableTextEditor";
import ReusableMultiDaysPicker from "../../reusable/Form/ReusableMultiDaysPicker";

const WorkoutRegime = ({
  control,
  watch,
}: {
  control: Control<any>;
  watch: UseFormWatch<any>;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const workoutDays = watch("workoutRegime.days");
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: 3,
        mt: 9,
      }}
    >
      <SectionHeader title="Workout Regime" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          px: "40px",
          gap: 3,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Typography fontSize={14} fontWeight={600} color="#4F5359">
            Choose the days for workout
          </Typography>
          <Box
            onClick={(event) => setAnchorEl(event.currentTarget)}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1.5,
              border: "1px solid lightgrey",
              borderRadius: "25px",
              px: 4,
              py: 1,
              cursor: "pointer",
              width: "fit-content",
            }}
          >
            <img src="/assets/calendar-Icon.svg" alt="calendar" />
            <Controller
              name="workoutRegime.days"
              control={control}
              render={({ field: { value } }) => {
                return (
                  <Typography fontSize={16} fontWeight={600}>
                    {value
                      ? Array.isArray(value) && value?.length > 0
                        ? `${value?.length} ${
                            value?.length === 1 ? "Day" : "Days"
                          } Selected`
                        : "Select Days"
                      : "Select Days"}
                  </Typography>
                );
              }}
            />
          </Box>
        </Box>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <ReusableMultiDaysPicker
            name="workoutRegime.days"
            control={control}
            defaultValue={workoutDays}
            onCancel={() => setAnchorEl(null)}
          />
        </Popover>
        <Box sx={{ mb: 6 }}>
          <ReusableTextEditor
            control={control}
            name="workoutRegime.workoutPlan"
            label="Workout Plan"
            placeholder="Enter your Workout Plan..."
          />
        </Box>
      </Box>
    </Box>
  );
};

export default WorkoutRegime;
