import { useMutation, useQueryClient } from "@tanstack/react-query";
import { coreServiceAxios } from "../../../config/axios-instance";
import { useSnackbar } from "../../../components/snackbar/Snackbar";

export const useCreateWorkoutMutation = () => {
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: Workout) => {
      const result = await coreServiceAxios.post(`api/health/workout`, data);

      return result.data;
    },
    onSuccess: () => {
      showSnackbar("success", "Workout created successfully.");
      queryClient.invalidateQueries({
        queryKey: ["workouts"],
      });
    },
    onError: () => {
      showSnackbar("error", "Failed to create workout.");
    },
  });
};
