import { createTheme } from "@mui/material/styles";

export const registerPageCustomTheme = createTheme({
  palette: {
    primary: {
      main: "#1E6F30",
    },
    secondary: {
      main: "#FFAD01",
    },
  },
  typography: {
    fontFamily: [
      "Open Sans",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

export const defaultStepperObjects = [
  { key: "generalAccount", label: "General Account" },
  { key: "profileSelection", label: "Profile Selection" },
  { key: "profileInformation", label: "Profile Information" },
];

export const profileSelectionOptions = [
  {
    id: 1,
    iconUrl: "/registration/farmer-profile-icon.png",
    backgroundUrl: "/registration/farmer-profile-widget.png",
    title: "Farmer",
    text: "As a farmer with Fresh Earth, you're at the heart of change. Your role goes beyond just farming. You nurture your land and create healthier food, guided by our agronomists and supported by our technology.  <br /> You see the fruits of your labor in real-time data, tracking the changes you're making. Your high-quality produce is valued, connecting you directly with those who care about sustainably sourced food. <br /> Your work matters, shaping a healthier future for our planet.",
  },
  {
    id: 2,
    iconUrl: "/registration/agronomist-profile-icon.png",
    backgroundUrl: "/registration/agronomist-profile-widget.png",
    title: "Agronomist",
    text: "As an agronomist with Fresh Earth, your expertise is the compass guiding the transformation of farms. <br /> You work closely with farmers, helping them create a digital 'twin' of their land and establishing a personalized regenerative farming plan. You play a pivotal role in monitoring the progression and measuring the environmental contributions, reflecting the tangible impact farmers are making. <br /> Your knowledge and guidance are integral to advancing sustainable agriculture and creating a healthier planet.",
  },
  {
    id: 3,
    iconUrl: "/registration/manufacturer-profile-icon.png",
    backgroundUrl: "/registration/manufacturer-profile-widget.png",
    title: "Manufacturer",
    text: "As a manufacturer within Fresh Earth Exchange, your innovation plays a pivotal role in sustainable agriculture. You're vital in crafting raw materials into farming solutions, thus empowering regenerative farming practices. <br /> By creating high-quality, certified products, you help farmers transition to sustainability.",
  },
  {
    id: 4,
    iconUrl: "/registration/producer-profile-icon.png",
    backgroundUrl: "/registration/producer-profile-widget.png",
    title: "Producer            ",
    text: "As a producer within Fresh Earth Exchange, you are at the heart of sustainable agriculture. <br /> You produce the raw materials, the building blocks, that breathe life into regenerative farming practices. Your inputs set the course for healthier soils and stronger crops. You're a vital component in our shared mission, your everyday efforts significantly impact the health of our planet. <br />      You're not just a producer, but a guardian of our earth's resources, pioneering the shift towards a more sustainable and regenerative future.",
  },
  {
    id: 5,
    iconUrl: "/registration/impactinvestor-profile-icon.svg",
    backgroundUrl: "/registration/impactinvestor-profile-widget.png",
    title: "Impact Investor",
    text: "As an impact investor with Fresh Earth Exchange, your investments fuel the engine of change. <br /> Your contributions don't just create financial returns; they also cultivate a positive environmental and social impact. Your resources give farmers and innovators the support they need to transition to regenerative practices. <br /> You're not merely an investor; you're an agent of change, investing in a healthier planet and a sustainable future for all.",
  },
];

export const farmingTypes = [
  "Grain Farming",
  "Livestock Farming",
  "Oilseed Farming",
  "Vegetable Farming",
  "Fruit Farming",
  "Nut Farming",
  "Legume Farming",
  "Fiber Farming",
  "Sugar Farming",
  "Tobacco Farming",
  "Tea/Coffee Farming",
  "Spice Farming",
  "Medicinal and Aromatic Crop Farming",
  "Greenhouse, Nursery and Floriculture Farming",
];

export const companies: any = [
  { id: 1, label: "Company 1" },
  { id: 2, label: "Company 2" },
  { id: 3, label: "Company 3" },
  { id: 4, label: "Company 4" },
  { id: 5, label: "Company 5" },
  { id: 6, label: "Company 6" },
];

export const interestSourceOptions = [
  "Social media",
  "Forums",
  "Word of mouth",
];
