import React from "react";
import { Box, Typography } from "@mui/material";
import HeaderText from "./HeaderText";

const HeaderImageWrapper: React.FC<{
  backgroundImage: string;
  text: string;
}> = ({ backgroundImage, text }) => {
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "745px",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center bottom",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "0 0 24px 24px",
        overflow: "hidden",
        boxSizing: 'border-box',
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          gap: "8px",
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeaderText text={text} />
        <Typography
          sx={{
            fontFamily: "Comfortaa",
            fontSize: "48px",
            fontWeight: 700,
            lineHeight: "57.6px",
            letterSpacing: "-0.01em",
            color: "rgba(255, 155, 0, 1)",
            display: "flex",
            alignItems: "center",
            marginLeft: "10px",
          }}
        >
          Health
          <Box
            component="img"
            src="/assets/x-icon.svg"
            sx={{
              width: "33px",
              height: "39px", 
              marginLeft: "10px",
              marginRight: "1px",
              marginBottom: "5px",
              display: "inline-block",
              verticalAlign: "middle",
            }}
          />
          change
        </Typography>
      </Box>
    </Box>
  );
};

export default HeaderImageWrapper;
