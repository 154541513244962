import { Box } from "@mui/material";
import { ProgramCardBasicInfo } from "../widgets";
import { ProgramData } from "../../../schemas/programs/programs";

type Props = {
  programObject: ProgramData;
  handleOnClick?: () => void;
};

export default function ProgramCard({
  programObject,
  handleOnClick = () => {},
}: Props) {
  return (
    <Box
      onClick={handleOnClick}
      sx={{
        width: "100%",
        height: "150px",
        borderRadius: "30px",
        backgroundColor: "#FFFFFF",
        boxShadow: "none",
        display: "flex",
        flexDirection: "row",
        gap: 3,
        paddingRight: 2,
        marginBottom: "16px",
        boxSizing: "border-box",
        "&:hover": {
          cursor: "pointer",
        },
      }}
    >
      <Box
        sx={{
          width: "30%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <img
          alt="program"
          src={"/assets/science-page-obesity-section.jpg"}
          style={{
            height: "190px",
            width: "100%",
            borderRadius: "15px",
          }}
        />
      </Box>
      <ProgramCardBasicInfo programObject={programObject} />
    </Box>
  );
}
