import { Box, Typography } from "@mui/material";

const SubHeaderSection: React.FC<{ text: string }> = ({ text }) => (
  <Box
    sx={{
      width: "100%",
      maxWidth: "1440px",
      margin: "0 auto",
      padding: "24px 16px",
      textAlign: "center",
    }}
  >
    <Typography
      sx={{
        fontFamily: "Comfortaa",
        fontSize: "24px",
        fontWeight: 400,
        lineHeight: "52.8px",
        letterSpacing: "-0.01em",
        color: "#3992DD",
      }}
    >
      {text}
    </Typography>
  </Box>
);

export default SubHeaderSection;