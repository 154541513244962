import { Box, MenuItem, Select, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";

type Props = {
  title: string;
  valueOne?: {
    title: string;
    value: number;
  };
  valueTwo?: {
    title: string;
    value: number;
  };
  total?: {
    title: string;
    value: number;
  };
  showSelect?: boolean;
  selectOptions?: Array<string>;
  selectedOption?: string;
  setSelectedOption?: Dispatch<SetStateAction<string>>;
  image?: string;
  imageStyle?: React.CSSProperties;
  children?: any;
  showBadge?: boolean;
};

const ProgramOverviewInfoCard = ({
  title,
  valueOne,
  valueTwo,
  total,
  showSelect = false,
  selectOptions = [],
  selectedOption,
  setSelectedOption,
  image,
  imageStyle,
  children,
  showBadge,
}: Props) => {
  return (
    <Box
      sx={{
        borderRadius: 3,
        border: "1px solid #E4E7EC",
        bgcolor: "white",
        width: "100%",
      }}
    >
      <Box
        sx={{
          height: 82,
          flexGrow: 1,
          overflow: "hidden",
          position: "relative",
          borderBottom: "1px solid #2974B1",
          borderTopLeftRadius: 11,
          borderTopRightRadius: 11,
        }}
      >
        <img
          src={image ?? "/assets/header-image.jpg"}
          alt="placeholder"
          style={
            imageStyle ?? {
              width: "100%",
              height: "100%",
              objectFit: "cover",
              transform: "scale(2) translate(-120px, 8px)",
              opacity: 0.3,
            }
          }
        />
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent:
              showSelect || showBadge ? "space-between" : "flex-start",
            alignItems: "center",
            gap: 3,
            pl: 4,
          }}
        >
          <Typography
            fontSize={24}
            fontWeight={600}
            color="#2974B1"
            fontFamily="Comfortaa, sans-serif"
          >
            {title}
          </Typography>
          {showSelect && setSelectedOption && (
            <Select
              value={selectedOption || ""}
              onChange={(e) => {
                const selectedValue = e.target.value;
                if (selectedValue === "__clear__") {
                  setSelectedOption("");
                } else {
                  setSelectedOption(selectedValue);
                }
              }}
              sx={{
                borderRadius: "20px",
                height: "35px",
                minWidth: "160px",
                backgroundColor: "white",
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#2974B1",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#2974B1",
                },
              }}
              displayEmpty
              renderValue={(selected) =>
                selected ? (
                  <Typography textAlign="center">{selected}</Typography>
                ) : (
                  <Typography color="grey" textAlign="center">
                    Select
                  </Typography>
                )
              }
            >
              <MenuItem value="__clear__">
                <Typography fontSize={16} fontWeight={400} color="grey">
                  Select option
                </Typography>
              </MenuItem>
              {selectOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          )}
          {showBadge && (
            <Box
              sx={{
                py: 0.5,
                px: 1.5,
                height: "max-content",
                borderRadius: 5,
                border: "1px solid #2974B1",
                backgroundColor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mr: "24px",
              }}
            >
              <img
                src="/assets/heart-icon.svg"
                alt="Heart icon"
                style={{
                  width: "16px",
                  height: "16px",
                  marginTop: "1px",
                  marginRight: "6px",
                }}
              />
              <Typography
                fontSize={14}
                fontWeight={600}
                color="#2974B1"
                sx={{
                  opacity: "100%",
                }}
              >
                Begginer
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      {children ?? (
        <Box sx={{ display: "flex", p: 2.5, gap: 3 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              borderRight: "2px solid #2974B1",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            {total && (
              <Box>
                <Typography
                  fontSize={16}
                  fontWeight={600}
                  color="#394150"
                  fontFamily="Comfortaa, sans-serif"
                >
                  {total?.title}
                </Typography>
                <Typography
                  fontSize={32}
                  fontWeight={600}
                  color="#6A4D39"
                  fontFamily="Comfortaa, sans-serif"
                >
                  {`${total.value >= 0 ? "$" : ""}${total.value
                    .toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${
                    total.value < 0 ? "$" : ""
                  }`}
                </Typography>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box>
              {valueOne && (
                <>
                  <Typography
                    fontSize={16}
                    fontWeight={600}
                    color="#394150"
                    fontFamily="Comfortaa, sans-serif"
                  >
                    {valueOne?.title}
                  </Typography>
                  <Typography
                    fontSize={24}
                    fontWeight={600}
                    color="#6A4D39"
                    fontFamily="Comfortaa, sans-serif"
                  >
                    {`${valueOne.value >= 0 ? "$" : ""}${valueOne.value
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${
                      valueOne.value < 0 ? "$" : ""
                    }`}
                  </Typography>
                </>
              )}
              {valueTwo && (
                <>
                  <Typography
                    fontSize={16}
                    fontWeight={600}
                    color="#394150"
                    fontFamily="Comfortaa, sans-serif"
                  >
                    {valueTwo?.title}
                  </Typography>
                  <Typography
                    fontSize={24}
                    fontWeight={600}
                    color="#6A4D39"
                    fontFamily="Comfortaa, sans-serif"
                  >
                    {`${valueTwo.value >= 0 ? "$" : ""}${valueTwo.value
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${
                      valueTwo.value < 0 ? "$" : ""
                    }`}
                  </Typography>
                </>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default ProgramOverviewInfoCard;
