import { Button } from "@mui/material";

const ReadMoreButton: React.FC<{
  onClick: () => void;
  isExpanded: boolean;
  buttonText: string;
  buttonColor?: string;
  hoverColor?: string;
  hoverTextColor?: string;
}> = ({
  onClick,
  isExpanded,
  buttonText,
  buttonColor = "rgba(255, 155, 0, 1)",
  hoverColor = "rgba(244, 175, 0, 1)",
  hoverTextColor = "rgba(13, 70, 119, 1)",
}) => (
  <Button
    onClick={onClick}
    sx={{
      width: "145px",
      height: "42px",
      padding: "10px 16px",
      borderRadius: "20px",
      backgroundColor: buttonColor,
      color: "rgba(255, 255, 255, 1)",
      fontFamily: "Open Sans",
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22.4px",
      textAlign: "center",
      textTransform: "none",
      marginTop: "16px",
      "&:hover": {
        backgroundColor: hoverColor,
        color: hoverTextColor,
      },
    }}
  >
    {isExpanded ? "Read Less" : buttonText}
  </Button>
);

export default ReadMoreButton;
