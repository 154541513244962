import React, { Dispatch, SetStateAction, useState } from "react";
import {
  Box,
  Autocomplete,
  TextField,
  Modal,
  Typography,
  Divider,
} from "@mui/material";
import { CountryType, countries } from "../../constants/countries";

type Props = {
  chosenCountry: CountryType;
  setChosenCountry: Dispatch<SetStateAction<CountryType>>;
};

const CountryDialCodeSelect = ({ chosenCountry, setChosenCountry }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: any | null) => {
    setChosenCountry(newValue);
  };

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Typography
          onClick={() => setIsOpen(true)}
          sx={{
            cursor: "pointer",
          }}
        >
          {chosenCountry.code}
        </Typography>
        <Divider
          orientation="vertical"
          variant="middle"
          sx={{ height: "24px", margin: "0 6px" }}
        />
        <Typography sx={{ color: "#000" }}>{chosenCountry.phone}</Typography>
      </Box>
      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            padding: 5,
            paddingTop: 2,
          }}
        >
          <Typography
            sx={{ marginBottom: "10px", textAlign: "center", fontSize: "22px" }}
          >
            Chose your country:
          </Typography>
          <Autocomplete
            value={chosenCountry}
            onChange={handleChange}
            sx={{}}
            options={countries}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            autoHighlight
            getOptionLabel={(option) => option?.label}
            disableClearable={true}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <img
                  loading="lazy"
                  width="20"
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  alt=""
                  onError={(e) => {
                    const imgElement = e.target as HTMLImageElement;
                    imgElement.style.display = "none";
                  }}
                />
                {option.label} ({option.code}) {option.phone}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
          />
        </Box>
      </Modal>
    </>
  );
};

export default CountryDialCodeSelect;
