import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuthToken } from "../../auth/authToken";
import { useSnackbar } from "../../components/snackbar/Snackbar";
import { ProgramData } from "../../schemas/programs/programs";
import { useNavigate } from "react-router";

export function useCreateProgramMutation() {
  const { tokenHeaders } = useAuthToken();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: async (data: ProgramData) => {
      const formData = new FormData();
      formData.append("name", data.programName ?? "");
      formData.append("difficulty", data.difficulty ?? "");
      data.supplements
        ?.filter((s) => s.id)
        ?.forEach((supplement, index) => {
          formData.append(
            `supplements[${index}][id]`,
            supplement.id?.toString() ?? ""
          );
          formData.append(`supplements[${index}][name]`, supplement.name ?? "");
          formData.append(
            `supplements[${index}][usage]`,
            supplement.usage ?? ""
          );
          formData.append(
            `supplements[${index}][cost]`,
            supplement.cost?.toString() ?? ""
          );
          formData.append(
            `supplements[${index}][benefits]`,
            supplement.benefits ?? ""
          );
        });
      data.testingProtocols
        ?.filter((tp) => tp.id)
        .forEach((protocol, index) => {
          formData.append(
            `testingProtocols[${index}][id]`,
            protocol.id?.toString() ?? ""
          );
          formData.append(
            `testingProtocols[${index}][name]`,
            protocol.name ?? ""
          );
          formData.append(
            `testingProtocols[${index}][cost]`,
            protocol.cost?.toString() ?? ""
          );
          formData.append(
            `testingProtocols[${index}][note]`,
            protocol.note ?? ""
          );
        });
      formData.append("mealPlan", data.mealPlan);
      formData.append("category", data.category ?? "");
      formData.append("objectives", data.objectives ?? "");
      formData.append("summary", data.summary ?? "");

      formData.append("workoutPlan", data.workoutRegime.workoutPlan ?? "");
      data.workoutRegime.days?.forEach((day, index) => {
        formData.append(`workoutDays[${index}][date]`, day?.toString() ?? "");
      });

      const response = await fetch(
        `${process.env.REACT_APP_CORE_SERVICE_URL}/api/health/program/create`,
        {
          body: formData,
          method: "POST",
          headers: {
            ...tokenHeaders,
            Accept: "application/json",
          },
        }
      );

      return response.json();
    },
    onSuccess: () => {
      showSnackbar("success", "You created program successfully.", 3000);
      queryClient.invalidateQueries({
        queryKey: ["api/healthProgram"],
      });
      navigate("/programs");
    },
    onError: (error) => {
      showSnackbar(
        "error",
        "Something went wrong. Please try again later.",
        3000
      );
    },
  });
}
