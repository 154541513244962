import { Box, Typography } from "@mui/material";

import FilterChecklist from "./FilterChecklist";
import PriceSlider from "./PriceSlider";
import { useSupplementsTypesQuery } from "../../api/supplements/supplementsTypesQuery";

type Props = {
  control: any;
};

const MarketplaceFilters = ({ control }: Props) => {
  const { data: supplementsTypes } = useSupplementsTypesQuery();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "20%",
        p: "36px 16px",
        gap: 2,
      }}
    >
      <Typography
        fontFamily="Open Sans"
        fontSize="16px"
        fontWeight={600}
        sx={{ paddingLeft: "15px" }}
      >
        Filter by
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <FilterChecklist
          name="programs"
          control={control}
          options={[
            "Longevity",
            "Diabetes",
            "Obesity",
            "Menopause",
            "Cognitive Health",
          ]}
          label="Programs"
        />
        <PriceSlider name="maxPrice" control={control} />
        <FilterChecklist
          name="type"
          control={control}
          options={supplementsTypes?.filter((x: any) => !!x)}
          label="Type"
        />
        <FilterChecklist
          name="supplier"
          control={control}
          options={["Customized Health", "NutriPath"]}
          label="Supplier"
        />
      </Box>
    </Box>
  );
};

export default MarketplaceFilters;
