import { useMutation, useQueryClient } from "@tanstack/react-query";
import { coreServiceAxios } from "../../../config/axios-instance";
import { useSnackbar } from "../../../components/snackbar/Snackbar";

export const useCreateMealMutation = () => {
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: Meal) => {
      const result = await coreServiceAxios.post(`api/health/meal`, data);

      return result.data;
    },
    onSuccess: () => {
      showSnackbar("success", "Meal created successfully.");
      queryClient.invalidateQueries({
        queryKey: ["meals"],
      });
    },
    onError: () => {
      showSnackbar("error", "Failed to create meal.");
    },
  });
};
