import React from "react";
import { Controller } from "react-hook-form";
import {
  FormControl,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

interface Props {
  name: string;
  control: any;
  options: string[];
  label?: string;
  sublabel?: string;
  direction?: string;
}

const ReusableRadioButtons: React.FC<Props> = ({
  name,
  control,
  options,
  label,
  sublabel,
  direction = "row",
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl fullWidth sx={{ margin: "8px 0 8px 0" }}>
          {label && (
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "600",
                textAlign: "left",
                marginBottom: "8px",
              }}
            >
              {label}
            </Typography>
          )}
          {sublabel && (
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "20px",
                textAlign: "left",
                marginBottom: "4px",
                color: "#4F5359",
              }}
            >
              {sublabel}
            </Typography>
          )}
          <RadioGroup
            name={name}
            value={value || ""}
            onChange={(e) => onChange(e.target.value)}
            sx={{
              display: "flex",
              flexDirection: direction,
              marginLeft: "8px",
              gap: "8px",
            }}
          >
            {options.map((option) => (
              <FormControlLabel
                key={option}
                value={option}
                control={
                  <Radio
                    sx={{
                      color: value === option ? "#2974B1" : "#EAECF0",
                      height: "10px",
                      "&.Mui-checked": {
                        color: "#3992DD",
                      },
                    }}
                    onClick={() => {
                      onChange(value === option ? null : option);
                    }}
                  />
                }
                label={option}
                sx={{
                  paddingTop: "8px",
                  paddingRight: "24px",
                  paddingBottom: "8px",
                  paddingLeft: "8px",
                  maxHeight: "fit-content",
                  border: value === option ? "2px double" : "1px solid",
                  borderRadius: "25px",
                  borderColor: value === option ? "#FFAD01" : "#EAECF0",
                  backgroundColor: value === option ? "#FEF7EB" : "initial",
                  color: value === option ? "#2974B1" : "initial",
                  fontWeight: 500,
                  fontSize: "14px",
                  "& .MuiFormControlLabel-label": {
                    userSelect: "none",
                    WebkitUserSelect: "none",
                    MozUserSelect: "none",
                    msUserSelect: "none",
                  },
                }}
              />
            ))}
          </RadioGroup>
          {error && (
            <Typography
              variant="caption"
              color="error"
              sx={{ marginLeft: "12px", marginTop: "4px" }}
            >
              {error.message}
            </Typography>
          )}
        </FormControl>
      )}
    />
  );
};

export default ReusableRadioButtons;
