import { useQuery } from "@tanstack/react-query";
import { useAuthToken } from "../../auth/authToken";
import { coreServiceAxios } from "../../config/axios-instance";
import { useUsersMeQuery } from "../users/usersMeQuery";
import { Supplements } from "../../types/supplements";

interface SupplementsData {
  data: Supplements[];
  pagination: {
    pageCount: number;
    total: number;
  };
}

export function useSupplementsQuery() {
  const { hasToken } = useAuthToken();
  const { data: me } = useUsersMeQuery();
  const shouldEnableQuery = hasToken && me?.id !== undefined;

  return useQuery({
    queryKey: ["api/supplements"],
    queryFn: () =>
      coreServiceAxios
        .get<SupplementsData>(
          `api/health/program/supplements?pageCount=1&pageSize=1000`
        )
        .then((response) => response?.data),
    enabled: Boolean(shouldEnableQuery),
  });
}
