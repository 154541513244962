import { useInfiniteQuery } from "@tanstack/react-query";
import { coreServiceAxios } from "../../../config/axios-instance";
import { mapOrderFilter } from "../../../utils/filters";

type Props = {
  searchInput: string;
  sort: SortFilter;
  duration: number;
  type: string[];
  difficulty: string[];
};

const fetchWorkouts = async ({
  pageParam = 1,
  queryKey,
}: {
  pageParam: number;
  queryKey: any;
}) => {
  const [, searchInput, sort, duration, type, difficulty] = queryKey;

  const filters = [
    searchInput ? `searchInput=${searchInput}` : null,
    sort ? `sort=${mapOrderFilter(sort)}` : null,
    duration ? `duration=${duration}` : null,
    type.length > 0 ? `type=${type.join(",")}` : null,
    difficulty.length > 0 ? `difficulty=${difficulty.join(",")}` : null,
  ]
    .filter(Boolean)
    .join("&");

  const url = `/api/health/workout?page=${pageParam}&${filters}`;
  const response = await coreServiceAxios.get(url);

  return response.data;
};

export const useWorkoutsQuery = ({
  searchInput,
  sort,
  duration,
  type,
  difficulty,
}: Props) => {
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery({
    queryKey: ["workouts", searchInput, sort, duration, type, difficulty],
    queryFn: fetchWorkouts,
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.length > 0) {
        return allPages.length + 1;
      }
      return undefined;
    },
    initialPageParam: 1,
  });

  const workouts = data?.pages.flat() || [];

  return {
    workouts,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
  };
};
