import { useQuery } from "@tanstack/react-query";
import { useAuthToken } from "../../auth/authToken";
import { coreServiceAxios } from "../../config/axios-instance";
import { useUsersMeQuery } from "../users/usersMeQuery";

export function useSupplementsTypesQuery() {
  const { hasToken } = useAuthToken();
  const { data: me } = useUsersMeQuery();
  const shouldEnableQuery = hasToken && me?.id !== undefined;

  return useQuery({
    queryKey: ["api/supplements/types"],
    queryFn: () =>
      coreServiceAxios
        .get("api/health/program/supplements/types")
        .then((response) => response?.data?.data),
    enabled: Boolean(shouldEnableQuery),
  });
}
