import { Box, Typography } from "@mui/material";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import { useNavigate } from "react-router";

const BackTo = ({
  link,
  title,
  goToPrevious,
}: {
  link: string;
  title: string;
  goToPrevious?: boolean;
}) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{ display: "flex", alignItems: "center", gap: 2, cursor: "pointer" }}
      onClick={() => (goToPrevious ? navigate(-1) : navigate(link))}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box
          sx={{
            borderRadius: "50%",
            width: 38,
            height: 38,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            bgcolor: "#2974B1",
            zIndex: 2,
          }}
        >
          <ArrowBackIosRoundedIcon sx={{ color: "white", fontSize: 18 }} />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            bgcolor: "#EDF0F2",
            height: 35,
            ml: -1.5,
            borderTopRightRadius: "20px",
            borderBottomRightRadius: "20px",
          }}
        >
          <Typography
            sx={{
              pl: 2.25,
              mr: 1.5,
              fontSize: 12,
              color: "#2974B1",
              fontWeight: 600,
            }}
          >
            {title}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default BackTo;
