import { Box, Link, Typography } from "@mui/material";
import { Supplements } from "../../types/supplements";
import { ReusableButton } from "../reusable";

const SupplementMarketplaceCard = ({
  supplement,
  openModal,
}: {
  supplement: Supplements;
  openModal: any;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: 1,
        border: "1px solid #E4E7EC",
        borderRadius: "12px",
        padding: "24px 12px 18px 12px",
      }}
    >
      <Box
        sx={{
          height: "160px",
          width: "100%",
          flexShrink: 0,
          overflow: "hidden",
          position: "relative",
          borderRadius: "12px",
          mb: 1,
        }}
      >
        <img
          src={supplement.image ?? "/assets/protein-powder.jpg"}
          alt="placeholder"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      </Box>
      <Typography
        fontWeight={900}
        fontSize="16px"
        lineHeight="22.4px"
        color="#000000"
      >
        {supplement.name}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          gap: 1,
        }}
      >
        <Box
          sx={{
            py: "2px",
            px: "12px",
            borderRadius: "12px",
            border: "1px solid #E4E7EC",
            backgroundColor: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 1,
          }}
        >
          <img
            src="/assets/schedule-icon.svg"
            alt="schedule icon"
            width={13}
            height={13}
            color="#7D8189"
            style={{
              filter:
                "invert(49%) sepia(5%) saturate(461%) hue-rotate(182deg) brightness(103%) contrast(92%)",
            }}
          />
          <Typography fontSize={14} fontWeight={500} color="#7D8189">
            {supplement.dosage}
          </Typography>
        </Box>
        <Link
          href={supplement.link}
          sx={{
            color: "#2974B1",
            fontWeight: 600,
            fontSize: "14px",
            textDecoration: "none",
            display: "flex",
            gap: 1,
          }}
          target="_blank"
        >
          <img
            src="/assets/link-icon.svg"
            alt="link icon"
            width={13}
            height={13}
          />
          See Reference
        </Link>
      </Box>
      <Typography fontSize={16} fontWeight={400} color="#7D8189">
        {supplement.benefits}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          mt: "auto",
        }}
      >
        <Typography fontSize={14} fontWeight={600} color="#000000">
          {`$ AUD ${supplement.cost}/month`}
        </Typography>
        <ReusableButton
          buttonText={"Add to Program"}
          width="50%"
          height="32px"
          onClickFunction={() => {
            openModal({ isOpen: true, supplement });
          }}
          backgroundColor="#2974B1"
          beforeIcon={<img src="/assets/plus-icon.svg" alt="icon" />}
        />
      </Box>
    </Box>
  );
};

export default SupplementMarketplaceCard;
