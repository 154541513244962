import { Modal, Box, Typography, Link } from "@mui/material";

export interface MealType {
  title: string;
  info: string;
  image: string;
  dietType: string;
  mealTime: string;
  reference: {
    title: string;
    text: string;
    linkTo: string;
  }[];
}
type Props = {
  open: boolean;
  handleClose: any;
  meal: MealType | null;
};
const MealModal: React.FC<Props> = ({ open, handleClose, meal }) => {
  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "8px",
      }}
    >
      <Box
        sx={{
          overflowY: "auto",
          backgroundColor: "background.paper",
          boxShadow: 5,
          outline: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "10px",
          padding: " 30px 39px",
          width: "600px",
          height: "550px",
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1.5,
            width: "100%",
            height: "100%",
            backgroundColor: "white",
          }}
        >
          <Box
            sx={{
              borderRadius: "10px",
              display: "flex",
              gap: 1.5,
              width: "100%",
            }}
          >
            <Box
              sx={{
                borderRadius: "10px",
                overflow: "hidden",
                height: "175px",
              }}
            >
              <img
                src={meal?.image}
                alt="meal"
                style={{
                  width: "450px",
                  height: "175px",
                  objectFit: "cover",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: 3,
                width: "100%",
                height: "100%",
              }}
            >
              <Typography
                fontSize="24px"
                fontWeight={700}
                color="#000000"
                lineHeight="33.6px"
              >
                {meal?.title}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    py: "2px",
                    px: "12px",
                    borderRadius: "12px",
                    backgroundColor: "#2974B1",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography fontSize={14} fontWeight={500} color="white">
                    {meal?.mealTime}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    py: "2px",
                    px: "12px",
                    borderRadius: "12px",
                    border: "1px solid #2974B1",
                    backgroundColor: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography fontSize={14} fontWeight={500} color="#2974B1">
                    {meal?.dietType}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Typography
            fontSize="16px"
            fontWeight={600}
            color="#000000"
            lineHeight="22.4px"
          >
            {meal?.info}
          </Typography>
          {meal?.reference?.map((r) => {
            return (
              <>
                <Typography fontSize={14} fontWeight={600} color="#606367">
                  {r?.title}
                </Typography>
                <Link
                  href={r?.linkTo}
                  sx={{
                    color: "#2974B1",
                    fontWeight: 400,
                    fontSize: "16px",
                    fontStyle: "italic",
                  }}
                  target="_blank"
                >
                  {r?.text}
                </Link>
              </>
            );
          })}
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: 27,
            right: 22,
            cursor: "pointer",
          }}
          onClick={handleClose}
        >
          <img
            src="/assets/close-icon.svg"
            alt="close"
            style={{
              width: "12px",
              height: "12px",
            }}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default MealModal;
