import { Box, Typography } from "@mui/material";
import { Control, Controller } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { isEmpty } from "../../../utils/onboarding";
import "./index.css";

type Props = {
  control: Control<any>;
  name: string;
  label?: string;
  placeholder?: string;
  autofocus?: boolean;
  isRequired?: boolean;
  onFocus?: any;
  readOnly?: boolean;
  disabled?: boolean;
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
};

const ReusableTextEditor = ({
  control,
  name,
  label,
  placeholder = "",
  isRequired = true,
  autofocus = false,
  readOnly = false,
  disabled = false,
  onFocus,
  onBlur,
}: Props) => {
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: isRequired,
      }}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { invalid, error },
      }) => {
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "20px",
                textAlign: "left",
              }}
            >
              {label}
            </Typography>
            <ReactQuill
              formats={[
                "header",
                "font",
                "size",
                "bold",
                "italic",
                "underline",
                "strike",
                "blockquote",
                "list",
                "bullet",
                "indent",
                "link",
                "image",
                "video",
              ]}
              placeholder={placeholder}
              modules={modules}
              ref={ref}
              value={isEmpty(value) ? "" : value}
              onChange={onChange}
              onBlur={onBlur}
            />
            {error && (
              <p style={{ marginLeft: "16px", fontSize: "12px", color: "red" }}>
                {error.message}
              </p>
            )}
          </Box>
        );
      }}
    />
  );
};

export default ReusableTextEditor;
