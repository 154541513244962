import { Box } from "@mui/material";
import { ReusableTextField } from "../../reusable";

type Props = {
  control: any;
};

export default function Filters({ control }: Props) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "50%",
      }}
    >
      <ReusableTextField
        control={control}
        name="name"
        inputType="text"
        placeholder="Search your Programs"
        borderRadius="20px"
        placeholderColor="#000000"
        fullWidth
        startAdornment={<img src="/assets/search-icon.svg" alt="search" />}
      />
    </Box>
  );
}
