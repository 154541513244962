import { Box, Typography } from "@mui/material";
import FilterChecklist from "../../marketplace/FilterChecklist";
import { mealLibraryFilterOptions } from "../../../constants/program-library/meal-library";
import SliderFilter from "../widgets/SliderFilter";

type Props = {
  control: any;
};

const MealLibraryFilters = ({ control }: Props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "20%",
        px: "16px",
        gap: 2,
      }}
    >
      <Typography
        fontFamily="Open Sans"
        fontSize="16px"
        fontWeight={600}
        sx={{ paddingLeft: "15px" }}
      >
        Filter by
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <FilterChecklist
          name="dietPlan"
          control={control}
          options={mealLibraryFilterOptions.dietType}
          label="Diet type"
        />
        <SliderFilter
          name="preparationTime"
          control={control}
          maxValue={60}
          label="Preparation time"
          minLabel="0 min"
          maxLabel="60 min"
          placeholderText="Choose the preparation time"
        />
        <FilterChecklist
          name="mealType"
          control={control}
          options={mealLibraryFilterOptions.mealType}
          label="Meal Type"
        />
      </Box>
    </Box>
  );
};

export default MealLibraryFilters;
