import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router";
import { useAuthToken } from "../../auth/authToken";
import { useResponseParser } from "../jsonResponseParser";
import { useSnackbar } from "../../components/snackbar/Snackbar";

interface Credentials {
  identifier: string;
  password: string;
}

interface Response {
  jwt: string;
  tenant: {
    id: number;
    name: string;
    strapi_url: string;
  };
}

export function useLogInMutation() {
  const { updateAuthToken } = useAuthToken();
  const { parseJsonResponse } = useResponseParser();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (credentials: Credentials) => {
      return fetch(`${process.env.REACT_APP_MC_URL}/api/login`, {
        body: JSON.stringify(credentials),
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then(parseJsonResponse<Response>);
    },
    onSuccess: (response) => {
      const token = {
        authToken: response.jwt,
        strapiUrl: response.tenant.strapi_url,
      };
      updateAuthToken(token);
      navigate("/");
    },
    onError: (error) => {
      showSnackbar("error", error.message.replace(/^"|"$/g, ""), 3000, {
        vertical: "bottom",
        horizontal: "center",
      });
    },
  });
}
