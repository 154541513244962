import { ReactNode, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  List,
  CssBaseline,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton,
  Typography,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useTheme } from "@mui/material/styles";
import {
  Content,
  Drawer,
  DrawerHeader,
  SidebarOption,
  sidebarOptions,
} from "../../constants/sidebar";
import { useUsersMeQuery } from "../../api/users/usersMeQuery";
import { useAuthToken } from "../../auth/authToken";

interface SidebarProps {
  children?: ReactNode;
}

const Sidebar = ({ children }: SidebarProps) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<SidebarOption[]>([]);

  const { updateAuthToken } = useAuthToken();
  const navigate = useNavigate();
  const location = useLocation();
  const { data: user } = useUsersMeQuery();

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleNavigation = (redirect: string) => {
    navigate(redirect);
  };

  function filterSidebarOptions(section: string, roles: string) {
    let temp = sidebarOptions.filter(
      (option) =>
        section.startsWith(option.section) &&
        (option.role.includes(roles) || option.role[0] === "*")
    );
    setOptions(temp);
  }

  useEffect(() => {
    if (!user || !location) {
      setOptions([]);
      return;
    }
    filterSidebarOptions(location.pathname, user?.role.name);
  }, [user, location]);

  const handleLogOut = () => {
    updateAuthToken(null);
    navigate("/");
  };

  const logoutButton = (
    <ListItem
      key={"logout"}
      disablePadding
      sx={{ display: "block" }}
      onClick={handleLogOut}
    >
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: open ? "initial" : "center",
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : "auto",
            justifyContent: "center",
          }}
        >
          <img src="/assets/log-out-icon.svg" alt="Log out" />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              sx={{
                color: "red",
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "24px",
              }}
            >
              Log out
            </Typography>
          }
          sx={{ opacity: open ? 1 : 0 }}
        />
      </ListItemButton>
    </ListItem>
  );

  return (
    <Box
      sx={{
        display: "flex",
        marginTop: location.pathname === "/login" ? "0" : "60px",
      }}
    >
      <CssBaseline />
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          alignItems: "flex-end",
          paddingTop: "100px",
          height: "90%",
          position: "relative",
          zIndex: 10,
          display: options.length === 0 ? "none" : "inline",
        }}
      >
        <List sx={{ marginTop: "20px" }}>
          {options.map((option, index) => (
            <ListItem
              key={option.label}
              disablePadding
              sx={{ display: "block" }}
              onClick={() => handleNavigation(option.redirect)}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <img src={option.icon} alt={option.label} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: "16px",
                        lineHeight: "24px",
                      }}
                    >
                      {option.label}
                    </Typography>
                  }
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          ))}
          {location.pathname.startsWith("/profile") ? logoutButton : null}
        </List>
        <DrawerHeader>
          <IconButton onClick={handleDrawerOpen}>
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, overflowX: "hidden"}}>
        <Content theme={theme} open={open}>
          {children}
        </Content>
      </Box>
    </Box>
  );
};
export default Sidebar;
