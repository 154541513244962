import { Box, Typography } from "@mui/material";
import ViewDataButton from "./ViewDataButton";

const ScienceContentSection: React.FC<{
  title: string;
  text: string;
  onClick: () => void;
  imageSrc: string;
  imageAlt: string;
}> = ({ title, text, onClick, imageSrc, imageAlt }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "311px",
        borderRadius: "24px",
        overflow: "hidden",
        margin: "20px auto 40px",
        width: "80%",
        maxWidth: "1000px",
        background:
          "linear-gradient(to right , rgba(242, 185, 253, 1), rgba(57, 146, 221, 1))",
      }}
    >
      <Box
        sx={{
          flexBasis: "55%",
          height: "100%",
          img: {
            width: "100%",
            height: "100%",
            objectFit: "cover",
          },
        }}
      >
        <img src={imageSrc} alt={imageAlt} />
      </Box>

      <Box
        sx={{
          flexBasis: "45%",
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          color: "#fff",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Comfortaa",
            fontSize: "24px",
            fontWeight: 600,
            lineHeight: "33.6px",
            letterSpacing: "-0.01em",
            textAlign: "left",
            marginLeft: "30px",
            color: "rgba(255, 255, 255, 1)",
          }}
        >
          {title}
        </Typography>
        <Box sx={{ height: "36px" }} />
        <Typography
          sx={{
            fontFamily: "Comfortaa",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "22.4px",
            textAlign: "left",
            marginBottom: "36px",
            marginLeft: "30px",
            color: "rgba(255, 255, 255, 1)",
            width: "400px"
          }}
        >
          {text}
        </Typography>

        <Box sx={{   marginLeft: "30px", }}>
          <ViewDataButton onClick={onClick} />
        </Box>
      </Box>
    </Box>
  );
};

export default ScienceContentSection;
