import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import {
  marketplaceFiltersDefaultValues,
  marketplaceFiltersSchema,
} from "../../schemas/marketplace/marketplace";
import MarketplaceFilters from "../../components/marketplace/MarketplaceFilters";
import SupplementsMarketplace from "../../components/marketplace/SupplementsMarketplace";

const MarketplacePage = () => {
  const { control, watch } = useForm({
    defaultValues: marketplaceFiltersDefaultValues,
    resolver: yupResolver(marketplaceFiltersSchema),
  });

  return (
    <Box sx={{ display: "flex", gap: 3, width: "100%" }}>
      <MarketplaceFilters control={control} />
      <SupplementsMarketplace control={control} watch={watch} />
    </Box>
  );
};

export default MarketplacePage;
