import { Box } from "@mui/material";
import ProgramOverviewWidgetHolder from "../widgets/ProgramOverviewWidgetHolder";
import { TestingProtocol } from "../../../../../types/testingProtocols";
import TestingProtocolCard from "../widgets/TestingProtocolCard";

const TestingProtocols = ({
  testingProtocols,
}: {
  testingProtocols: TestingProtocol[];
}) => {
  return (
    <ProgramOverviewWidgetHolder title="Testing Protocols">
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "49% 49%",
          gap: 3,
          width: "100%",
        }}
      >
        {testingProtocols?.map((t) => (
          <TestingProtocolCard testingProtocol={t} />
        ))}
      </Box>
    </ProgramOverviewWidgetHolder>
  );
};

export default TestingProtocols;
