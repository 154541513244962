export const displayValue = (value?: number | null) => {
  return value === null || value === undefined || value === 0 ? "N/A" : value;
};

const colors = ["#2974B1", "#6A4D39", "#FFAD00", "#8A2F9B", "#ABADC6"];
export const generateChartData = (data: any) => {
  return data?.map((d: any, index: number) => {
    return {
      name: d.name,
      value: d.cost,
      fill: colors[index % 5],
    };
  });
};
