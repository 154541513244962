import * as yup from "yup";
import { filterOptions } from "../../constants/program-library/program-library";
import { workoutLibFilterOptions } from "../../constants/program-library/workout-library";

export const workoutLibFiltersSchema = yup
  .object()
  .shape({
    searchInput: yup.string().default(""),
    sort: yup.string().oneOf(filterOptions),
    duration: yup.number().min(0).required().default(0),
    type: yup
      .array()
      .of(yup.mixed().oneOf(workoutLibFilterOptions.type))
      .default([]),
    difficulty: yup
      .array()
      .of(yup.mixed().oneOf(workoutLibFilterOptions.difficulty))
      .default([]),
  })
  .required();

export const workoutLibFiltersDefaultValues = {
  searchInput: "",
  sort: "",
  duration: 0,
  type: [],
  difficulty: [],
};

export const workoutSchema = yup.object().shape({
  name: yup
    .string()
    .required("Workout Name is required")
    .min(3, "Workout Name must be at least 3 characters")
    .max(50, "Workout Name must not exceed 50 characters"),
  duration: yup
    .number()
    .min(1, "Duration time must be at least 1 minute")
    .max(240, "Duration time must not exceed 240 minutes")
    .required("Duration time is required"),
  type: yup
    .string()
    .required("Workout Type is required")
    .oneOf(workoutLibFilterOptions.type, "Invalid Workout type"),
  difficulty: yup
    .string()
    .required("Difficulty Level is required")
    .oneOf(workoutLibFilterOptions.difficulty, "Invalid Difficulty Level"),
  equipment: yup
    .array()
    .of(yup.string().required("Equipment is required"))
    .required("Equipment is required")
    .min(1, "At least one piece of equipment is required"),
});
