import * as yup from "yup";
import { filterOptions } from "../../constants/program-library/program-library";

export const mealLibraryFiltersSchema = yup
  .object()
  .shape({
    searchInput: yup.string().default(""),
    sort: yup.string().oneOf(filterOptions),
    preparationTime: yup.number().min(0).required().default(0),
    dietPlan: yup
      .array()
      .of(yup.mixed<DietPlan>().oneOf(["Mediterranean", "Paleo", "Vegetarian"]))
      .default([]),
    mealType: yup
      .array()
      .of(
        yup.mixed<MealType>().oneOf(["Breakfast", "Lunch", "Dinner", "Snack"])
      )
      .default([]),
  })
  .required();

export const mealLibraryFiltersDefaultValues = {
  searchInput: "",
  sort: "",
  preparationTime: 0,
  dietPlan: [] as DietPlan[],
  mealType: [] as MealType[],
};

export type MealLibraryFiltersFormValues =
  typeof mealLibraryFiltersDefaultValues;

export const mealSchema = yup.object().shape({
  name: yup
    .string()
    .required("Meal Name is required")
    .min(3, "Meal Name must be at least 3 characters")
    .max(50, "Meal Name must not exceed 50 characters"),
  type: yup
    .string()
    .required("Meal Type is required")
    .oneOf(["Breakfast", "Lunch", "Dinner", "Snack"], "Invalid meal type"),
  dietPlan: yup
    .string()
    .required("Diet Plan is required")
    .oneOf(["Mediterranean", "Paleo", "Vegetarian"], "Invalid diet plan"),
  description: yup
    .string()
    .max(500, "Description must not exceed 500 characters")
    .required("Description is required"),
  preparationTime: yup
    .number()
    .min(1, "Preparation time must be at least 1 minute")
    .max(240, "Preparation time must not exceed 240 minutes")
    .required("Preparation time is required"),
  calories: yup
    .number()
    .min(0, "Calories must be a positive number")
    .required("Calories are required"),
  recipe: yup.string().required("Recipe is required"),
  references: yup.string().nullable().required("References are required"),
});
