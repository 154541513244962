import Header from "./components/header/Header";
import Sidebar from "./components/sidebar/Sidebar";
import AppRoutes from "./routes";

import "./App.css";

const App = () => {
  return (
    <>
      <Header />
      <Sidebar>
        <AppRoutes />
      </Sidebar>
    </>
  );
};

export default App;
