// @ts-nocheck
import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Calendar, dayjsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./index.css";
import { Box, IconButton, Typography } from "@mui/material";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);

const djLocalizer = dayjsLocalizer(dayjs);

const CustomToolbar = ({ props }: { props: any }) => {
  return (
    <Box
      sx={{
        widht: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 2,
        marginBottom: "10px",
      }}
    >
      <Box
        sx={{
          widht: "100%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 2,
        }}
      >
        <img
          src="/assets/schedule-icon.svg"
          alt="schedule icon"
          width={30}
          height={30}
        />
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "24px",
            color: "#394150",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "Comfortaa, sans-serif",
          }}
        >
          Schedule
        </Typography>
      </Box>
      <Box
        sx={{
          widht: "100%",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 2,
        }}
      >
        <IconButton onClick={() => props?.onNavigate("PREV")}>
          <ArrowBackIcon
            sx={{ color: "#2974B1", width: "20px", height: "20px" }}
          />
        </IconButton>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "14px",
            color: "#394150",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {props?.label}
        </Typography>
        <IconButton onClick={() => props?.onNavigate("NEXT")}>
          <ArrowForwardIcon
            sx={{ color: "#2974B1", width: "20px", height: "20px" }}
          />
        </IconButton>
      </Box>
    </Box>
  );
};

const EventComponent = ({ props }: { props: any }) => {
  return (
    <Box
      sx={{
        background: "#2974B1",
        height: "100%",
        minHeight: "22px",
        borderRadius: "20px",
        paddingX: "8px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 1,
      }}
    >
      <img
        alt="img"
        src="/assets/dumbbell-icon.svg"
        style={{
          objectFit: "cover",
        }}
      />
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: "14px",
          lineHeight: "14px",
          color: "white",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {props?.title ?? "Exercise"}
      </Typography>
    </Box>
  );
};

const components = {
  event: (props: any) => {
    return <EventComponent props={props} />;
  },
  toolbar: (props: any) => {
    return <CustomToolbar props={props} />;
  },
};
const ReusableCalendar = ({ eventsData }: { eventsData: any }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Calendar
        localizer={djLocalizer}
        events={eventsData}
        components={components}
        startAccessor="start"
        endAccessor="end"
        views={["week"]}
        defaultView="week"
        formats={{
          dayFormat: (date, culture, localizer) =>
            localizer.format(date, "ddd D", culture),
          weekdayFormat: (date, culture, localizer) =>
            localizer.format(date, "ddd", culture),
        }}
      />
    </Box>
  );
};

export default ReusableCalendar;
