import { ReactNode, useCallback, useMemo, useState } from "react";
import { AuthTokenContext, Token } from "./authToken";
import { useQueryClient } from "@tanstack/react-query";
import {
  LOCAL_STORAGE_ACCESS_TOKEN_KEY,
  LOCAL_STORAGE_STRAPI_URL_KEY,
} from "../constants/auth";

const AuthTokenProvider = ({ children }: { children: ReactNode }) => {
  const queryClient = useQueryClient();

  const [authToken, setAuthToken] = useState<string | null>(
    localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY)
  );

  const [strapiUrl, setStrapiUrl] = useState<string | null>(
    localStorage.getItem(LOCAL_STORAGE_STRAPI_URL_KEY)
  );

  const updateAuthToken = useCallback(
    (token: { authToken: string; strapiUrl: string } | null) => {
      setAuthToken(token?.authToken ?? null);
      setStrapiUrl(token?.strapiUrl ?? null);

      if (token) {
        localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY, token.authToken);
        localStorage.setItem(LOCAL_STORAGE_STRAPI_URL_KEY, token.strapiUrl);
      } else {
        localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);
        localStorage.removeItem(LOCAL_STORAGE_STRAPI_URL_KEY);
        queryClient.invalidateQueries();
      }
    },
    [queryClient]
  );

  const providerValue = useMemo((): Token => {
    return authToken
      ? {
          hasToken: true,
          tokenHeaders: { Authorization: `Bearer ${authToken}` },
          updateAuthToken,
          strapiUrl,
        }
      : { hasToken: false, tokenHeaders: {}, updateAuthToken, strapiUrl };
  }, [authToken, strapiUrl, updateAuthToken]);

  return (
    <AuthTokenContext.Provider value={providerValue}>
      {children}
    </AuthTokenContext.Provider>
  );
};

export default AuthTokenProvider;
