export const mapOrderFilter = (sort: string): string => {
  switch (sort) {
    case "Name (A-Z)":
      return "name_asc";
    case "Name (Z-A)":
      return "name_desc";
    case "Price (Low to High)":
      return "price_asc";
    case "Price (High to Low)":
      return "price_desc";
    default:
      return "name_asc";
  }
};
