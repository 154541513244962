import * as React from "react";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import Box from "@mui/material/Box";
import { Control, Controller } from "react-hook-form";

const StyledSlider = styled(Slider)({
  height: 5,
  "& .MuiSlider-thumb": {
    height: 32,
    width: 32,
    backgroundColor: "#ffffff",
    border: "3px solid #1976D2",
    "&:hover, &.Mui-focusVisible, &.Mui-active": {
      boxShadow: "none",
    },
  },
  "& .MuiSlider-track": {
    backgroundColor: "#FFC107",
    height: 4,
  },
  "& .MuiSlider-rail": {
    backgroundColor: "#E0E0E0",
    height: 4,
    opacity: 1,
  },
  "& .MuiSlider-track.MuiSlider-track": {
    backgroundColor: "#1976D2",
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#3992DD",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&::before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

type Props = {
  control: Control<any>;
  name: string;
  defaultValue?: number;
  maxValue: number;
  label: string;
  minLabel: string;
  maxLabel: string;
  placeholderText?: string;
};

const SliderFilter = ({
  control,
  name,
  label,
  minLabel,
  maxLabel,
  placeholderText,
  maxValue,
  defaultValue,
}: Props) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <Box sx={{ width: "100%", paddingX: "15px", my: 1 }}>
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "#000000",
            }}
          >
            {label}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              my: 1,
            }}
          >
            <Typography
              sx={{
                fontSize: "10px",
                fontWeight: "400",
                color: "#7D8189",
              }}
            >
              {minLabel}
            </Typography>
            <Typography
              sx={{
                fontSize: "10px",
                fontWeight: "400",
                color: "#7D8189",
              }}
            >
              {maxLabel}
            </Typography>
          </Box>
          <StyledSlider
            valueLabelDisplay="auto"
            defaultValue={defaultValue}
            max={maxValue}
            onChangeCommitted={(e: any) => {
              onChange(Number(e.target.outerText));
            }}
          />
          {placeholderText && (
            <Typography fontSize={10} fontWeight={400} color="#7D8189" mt={1}>
              {placeholderText}
            </Typography>
          )}
        </Box>
      )}
    />
  );
};

export default SliderFilter;
